import {Button, Col, Row, Space, Typography} from "antd"
import React, {useEffect, useMemo, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {BaseTable} from "../../../components/BaseTable/baseTable"
import {LOADING_STATE_PENDING} from "../../../utils/loadingState"
import {Spinner} from "../../../components/Spinner/spinner"
import {LinkButton} from "../../../components/Buttons/linkButton"
import {defaultColumnParams} from "../../../components/BaseTable/defaultColumnParams"
import {UploadOutlined} from "@ant-design/icons"
import {ModalEditor} from "../../../components/ModalEditor/ModalEditor"
import {getFinalReportFilesTable, getIsReportDownloadable} from "../../../redux/projectManagementModule/finalReportFilesTableSlice"
import {GetTechnicalReportButton} from "./Report/GetTechnicalReportButton"
import {useParams} from "react-router-dom"

export const FinalReportPage = () => {
	const dispatch = useDispatch()
	const params = useParams()

	const tableName = "files"

	//колонки таблицы
	const tableColumns = useSelector((state) => state.projectFinalReportFilesTable.tableColumns)
	//данные таблицы
	const tableData = useSelector((state) => state.projectFinalReportFilesTable.tableData)
	// идет ли запрос на бэк за данными для таблицы
	const isLoading = useSelector((state) => state.projectFinalReportFilesTable.isLoading)

	const projectGuid = useSelector((state) => state.projectCard.guid)
	const columnFilterValues = useSelector((state) => state.projectFinalReportFilesTable.columnFilterValues)

	const [selectedId, setSelectedId] = useState("")
	const [showModalEditor, setShowModalEditor] = useState(false)

	const loadData = () => {
		dispatch(getFinalReportFilesTable({tableName, projectGuid}))
		dispatch(getIsReportDownloadable({tableName, projectGuid}))
	}

	const showAddModal = () => {
		setShowModalEditor(true)
	}

	const showEditModal = (record) => {
		setSelectedId(record.guid)
		setShowModalEditor(true)
	}

	const handleSuccess = () => {
		setSelectedId("")
		setShowModalEditor(false)
		loadData()
	}

	const handleCancel = () => {
		setSelectedId("")
		setShowModalEditor(false)
	}

	const linkCellRenderer = (text, record) => {
		return <LinkButton text={text} handleClick={() => showEditModal(record)} />
	}

	const mappedColumns = useMemo(() => {
		//  сортировщик (для чисел и строк)
		const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || "").localeCompare(b || "") : a - b)

		return tableColumns.map((x) => {
			const column = {
				...x,
				...defaultColumnParams,
				columnTitle: x.title,
				sorter: (a, b) => sorter(a[x.dataIndex], b[x.dataIndex]),
			}

			if (x.dataIndex === "fileName") {
				return {...column, render: linkCellRenderer}
			}
			return column
		})
	}, [tableColumns])

	//запрос данных
	useEffect(() => {
		if (projectGuid !== null) loadData()
	}, [projectGuid])

	if (isLoading === LOADING_STATE_PENDING) {
		return <Spinner />
	}

	return (
		<Row wrap={false}>
			<Col flex="auto" className="mainCol">
				<Space direction="vertical" size="small" style={{width: "100%"}}>
					<Typography.Title level={3}></Typography.Title>
					<Space>
						<GetTechnicalReportButton projectGuid={params.guid} />
						<Button
							onClick={showAddModal}
							icon={<UploadOutlined />}
							type="primary"
							className="mainColor addButton">{`Загрузить файл`}</Button>
					</Space>
					<BaseTable
						data={tableData}
						columns={mappedColumns}
						isLoading={isLoading === LOADING_STATE_PENDING}
						pageContentHeight={340}
						filteredValues={columnFilterValues}
					/>
				</Space>

				{showModalEditor && (
					<ModalEditor
						entityName={tableName}
						isProjects={true}
						entityId={selectedId}
						onSuccess={handleSuccess}
						onCancel={handleCancel}
						useLink={true}
						link={`${tableName}/getForm?projectGuid=${projectGuid}`}
					/>
				)}
			</Col>
		</Row>
	)
}

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {makeNavigationTree} from "../utils/makeNavigationTree"
import {createBreadcrumbs} from "../utils/createBreadcrumbs"
import {referencesAPI} from "../pages/References/api/referencesAPI"

const initialState = {
	initialData: [], // данные о структуре справочников полученные с сервера
	treeData: [], // данные для treeSelect (древовидная структура справочников)
	breadcrumbsArray: [], // массив отображаемых хлебных крошек
	selectedReference: {guid: "", name: ""}, // выбранный справочник
	isLoading: "idle", // идет ли запрос
}

// получет массив объектов для навигации
export const getReferencesTreeData = createAsyncThunk("referencesNavigation/getReferencesTreeData", async (params, thunkAPI) => {
	try {
		const response = await referencesAPI.getReferencesNavigationArray()
		return response.data
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// tree select Навигация на странице Справочников
const referencesNavigationSlice = createSlice({
	name: "referencesNavigation",
	initialState,
	reducers: {
		setSelectedReference: (state, action) => {
			state.selectedReference = action.payload
		},
		setReferencesBreadcrumbs: (state, action) => {
			let newArray = []
			createBreadcrumbs(state.initialData, action.payload, newArray)
			state.breadcrumbsArray = newArray
		},
	},
	extraReducers: {
		[getReferencesTreeData.pending]: (state) => {
			state.isLoading = "pending"
		},
		[getReferencesTreeData.fulfilled]: (state, action) => {
			state.initialData = action.payload
			let treeData = makeNavigationTree(action.payload)
			state.treeData = treeData
			state.selectedReference.guid = treeData[0].guid
			state.breadcrumbsArray = [{name: treeData[0].name, guid: treeData[0].guid}]
			state.isLoading = "succeeded"
		},
		[getReferencesTreeData.rejected]: (state) => {
			state.isLoading = "failed"
		},
	},
})
export const {setSelectedReference, setReferencesBreadcrumbs} = referencesNavigationSlice.actions
export const referencesNavigationReducer = referencesNavigationSlice.reducer

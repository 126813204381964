import React, {useEffect, useState, useMemo} from "react"
import PropTypes from "prop-types"
import {Typography, Button, Space} from "antd"
import {getConclusionsTable} from "../../../../redux/projectManagementModule/conclusionTableSlice"
import {useDispatch, useSelector} from "react-redux"
import {PlusOutlined} from "@ant-design/icons"
import {ExportExcel} from "../../../../components/ExportExcel"
import {BaseTable} from "../../../../components/BaseTable/baseTable"
import {Spinner} from "../../../../components/Spinner/spinner"
import {LinkButton} from "../../../../components/Buttons/linkButton"
import {ModalEditor} from "../../../../components/ModalEditor/ModalEditor"
import {defaultColumnParams} from "../../../../components/BaseTable/defaultColumnParams"
import {LOADING_STATE_PENDING} from "../../../../utils/loadingState"

// таблица
export const ConclusionsTable = ({tableName, tableTitle}) => {
	const dispatch = useDispatch()

	//колонки таблицы
	const tableColumns = useSelector((state) => state.projectConclusionTables.tableColumns)
	//данные таблицы
	const tableData = useSelector((state) => state.projectConclusionTables.tableData)
	// идет ли запрос на бэк за данными для таблицы
	const isLoading = useSelector((state) => state.projectConclusionTables.isLoading)

	const projectGuid = useSelector((state) => state.projectCard.guid)
	const columnFilterValues = useSelector((state) => state.projectConclusionTables.columnFilterValues)

	const [selectedId, setSelectedId] = useState("")
	const [showModalEditor, setShowModalEditor] = useState(false)

	const loadData = () => {
		dispatch(getConclusionsTable({tableName, projectGuid}))
	}

	const showAddModal = () => {
		setShowModalEditor(true)
	}

	const showEditModal = (record) => {
		setSelectedId(record.guid)
		setShowModalEditor(true)
	}

	const handleSuccess = () => {
		setSelectedId("")
		setShowModalEditor(false)
		loadData()
	}

	const handleCancel = () => {
		setSelectedId("")
		setShowModalEditor(false)
	}

	const linkCellRenderer = (text, record) => {
		return <LinkButton text={text} handleClick={() => showEditModal(record)} />
	}

	const editCellRenderer = (text, record) => {
		return (
			<LinkButton
				text={text}
				handleClick={() => {
					showEditModal(record)
				}}
			/>
		)
	}

	const mappedColumns = useMemo(() => {
		//  сортировщик (для чисел и строк)
		const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || "").localeCompare(b || "") : a - b)

		return tableColumns.map((x) => {
			const column = {
				...x,
				...defaultColumnParams,
				columnTitle: x.title,
				sorter: (a, b) => sorter(a[x.dataIndex], b[x.dataIndex]),
			}

			if (x.dataIndex === "name") {
				return {...column, render: linkCellRenderer}
			}
			if (x.dataIndex === "recommendation" || x.dataIndex === "causeOfDamage" || x.dataIndex === "defect") {
				return {...column, render: editCellRenderer}
			}
			return column
		})
	}, [tableColumns])

	//запрос данных
	useEffect(() => {
		if (projectGuid !== null) loadData()
	}, [tableName, projectGuid])

	if (isLoading === LOADING_STATE_PENDING) {
		return <Spinner />
	}

	return (
		<>
			<Space direction="vertical" size="small" style={{width: "100%"}}>
				<Typography.Title level={3}>{tableTitle}</Typography.Title>
				<Space>
					<Button
						onClick={showAddModal}
						icon={<PlusOutlined />}
						type="primary"
						className="mainColor addButton">{`Создание [${tableTitle}]`}</Button>
					<ExportExcel dataToExport={tableData} tableHeader={tableColumns} fileName={tableName} />
				</Space>
				<BaseTable
					data={tableData}
					columns={mappedColumns}
					isLoading={isLoading === LOADING_STATE_PENDING}
					pageContentHeight={360}
					filteredValues={columnFilterValues}
				/>
			</Space>

			{showModalEditor && (
				<ModalEditor
					entityName={tableName}
					isProjects={true}
					entityId={selectedId}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					useLink={true}
					link={`${tableName}/getForm?projectGuid=${projectGuid}`}
				/>
			)}
		</>
	)
}

ConclusionsTable.propTypes = {
	tableName: PropTypes.string, // название Таблицы
	tableTitle: PropTypes.string, // подпись Таблицы
}

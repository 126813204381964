// добавляет в массив breadcrumbsArray объекты - хлебные крошки
export let createBreadcrumbs = (initialData, guid, breadcrumbsArray) => {
	// при переходе на Главную guid = null
	if (guid === null) {
		return
	}
	let breadcrumbObj = initialData.find((item) => item.guid === guid)

	breadcrumbsArray.unshift({
		name: breadcrumbObj.name,
		guid: breadcrumbObj.guid,
		referenceName: breadcrumbObj.referenceName,
		level: breadcrumbObj.level,
	})
	// вызываем функцию пока не дойдем до объекта, у которого нет родителя
	if (breadcrumbObj.parentGuid !== null) {
		createBreadcrumbs(initialData, breadcrumbObj.parentGuid, breadcrumbsArray)
	}
}

import {Col, Space} from "antd"
import React from "react"
import {WorkTable} from "./WorkTable"
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux"
import {NavigationButton} from "../../../../components/NavigationButton/NavigationButton"

// Страница Работы
export const Works = () => {
    const params = useParams()

    //заголовок таблицы
    const tableTitle = useSelector((state) => state.projectWorksNavigation.selectedWork.name)

    return (
        <Col flex="auto" className="mainCol">
            <Space size="middle" style={{marginBottom: "12px"}}>
                <NavigationButton />
            </Space>
            <WorkTable tableName={params.workType} tableTitle={tableTitle} />
        </Col>
    )
}
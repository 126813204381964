import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {projectManagementAPI} from "./api"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"

const initialState = {
	tableData: [],
	objectTableName: "",
	tableColumns: [],

	isLoading: LOADING_STATE_EMPTY, // идет ли запрос за данными для таблицы
	isLoadingFunctionalRoles: LOADING_STATE_EMPTY,
	isLoadingPost: LOADING_STATE_EMPTY,

	columnFilterValues: {},
}

// получет данные для отрисовки таблицы в заключения
export const getConclusionsTable = createAsyncThunk("conclusionsTables/getConclusionTable", async (params, thunkAPI) => {
	try {
		let {tableName, projectGuid} = params
		const response = await projectManagementAPI.getResultTableData(tableName, projectGuid)
		return {response: response.data, tableName}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// работа с таблицей на странице заключения
const conclusionTablesSlice = createSlice({
	name: "conclusionTables",
	initialState,
	reducers: {
		setObjectTableName: (state, action) => {
			state.objectTableName = action.payload
		},
		updateColumnFilterValues: (state, action) => {
			state.columnFilterValues = action.payload
		},
		clearColumnFilterValues: (state) => {
			state.columnFilterValues = null
		},
		setCheckedListRoles: (state, action) => {
			state.checkedListRoles = action.payload
		},
	},
	extraReducers: {
		[getConclusionsTable.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[getConclusionsTable.fulfilled]: (state, action) => {
			const {response} = action.payload
			state.tableData = response.data
			state.tableColumns = response.columns.map((i) => ({...i, ellipsis: true, width: 150}))
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getConclusionsTable.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
		},
	},
})

export const conclusionTablesReducer = conclusionTablesSlice.reducer
export const {setObjectTableName, updateColumnFilterValues, clearColumnFilterValues} = conclusionTablesSlice.actions

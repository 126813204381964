import {Navigate, Outlet} from "react-router-dom"
import {HeaderBar} from "./HeaderBar/HeaderBar"
import React from "react"
import {useSelector} from "react-redux"
import {SimpleSnackbar} from "../components/SimpleSnackbar"

// лейаут
export const ContentContainer = () => {
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

	if (!isLoggedIn) {
		return <Navigate to="/login" replace={true} />
	}

	return (
		<div style={{minWidth: "900px"}}>
			<HeaderBar />
			<Outlet />
			<SimpleSnackbar />
		</div>
	)
}

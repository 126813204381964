export const setColumnsConfigs = (columns, columnParams, parentColumnParams, isRootColumns) => {
	for (let i = 0; i < columns.length; i++) {
		if (!columns[i].children) {
			if (typeof columnParams === "function") {
				const columnParamsObject = columnParams(columns[i], isRootColumns ? i : -1)
				columns[i] = {...columns[i], ...columnParamsObject}
			} else {
				columns[i] = {...columns[i], ...columnParams}
			}
		} else {
			columns[i] = {...columns[i], ...parentColumnParams}
			setColumnsConfigs(columns[i].children, columnParams, parentColumnParams, false)
		}
	}

	return
}
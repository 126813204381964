import {Button, Form, Input, Typography} from "antd"
import React from "react"
import {Divider} from "antd"
import logo from "../../assets/img/vnikti_logo.png"
import styled from "styled-components"
import PropTypes from "prop-types"
import {useDispatch, useSelector} from "react-redux"
import {login} from "../../redux/authSlice"
import {Navigate} from "react-router-dom"

//страница логина
export function Login() {
	const dispatch = useDispatch()

	// залогинен ли пользователь в системе
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
	// текст ошибки, если не прошел запрос логинизации
	const error = useSelector((state) => state.auth.error)

	// отправка данных формы на сервер
	const onFinish = (values) => {
		dispatch(login(values))
	}

	if (isLoggedIn) {
		return <Navigate to="/projects" replace={true} />
	}

	return (
		<LoginContainer>
			<FormContainer>
				<img src={logo} alt="Логотип" className={"logo"} />
				<Divider className={"divider"} />
				<Form
					requiredMark={false}
					className={"form"}
					name="form"
					labelCol={{
						span: 8,
					}}
					wrapperCol={{
						span: 16,
					}}
					onFinish={onFinish}
					autoComplete="off">
					<Form.Item
						label="Логин"
						name="email"
						rules={[
							{
								required: true,
								message: "Пожалуйста, заполните поле",
							},
						]}>
						<Input placeholder="Имя" />
					</Form.Item>

					<Form.Item
						label="Пароль"
						name="password"
						rules={[
							{
								required: true,
								message: "Пожалуйста, заполните поле",
							},
						]}>
						<Input.Password placeholder="Пароль" />
					</Form.Item>

					{error && <Typography.Text className="error-text">{error}</Typography.Text>}

					<Form.Item
						wrapperCol={{
							offset: 8,
							span: 16,
						}}>
						<Button htmlType="submit" className={"submit-button"}>
							Войти
						</Button>
						<Button type="link" htmlType="button" className={"recovery-button"}>
							Забыли Пароль?
						</Button>
					</Form.Item>
				</Form>
			</FormContainer>
		</LoginContainer>
	)
}

//кнопка с email на который пользователь отправляет вопросы
const MailButton = ({email, subject, body, children}) => {
	return <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ""}&body=${encodeURIComponent(body) || ""}`}>{children}</a>
}

MailButton.propTypes = {
	body: PropTypes.string,
	children: PropTypes.element,
	email: PropTypes.string,
	subject: PropTypes.string,
}

//контейнер страницы логина
const LoginContainer = styled("div")`
	padding-top: 145px;
`

//стилизованный контейнер для почты
const MailContainer = styled("div")`
	display: flex;
	justify-content: center;
	.text {
		margin: 0;
		align-self: center;
	}
	.anchor-button {
		padding: 0;
	}
`

//стилизованный контейнер с формой
const FormContainer = styled("div")`
	margin: 0 auto 80px;
	display: flex;
	width: 640px;
	outline: thin solid #FFFFFF;
	align-items: center;
	flex-direction: column;
	.title {
		font-size: 14px;
		text-align: center;
		background-color: #004990;
		color: white;
		font-weight: 400;
	}
	.logo {
		height: 191px;
		margin: 0 auto;
	}
	.divider {
		margin: 0;
		background-color: #1A26FF;
		height: 3px;
	}
	.submit-button {
		border-radius: 4px;
	}
	.recovery-button {
		color: #1A26FF;
	}
	.form {
		padding: 25px 25px 0;
		width: 100%;
	}
	.error-text {
		display: block;
		margin-bottom: 16px;
		text-align: center;
		color: #ff4d4f;
	}
`

import React from "react"
import "./index.css"
import {createRoot} from "react-dom/client"
import {Provider} from "react-redux"
import {store} from "./redux/store"
import reportWebVitals from "./reportWebVitals"
import {App} from "./App"
import {BrowserRouter} from "react-router-dom"
import {ConfigProvider} from 'antd'
import ru_RU from 'antd/locale/ru_RU'
import './fonts/WixMadeforDisplay-Bold.ttf'
import './fonts/WixMadeforDisplay-ExtraBold.ttf'
import './fonts/WixMadeforDisplay-SemiBold.ttf'
import './fonts/WixMadeforDisplay-Medium.ttf'
import './fonts/WixMadeforDisplay-Regular.ttf';

const container = document.getElementById("root")
const root = createRoot(container)

root.render(
	//<React.StrictMode>
	<ConfigProvider locale={ru_RU}>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</ConfigProvider>
	//</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import {Select, Table, Form, Input, InputNumber, Checkbox, Space} from "antd"
import React, {useEffect, useContext, useState, useRef} from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import {findColumnByName} from "./scripts/findColumnByName"

// Реализует 2 вида форм базового расчёта:
// 1) классическая таблица, у которой редактируются конкретные столбцы и тип ячейки в столбце одинаковый;
// 2) таблица с одним редактируемым столбцом (как правило "tobe"), в нём могут быть ячейки разных типов.
//
// В первом случае параметры ячейки задаются в конфигах столбца в поле columnEditingProps.
// Во втором случае параметры ячейки задаются для каждой строки таблицы в поле rowCellEditingProps,
// а в колонке заполняется поле useRowEditingProps значением true.
//
// Второй вариант по функциональности больше похож на обычную форму с полями, но по дизайну это таблица,
// поэтому пришлось как-то пытаться отображать эти данные в виде таблицы.

export const EditableTable = ({data, columns, onDataChange, useDefaultRowStyle = false, otherTableProps}) => {
	const handleFieldChange = (record, dataIndex, newValue) => {
		const newData = [...data]
		const index = newData.findIndex((item) => item.key === record.key)
		const item = newData[index]

		const column = findColumnByName(columns, dataIndex)
		if (column) {
			let editingProps
			if (column.useRowEditingProps) {
				editingProps = record.rowCellEditingProps
			} else {
				editingProps = column.columnEditingProps
			}

			if (editingProps && editingProps.type === "select") {
				let newItem = {
					...item,
					[editingProps.nameField]: newValue.label,
					[editingProps.guidField]: newValue.value,
				}
				if (editingProps.nameField === "equipmentAttribute") {
					const unitsColumn = findColumnByName(columns, "unitOfMeasurement")

					let unitsEditingProps
					if (unitsColumn.useRowEditingProps) {
						unitsEditingProps = record.rowCellEditingProps
					} else {
						unitsEditingProps = unitsColumn.columnEditingProps
					}

					const selectedUnitIndex = unitsEditingProps.options.findIndex((item) => item.value === newValue.unitFK)

					if (unitsEditingProps && unitsEditingProps.type === "select" && selectedUnitIndex && selectedUnitIndex >= 0) {
						newItem = {
							...newItem,
							[unitsEditingProps.nameField]: unitsEditingProps.options[selectedUnitIndex].label,
							[unitsEditingProps.guidField]: unitsEditingProps.options[selectedUnitIndex].value,
						}
					}
				}

				newData.splice(index, 1, newItem)
			} else {
				const newRecord = {...record, [dataIndex]: newValue}
				newData.splice(index, 1, {...item, ...newRecord})
			}
		}

		onDataChange && onDataChange(newData)
	}

	const mapColumns = (col) => {
		if ((!col.children || col.children.length === 0) && !col.editable) {
			return {...col, onCell: (record) => ({record: record, dataIndex: col.dataIndex, columnRenderProps: col.columnRenderProps})}
		}
		const newCol = {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				columnEditingProps: col.useRowEditingProps ? record.rowCellEditingProps : col.columnEditingProps,
				cellRenderProps: record.cellRenderProps,
				handleFieldChange: handleFieldChange,
			}),
		}
		if (col.children) {
			newCol.children = col.children.map(mapColumns)
		}
		return newCol
	}
	const columnsExtended = columns.map(mapColumns)

	return (
		<StyledTable
			{...otherTableProps}
			size="small"
			columns={columnsExtended}
			dataSource={data}
			pagination={false}
			components={{
				body: {
					row: EditableRow,
					cell: EditableCell,
				},
			}}
			bordered
			rowClassName={(record, index) => {
				if (useDefaultRowStyle) {
					return index % 2 === 0 ? "editable-row editable-row--even" : "editable-row"
				}
				if (record?.isRowEditable === false && !useDefaultRowStyle) {
					return "non-editable-row"
				}

				return "editable-row"
			}}
		/>
	)
}

EditableTable.propTypes = {
	data: PropTypes.array,
	columns: PropTypes.array,
	onDataChange: PropTypes.func,
	useDefaultRowStyle: PropTypes.bool,
	otherTableProps: PropTypes.object,
}

const StyledTable = styled(Table)`
	.editable-row {
		position: relative;

		&--even {
			background-color: #f0f5ff;
		}
	}

	.non-editable-row {
		background-color: #bae7ff;
	}

	.editable-cell-value-wrap {
		height: 32px;
		cursor: pointer;
		padding-left: 12px;
		display: flex;
		align-items: center;
		border: 1px solid #f1f1f1;
		border-radius: 4px;
		margin-left: -1px;
	}

	.editable-row:hover > td,
	.non-editable-row:hover > td {
		background: transparent !important;
	}

	.editable-row:hover .editable-cell-value-wrap {
		border: 1px solid #d9d9d9;
	}

	.non-editable-cell-value-wrap {
		height: 32px;
		padding-left: 12px;
		display: flex;
		align-items: center;
	}

	.actions-cell-wrap {
		display: flex;
		justify-content: center;
	}
`

const EditableContext = React.createContext(null)

const EditableRow = ({...props}) => {
	const [form] = Form.useForm()
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	)
}

const EditableCell = ({
	editable,
	children,
	dataIndex,
	record,
	handleFieldChange,
	columnRenderProps,
	columnEditingProps,
	cellRenderProps,
	...restProps
}) => {
	const [editing, setEditing] = useState(false)
	const inputRef = useRef(null)
	const form = useContext(EditableContext)

	// TODO:
	// Исправить ошибку с вводом 01.1
	const regularExpressionForNumber = /^(-?\d+)((\.|\,)\d+)?$/

	useEffect(() => {
		if (editing && inputRef && inputRef.current) {
			inputRef.current.focus()
			inputRef.current.select()
		}
	}, [editing])

	const toggleEdit = () => {
		setEditing(!editing)
		form.setFieldsValue({[dataIndex]: record[dataIndex]})
	}

	const saveInputValue = async () => {
		const values = form.getFieldValue(dataIndex)
		handleFieldChange(record, dataIndex, values)
	}

	const saveInputNumValue = async () => {
		const values = form.getFieldValue(dataIndex)
		var pattern = regularExpressionForNumber
		
		if (pattern.test(values)) handleFieldChange(record, dataIndex, values)
		else {
			console.warn("Invalid input")
		}
	}

	const saveSelectValue = (value, option) => {
		toggleEdit()
		handleFieldChange(record, dataIndex, option)
	}

	const saveCheckboxValue = (e) => {
		toggleEdit()
		handleFieldChange(record, dataIndex, e.target.checked)
	}

	const getContent = () => {
		if (dataIndex === "actions") {
			return <div className="actions-cell-wrap">{children}</div>
		}

		if (record?.isRowEditable === false && !editable) {
			return children
		}

		if ((record?.isRowEditable === false && editable) || columnEditingProps?.type === "readonly") {
			return <div className="non-editable-cell-value-wrap">{children}</div>
		}

		const isNonEditableCell = !cellRenderProps?.nonEditableCells?.includes(dataIndex) ?? true
		if (editable && isNonEditableCell) {
			if (columnEditingProps && columnEditingProps.type === "checkbox") {
				return (
					<Form.Item style={{margin: 0}} name={dataIndex}>
						<Checkbox checked={record[dataIndex]} onChange={saveCheckboxValue} />
					</Form.Item>
				)
			}

			return editing ? (
				<>
					{!columnEditingProps && (
						<Form.Item style={{margin: 0}} name={dataIndex}>
							<Input ref={inputRef} onChange={saveInputValue} onBlur={() => toggleEdit()} />
						</Form.Item>
					)}
					{columnEditingProps && columnEditingProps.type === "number" && (
						<Form.Item style={{margin: 0}} name={dataIndex}>
							<InputNumber {...columnEditingProps.inputProps} ref={inputRef} onChange={saveInputValue} onBlur={() => toggleEdit()} />
						</Form.Item>
					)}
					{columnEditingProps && columnEditingProps.type === "select" && (
						<Form.Item style={{margin: 0}} name={dataIndex}>
							<Select
								style={{width: "100%"}}
								options={columnEditingProps.options}
								onChange={saveSelectValue}
								autoFocus
								defaultOpen
								allowClear
							/>
						</Form.Item>
					)}
					{columnEditingProps && columnEditingProps.type === "strNum" && (
						<Form.Item
							style={{margin: 0}}
							name={dataIndex}
							rules={[
								{
									pattern: regularExpressionForNumber,
									message: "Неверно введено число",
								},
							]}>
							<Input ref={inputRef} onChange={saveInputNumValue} onBlur={() => toggleEdit()} />
						</Form.Item>
					)}
				</>
			) : (
				<div className="editable-cell-value-wrap" onClick={toggleEdit}>
					{children}
				</div>
			)
		}

		if ((columnRenderProps && columnRenderProps.type === "checkbox") || (columnEditingProps && columnEditingProps.type === "checkbox")) {
			return (
				<Space style={{margin: 0}}>
					<Checkbox disabled checked={record[dataIndex] ?? children[1]} />
				</Space>
			)
		}

		return children
	}

	return (
		<td {...restProps} style={record?.rowStyle}>
			{getContent()}
		</td>
	)
}

EditableCell.propTypes = {
	editable: PropTypes.bool,
	children: PropTypes.array,
	dataIndex: PropTypes.string,
	record: PropTypes.object,
	handleFieldChange: PropTypes.func,
	columnRenderProps: PropTypes.object,
	columnEditingProps: PropTypes.object,
	cellRenderProps: PropTypes.object,
}

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {authApi} from "../pages/Login/api/authAPI"

const initialState = {
	isLoggedIn: false, // залогинен ли пользователь
	user: {}, // данные пользователя
	isLoading: "idle", // идет ли запрос логинизации
	error: null, // текст ошибки, если не прошел запрос логинизации
}

// логин
export const login = createAsyncThunk("app/login", async (loginData, thunkAPI) => {
	try {
		const loginResponse = await authApi.login(loginData)
		localStorage.setItem("aees_token", loginResponse.data.access_token)
		try {
			const meResponse = await authApi.me()
			return meResponse.data
		} catch {
			return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
		}
	} catch (err) {
		if (err.response.status === 400) {
			return thunkAPI.rejectWithValue("Неверный логин пароль")
		} else {
			return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
		}
	}
})

// авторизация и данные о залогиненном пользователе
const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setIsLoggedIn: (state, action) => {
			state.isLoggedIn = action.payload
		},
		setUser: (state, action) => {
			state.user = action.payload
		},
	},
	extraReducers: {
		[login.pending]: (state) => {
			state.error = null
			state.isLoading = "pending"
		},
		[login.fulfilled]: (state, action) => {
			state.isLoggedIn = true
			state.user = action.payload
			state.isLoading = "succeeded"
		},
		[login.rejected]: (state, action) => {
			state.isLoading = "failed"
			state.error = action.payload
		},
	},
})

export const {setIsLoggedIn, setUser} = authSlice.actions

export const authReducer = authSlice.reducer

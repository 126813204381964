export const getNavigationTreeNodeByGuid = (nodes, referenceGuid) => {
	for (let node of nodes) {
		if (node.guid === referenceGuid) {
			return node
		}
		if (node.children && node.children.length > 0) {
			const searchNode = getNavigationTreeNodeByGuid(node.children, referenceGuid)
			if (searchNode) {
				return searchNode
			}
		}
	}
}

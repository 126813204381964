// Расчёт максимальной глубины вложенности, необходим для расчёта высоты таблицы
export const getNestingColumnMaxCount = (columns) => {
	const counts = []
	for (let column of columns) {
		if (column.children && column.children.length > 0) {
			counts.push(getNestingColumnMaxCount(column.children) + 1)
		} else {
			counts.push(1)
		}
	}

	return Math.max(...counts)
}
import {Col, Space} from "antd"
import React from "react"
import {ProjectLogTable} from "./ProjectLogsTable";
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux"
import {NavigationButton} from "../../components/NavigationButton/NavigationButton"

// Страница Справочники
export const ProjectLogs = () => {
    const params = useParams()

    //заголовок таблицы
    const tableTitle = useSelector((state) => state.ProjectLogNavigation.selectedProjectLog.name)

    return (
        <Col flex="auto" style={{height: "calc(100vh - 40px - 24px)", backgroundColor: "#fff", margin: "12px"}}>
            <Space size="middle" style={{marginBottom: "12px"}}>
                <NavigationButton />
            </Space>
            <ProjectLogTable tableName={params.upperTableName? params.upperTableName+'/'+params.tableName: params.tableName} tableTitle={tableTitle} />
        </Col>
    )
}
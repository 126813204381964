import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {projectManagementAPI} from "./api"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"

const initialState = {
	tableData: [],
	objectTableName: "",
	tableColumns: [],

	isLoading: LOADING_STATE_EMPTY, // идет ли запрос за данными для таблицы

	columnFilterValues: {},
}

const generalInfoColumns = [
	{
		title: "Наименование",
		dataIndex: "title",
	},
	{
		title: "Параметр",
		dataIndex: "value",
	},
]

// получет данные для отрисовки таблицы в заключения
export const getElementsTable = createAsyncThunk("elementsTables/getElementTable", async (params, thunkAPI) => {
	try {
		let {elementGuid, activeTab} = params
		if (activeTab === "generalInfo") {
			const response = await projectManagementAPI.getElementGeneralInfo(elementGuid)
			return {response: response.data, activeTab}
		}
		const response = await projectManagementAPI.getElementsParametersTableData(elementGuid)
		return {response: response.data, activeTab: activeTab}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// работа с таблицей на странице заключения
const elementsTablesSlice = createSlice({
	name: "elementsTables",
	initialState,
	reducers: {
		setObjectTableName: (state, action) => {
			state.objectTableName = action.payload
		},
		updateColumnFilterValues: (state, action) => {
			state.columnFilterValues = action.payload
		},
		clearColumnFilterValues: (state) => {
			state.columnFilterValues = null
		},
		setCheckedListRoles: (state, action) => {
			state.checkedListRoles = action.payload
		},
	},
	extraReducers: {
		[getElementsTable.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[getElementsTable.fulfilled]: (state, action) => {
			const {response, activeTab} = action.payload
			if (activeTab === "generalInfo") {
				state.tableColumns = generalInfoColumns
				state.tableData = response
			} else {
				state.tableColumns = response.columns.map((i) => ({...i, ellipsis: true, width: 150}))

				state.tableData = response.data
			}
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getElementsTable.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
		},
	},
})

export const elementsTablesReducer = elementsTablesSlice.reducer
export const {setObjectTableName, updateColumnFilterValues, clearColumnFilterValues} = elementsTablesSlice.actions

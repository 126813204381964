import React, {useEffect, useState, useMemo} from "react"
import PropTypes from "prop-types"
import {Typography, Button, Space} from "antd"
import {getResultTable, setTableValueType} from "../../../../redux/projectManagementModule/resultsTableSlice"
import {useDispatch, useSelector} from "react-redux"
import {CopyOutlined, PlusOutlined} from "@ant-design/icons"
import {ExportExcel} from "../../../../components/ExportExcel"
import {LOADING_STATE_PENDING} from "../../../../utils/loadingState"
import {BaseTable} from "../../../../components/BaseTable/baseTable"
import {Spinner} from "../../../../components/Spinner/spinner"
import {ModalEditor} from "../../../../components/ModalEditor/ModalEditor"
import {defaultColumnParams} from "../../../../components/BaseTable/defaultColumnParams"
import {StyledTabs} from "../../../../components/StyledTabs"
import {LinkButton} from "../../../../components/Buttons/linkButton"
import {cardprojectAPI} from "../../api/cardprojectAPI"
import AddResultsModal from "./AddResultsModal"

// таблица
export const ResultTable = ({tableName, tableTitle}) => {
	const dispatch = useDispatch()

	//колонки таблицы
	const tableColumns = useSelector((state) => state.projectResultsTables.tableColumns)
	//данные таблицы
	const tableData = useSelector((state) => state.projectResultsTables.tableData)
	// идет ли запрос на бэк за данными для таблицы
	const isLoading = useSelector((state) => state.projectResultsTables.isLoading)

	const projectGuid = useSelector((state) => state.projectCard.guid)

	const columnFilterValues = useSelector((state) => state.projectResultsTables.columnFilterValues)

	const valueType = useSelector((state) => state.projectResultsTables.valueType)

	const breadcrumbs = useSelector((state) => state.projectResultsNavigation.breadcrumbsArray)

	const [selectedId, setSelectedId] = useState("")
	const [showModalEditor, setShowModalEditor] = useState(false)
	const [showAddingResultsModal, setShowAddingResults] = useState(false)

	const loadData = () => {
		dispatch(getResultTable({workCategory: tableName, projectGuid: projectGuid, valueType: valueType}))
	}

	const showAddModal = () => {
		setShowModalEditor(true)
	}

	const showAddsModal = () => {
		setShowAddingResults(true)
	}

	const showEditModal = (record) => {
		setSelectedId(record.guid)
		setShowModalEditor(true)
	}

	const handleSuccess = () => {
		setSelectedId("")
		setShowModalEditor(false)
		setShowAddingResults(false)
		loadData()
	}

	const handleCancel = () => {
		setSelectedId("")
		setShowAddingResults(false)
		setShowModalEditor(false)
	}

	const nameCell = (text, record) => {
		return (
			<LinkButton
				text={text}
				handleClick={() => {
					showEditModal(record)
				}}
			/>
		)
	}

	async function duplicateRow(resultGuid) {
		try {
			await cardprojectAPI.postCopyResult(resultGuid)
			loadData()
		} catch (e) {}
	}

	const duplicateCellRender = (text, record) => {
		return (
			<Button
				onClick={() => {
					duplicateRow(record.guid)
				}}>
				<CopyOutlined />
			</Button>
		)
	}

	const imageCellRender = (text, record) => {
		return (
			<a href={record.filePath} target="_blank">
				{record.filePath}
			</a>
		)
	}

	const mappedColumns = useMemo(() => {
		//  сортировщик (для чисел и строк)
		const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || "").localeCompare(b || "") : a - b)

		const preColumns = tableColumns.map((x) => {
			const column = {
				...x,
				...defaultColumnParams,
				columnTitle: x.title,
				sorter: (a, b) => sorter(a[x.dataIndex], b[x.dataIndex]),
			}

			if (x.dataIndex === "filePath") {
				return {...column, width: 300, render: imageCellRender}
			}
			if (x.dataIndex === "name" || x.dataIndex === "work" || x.dataIndex === "fileName") {
				return {...column, render: nameCell}
			}

			return column
		})

		const duplicateColumns = preColumns.find((column) => column.dataIndex === "duplicate")

		if (!duplicateColumns && valueType !== "files")
			preColumns.unshift({width: 64, title: "", dataIndex: "duplicate", columnTitle: "Действие", render: duplicateCellRender})

		return preColumns
	}, [tableColumns])

	const twoTabs = [
		{
			label: "Числовые результаты",
			key: "numericalresults",
		},
		{
			label: "Графические результаты",
			key: "graphicalresults",
		},
		{
			label: "Файлы",
			key: "files",
		},
	]

	const handleTabChange = (newValue) => {
		dispatch(setTableValueType(newValue))
	}

	//запрос данных
	useEffect(() => {
		if (tableName) {
			loadData()
		}
	}, [tableName, valueType, projectGuid])

	if (isLoading === LOADING_STATE_PENDING) {
		return <Spinner />
	}

	return (
		<>
			<Space direction="vertical" size="small" style={{width: "100%"}}>
				<Typography.Title level={3}>
					{tableTitle}({breadcrumbs.length > 1 && breadcrumbs[0].name})
				</Typography.Title>
				<StyledTabs type="card" items={twoTabs} activeKey={valueType} onChange={handleTabChange} destroyInactiveTabPane />
				<Space>
					{valueType === "numericalresults" ? (
						<Button
							onClick={showAddsModal}
							icon={<PlusOutlined />}
							type="primary"
							className="mainColor addButton">{`Добавить результаты`}</Button>
					) : (
						<Button
							onClick={showAddModal}
							icon={<PlusOutlined />}
							type="primary"
							className="mainColor addButton">{`Добавить результат`}</Button>
					)}

					<ExportExcel dataToExport={tableData} tableHeader={tableColumns} fileName={tableName} />
				</Space>
				<BaseTable
					data={tableData}
					columns={mappedColumns}
					pageSize={100}
					isLoading={isLoading === LOADING_STATE_PENDING}
					pageContentHeight={428}
					filteredValues={columnFilterValues}
				/>
			</Space>

			{showModalEditor && (
				<ModalEditor
					entityName={valueType}
					isProjects={true}
					entityId={selectedId}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					useLink={valueType === "files"}
					link={`${valueType}/getform?projectGuid=${projectGuid}&workGuid=${tableName}`}
				/>
			)}
			{showAddingResultsModal && <AddResultsModal workGuid={tableName} onSuccess={handleSuccess} onCancel={handleCancel} />}
		</>
	)
}

ResultTable.propTypes = {
	tableName: PropTypes.string, // название Таблицы
	tableTitle: PropTypes.string, // подпись Таблицы
}

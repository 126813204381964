import {TreeSelect} from "antd"
import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import styled from "styled-components"
import {referencesAPI} from "../../pages/References/api/referencesAPI"

export function ModalTreeSelect({entityId}) {
	const modalDataTree = useSelector((state) => state.modalEditor.tree)

	const [selectedBranches, setSelectedBranches] = useState([])

	async function handleChange(val, lab, ex) {
		console.log(val, lab, ex)
		if (ex.checked) {
			await referencesAPI.setPerimeterAcces(val, entityId)
			const result = await referencesAPI.getPerimeterAcces(entityId)
			setSelectedBranches(result?.data.map((el) => el.guid))
		} else {
			await referencesAPI.deletePerimeterAcces(ex.triggerValue, entityId)
			const result = await referencesAPI.getPerimeterAcces(entityId)
			setSelectedBranches(result?.data.map((el) => el.guid))
		}
	}

	useEffect(() => {
		referencesAPI.getPerimeterAcces(entityId).then((res) => {
			const guidsArr = res.data.map((branch) => branch.guid)
			setSelectedBranches(guidsArr)
		})
	}, [])

	useEffect(() => {
		//console.log(selectedBranches)
		// setSelectedBranches((prev) => {
		// 	console.log(prev)
		// })
		// if (exess) {
		// 	referencesAPI.deletePerimeterAcces(exess, entityId)
		// 	const result = referencesAPI.getPerimeterAcces(entityId)
		//
		// 	setSelectedBranches(result?.data)
		//
		// 	console.log("exess")
		// } else {
		// 	referencesAPI.setPerimeterAcces(val, entityId)
		// 	const result = referencesAPI.getPerimeterAcces(entityId)
		//
		// 	setSelectedBranches(result?.data)
		// 	console.log("none")
		// }
	}, [selectedBranches])
	return (
		<CustomTreeSelect
			popupClassName={"customSelect"}
			showCheckedStrategy={"SHOW_PARENT"}
			treeCheckable={true}
			treeData={modalDataTree}
			value={selectedBranches}
			fieldNames={{label: "name", value: "guid"}}
			listHeight={1000000}
			treeNodeFilterProp="name"
			dropdownStyle={{
				display: "block",
				top: "610px",
				left: "calc(50% - 172px)",
				visibility: "visible",
				maxHeight: "calc(100vh - 50px)",
				overflow: "auto",
				boxShadow: "none",
				position: "absolute",
				height: "320px",
				overflowY: "scroll",
				backgroundColor: "white",
				border: "1px solid gray",
				width: "400px",
			}}
			open={true}
			treeLine={true}
			treeDefaultExpandedKeys={modalDataTree[0]?.guid ? [modalDataTree[0].guid] : []}
			onChange={handleChange}
		/>
	)
}

const CustomTreeSelect = styled(TreeSelect)`
	height: 400px;
	width: 400px;
	left: calc(50% - 172px);
	.customSelect {
		width: 400px;
	}
`

import React from "react"
import {Col} from "antd"
import styled from "styled-components"

export const Help = () => {
	return (
		<ColStyled>
		</ColStyled>
	)
}

const ColStyled = styled(Col)`
	background-color: #fff;
	margin: 16px;
`

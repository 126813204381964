import {Button, Checkbox, Modal} from "antd"
import PropTypes from "prop-types"
import {useState} from "react"
import styled from "styled-components"
import DisableBodyScroll from "../../../../components/DisableBodyScroll"

export const ChangeWorkStatusModalWindow = ({currentStatus, newStatus, onSuccess, onCancel, workName}) => {
	const [elementIsBad, setElementISBad] = useState(false)

	const Header = () => {
		if (currentStatus === 1) {
			if (newStatus === 2) return <StyledHeader>Отправить работу на согласование?</StyledHeader>

			if (newStatus === 0) return <StyledHeader>Удалить работу?</StyledHeader>
		}
		if (currentStatus === 2) {
			if (newStatus === 3) {
				return <StyledHeader>Согласовать работу?</StyledHeader>
			}
			if (newStatus === 4) {
				return <StyledHeader>Отклонить работу и отправить на доработку?</StyledHeader>
			}
		}

		if (currentStatus === 3) return <StyledHeader>Отклонить работу и отправить на доработку?</StyledHeader>

		if (currentStatus === 4) {
			return <StyledHeader>Удалить работу?</StyledHeader>
		}
		if (currentStatus === 5 || currentStatus === 6) return <StyledHeader>Завершить работу?</StyledHeader>

		if (currentStatus === 0) return <StyledHeader>Восстановить работу?</StyledHeader>
	}

	const Footer = () => {
		if (currentStatus === 1) {
			if (newStatus === 2)
				return (
					<>
						<Button onClick={handleCancel}>Отмена</Button>
						<Button type="primary" onClick={() => onSuccess(undefined, newStatus)}>
							Да, отправить
						</Button>
					</>
				)
			if (newStatus === 0) {
				return (
					<>
						<Button onClick={handleCancel}>Отмена</Button>
						<Button type="primary" onClick={() => onSuccess(undefined, newStatus)}>
							Удалить
						</Button>
					</>
				)
			}
		}
		if (currentStatus === 2) {
			if (newStatus === 3) {
				return (
					<>
						<Button onClick={handleCancel}>Отмена</Button>
						<Button type="primary" onClick={() => onSuccess(undefined, newStatus)}>
							Согласовать
						</Button>
					</>
				)
			}
			if (newStatus === 4) {
				return (
					<>
						<Button onClick={handleCancel}>Отмена</Button>
						<Button type="primary" onClick={() => onSuccess(undefined, newStatus)}>
							Отклонить работу
						</Button>
					</>
				)
			}
		}

		if (currentStatus === 3)
			// Отклонение работы работником лаборатории
			return (
				<>
					<Button onClick={handleCancel}>Отмена</Button>
					<Button type="primary" onClick={() => onSuccess(elementIsBad, newStatus)}>
						Отклонить
					</Button>
				</>
			)
		if (currentStatus === 4) {
			return (
				<>
					<Button onClick={handleCancel}>Отмена</Button>
					<Button type="primary" onClick={() => onSuccess(undefined, newStatus)}>
						Удалить
					</Button>
				</>
			)
		}
		if (currentStatus === 5 || currentStatus === 6)
			return (
				<>
					<Button onClick={handleCancel}>Отмена</Button>
					<Button type="primary" onClick={() => onSuccess(undefined, newStatus)}>
						Завершить
					</Button>
				</>
			)
		if (currentStatus === 0)
			return (
				<>
					<Button onClick={handleCancel}>Отмена</Button>
					<Button type="primary" onClick={() => onSuccess(undefined, newStatus)}>
						Восстановить
					</Button>
				</>
			)
	}

	const handleCancel = () => {
		onCancel()
	}

	return (
		<Modal title={<Header />} open={true} centered onCancel={handleCancel} footer={<Footer />} width={"400px"}>
			<DisableBodyScroll />
			{currentStatus === 3 && workName !== "Визуально-измерительный контроль" && workName !== "Изготовление образцов" && (
				<Checkbox onClick={(v) => setElementISBad(v.target.checked)}>
					Добавить в Журнал регистрации проб запись о непригодности пробы
				</Checkbox>
			)}
		</Modal>
	)
}

const StyledHeader = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: -8px;
`

ChangeWorkStatusModalWindow.propTypes = {
	currentStatus: PropTypes.number,
	newStatus: PropTypes.number,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
	workName: PropTypes.string,
}

import {Row} from "antd"
import React from "react"
import {useSelector} from "react-redux"
import {Navigate, Outlet} from "react-router-dom"

// лейаут для страницы Проектов
export const ProjectContainer = () => {
	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

	if (!isLoggedIn) {
		return <Navigate to="/login" replace={true} />
	}

	return (
		<Row wrap={false}>
			<Outlet />
		</Row>
	)
}

import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NavigationTree} from "../../../../components/NavigationTree/NavigationTree"
import {getWorksTreeData, setSelectedWork} from "../../../../redux/projectManagementModule/worksNavigationSlice"
import {useNavigate, useParams} from "react-router-dom"

// Боковая часть с навигацией на странице Справочников
export const WorksNavigation = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const params = useParams()

	const isLoading = useSelector((state) => state.projectWorksNavigation.isLoading)
	const treeData = useSelector((state) => state.projectWorksNavigation.treeData)
	const selectedWorkGuid = useSelector((state) => state.projectWorksNavigation.selectedWork.guid)

	// выбирает справочник, перерисовывает хлебные крошки
	const onChange = (newValue, selectedWork) => {
		dispatch(setSelectedWork({guid: selectedWork.guid, name: selectedWork.name}))
		navigate(`${selectedWork.guid}`)
	}

	useEffect(() => {
		dispatch(getWorksTreeData())
	}, [])

	useEffect(() => {
		if (treeData.length !== 0) {
			const selectedWork = treeData.find((dt) => dt.guid === params.workType)
			if (selectedWork) onChange("", selectedWork)
		}
	}, [params.workType, treeData])

	return <NavigationTree treeData={treeData} value={selectedWorkGuid} onChange={onChange} isLoading={isLoading} />
}

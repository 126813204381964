import {Col, Space} from "antd"
import React from "react"
import {ReferencesBreadcrumbs} from "./ReferencesBreadcrumbs"
import {ReferenceTable} from "./ReferenceTable"
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux"
import {NavigationButton} from "../../components/NavigationButton/NavigationButton"

// Страница Справочники
export const References = () => {
	const params = useParams()

	//заголовок таблицы
	const tableTitle = useSelector((state) => state.referencesNavigation.selectedReference.name)

	return (
		<Col flex="auto" style={{height: "calc(100vh - 40px - 24px)", backgroundColor: "#fff", margin: "12px"}}>
			<Space size="middle" style={{marginBottom: "12px"}}>
				<NavigationButton />
				<ReferencesBreadcrumbs />
			</Space>
			<ReferenceTable tableName={params.tableName} tableTitle={tableTitle} />
		</Col>
	)
}

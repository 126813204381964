export const findColumnByName = (columns, columnName) => {
	if (!columns) {
		return
	}

	for (const item of columns) {
		if (item.dataIndex === columnName) {
			return item
		} else {
			const result = findColumnByName(item.children, columnName)
			if (!result) {
				continue
			}

			return result
		}
	}
}

import {Row} from "antd"
import React, {useEffect} from "react"
import {useDispatch} from "react-redux"
import {Outlet} from "react-router-dom"
import {ReportNavigation} from "./ReportNavigation"
import {setProjectCardActiveTab} from "../../../../redux/projectManagementModule/projectCardSlice"

// лейаут для страницы Карточка проекта -> Результаты
export const ReportContainer = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setProjectCardActiveTab("report"))
	}, [])

	return (
		<Row wrap={false}>
			<ReportNavigation />
			<Outlet />
		</Row>
	)
}

import {Breadcrumb} from "antd"
import React from "react"
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux"
import {setReferencesBreadcrumbs, setSelectedReference} from "../../redux/referencesNavigationSlice"
import {Link, useNavigate} from "react-router-dom"

//хлебные крошки на странице справочники
export const ReferencesBreadcrumbs = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const breadcrumbsArray = useSelector((state) => state.referencesNavigation.breadcrumbsArray)
	const selectedReferenceGuid = useSelector((state) => state.referencesNavigation.selectedReference.guid)

	// выбирает справочник, перерисовывает крошки
	const onBreadcrumbClick = (selectedReference) => {
		if (selectedReferenceGuid !== selectedReference.guid) {
			dispatch(setSelectedReference(selectedReference))
			dispatch(setReferencesBreadcrumbs(selectedReference.guid))
			if (selectedReference.referenceName) {
				navigate(`/references/${selectedReference.referenceName}`)
			} else {
				navigate(`/references`)
			}
		}
	}
	const onMainBreadcrumbClick = () => {
	}

	return (
		<BreadcrumbsStyled>
			<Breadcrumb.Item key="main" onClick={onMainBreadcrumbClick}>
				<Link to="/" className={"main-link text"}>
					Главная
				</Link>
			</Breadcrumb.Item>
			{breadcrumbsArray.map((item, index) => (
				<Breadcrumb.Item
					key={index}
					className={item.guid !== selectedReferenceGuid ? "link text" : "text"}
					onClick={() => onBreadcrumbClick(item)}>
					{item.name}
				</Breadcrumb.Item>
			))}
		</BreadcrumbsStyled>
	)
}

const BreadcrumbsStyled = styled(Breadcrumb)`
	.main-link:hover {
		color: rgba(0, 0, 0, 0.45);
	}
	.link:hover {
		cursor: pointer;
	}
	.text {
		font-size: 12px;
	}
`

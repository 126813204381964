export const validateMessages = {
	required: "Поле является обязательным",
	pattern: {
		mismatch: "Неверный формат данных",
	},
	string: {
		len: "Поле должно содержать ${len} символов",
		min: "Минимальное количество символов: ${min}",
		max: "Максимальное количество символов: ${max}",
		range: "Поле должно содержать от ${min} до ${max} символов",
	},
}

const numberRule = {
	pattern: new RegExp(/^-?\d+$/),
}

const decimalRule = {
	  pattern: new RegExp(/^-?\d+(\.\d+)?$/),
}

const getPatternRule = (pattern) => {
	return new {
		pattern: new RegExp(pattern),
	}()
}

const requiredRule = {
	required: true,
}

export const mapValidationRules = (serverRules) => {
	if (!serverRules) return []

	const rules = []

	if (serverRules.required) {
		rules.push(requiredRule)
	}
	if (serverRules.min || serverRules.max || serverRules.len) {
		const stringRule = {
			type: "string",
		}

		if (serverRules.max) {
			stringRule["max"] = serverRules.max
		}
		if (serverRules.min) {
			stringRule["min"] = serverRules.min
		}
		if (serverRules.len) {
			stringRule["len"] = serverRules.len
		}

		rules.push(stringRule)
	}
	if (serverRules.type === "number") {
		rules.push(numberRule)
	}
	if (serverRules.type === "decimal") {
		rules.push(decimalRule)
	}
	if (serverRules.pattern) {
		rules.push(getPatternRule(serverRules.pattern))
	}

	return rules
}

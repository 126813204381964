import React from "react"
import {Spin} from "antd"
import styled from "styled-components"

export const Spinner = () => {
	return (
		<SpinContainer>
			<Spin size="large" />
		</SpinContainer>
	)
}

const SpinContainer = styled("div")`
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 347px);
`

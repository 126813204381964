import customAxios from "../../../api/customAxios"
import {DPTableNames} from "../DigitalProfilesTableNames"

//кэш запросов элементов для выпадающих списков
const optionsCacheMap = new Map()

// методы страницы справочников
export const referencesAPI = {
	getReferenceBaseUrl() {
		return "/api/vnikti-references/v1"
	},
	getReferencesNavigationArray() {
		return customAxios.get("/api/vnikti-references/v1/referencenavigation")
	},
	getReferenceTableData(tableName) {
		if (DPTableNames.includes(tableName)) {
			return customAxios.get(`/api/vnikti-digitalprofiles/v1/${tableName}/columns`)
		} else return customAxios.get(`/api/vnikti-references/v1/${tableName}/columns`)
	},
	getReferenceFormInfo(tableName, guid) {
		const additionalParam = guid ? `?guid=${guid}` : ""
		if (DPTableNames.includes(tableName)) {
			return customAxios.get(`/api/vnikti-digitalprofiles/v1/${tableName}/form${additionalParam}`)
		} else return customAxios.get(`/api/vnikti-references/v1/${tableName}/form${additionalParam}`)
	},
	getPerimeterAcces(userGuid) {
		return customAxios.get(`/api/vnikti-references/v1/access-perimeter/${userGuid}`)
	},
	setPerimeterAcces(guidArr, userGuid) {
		return customAxios.post(`/api/vnikti-references/v1/access-perimeter?userGuid=${userGuid}`, guidArr)
	},
	deletePerimeterAcces(guidArr, userGuid) {
		return customAxios.delete(`/api/vnikti-references/v1/access-perimeter?userGuid=${userGuid}`, {data: [guidArr]})
	},
	async getReferenceOptions(url) {
		// ДЛЯ СИСТЕМ БЕЗ ДИНАМИЧЕСКОГО ДОБАВЛЕНИЯ
		// if (!optionsCacheMap.has(url)) {
		// 	try {
		// 		const response = await customAxios.get(url)
		// 		optionsCacheMap.set(url, response.data)
		// 	} catch (error) {
		// 		return []
		// 	}
		// }

		try {
			const response = await customAxios.get(url)
			optionsCacheMap.set(url, response.data)
		} catch (error) {
			return []
		}
		return optionsCacheMap.get(url)
	},

	//добавление строки таблицы
	postReferenceTableRow(tableName, requestOptions) {
		if (DPTableNames.includes(tableName)) {
			return customAxios.post(`/api/vnikti-digitalprofiles/v1/${tableName}`, requestOptions)
		} else return customAxios.post(`/api/vnikti-references/v1/${tableName}`, requestOptions)
	},

	//редактирование строки таблицы
	editReferenceTableRow(tableName, requestOptions) {
		if (DPTableNames.includes(tableName)) {
			return customAxios.put(`/api/vnikti-digitalprofiles/v1/${tableName}`, requestOptions)
		} else return customAxios.put(`/api/vnikti-references/v1/${tableName}`, requestOptions)
	},

	//удаление строки таблицы
	deleteReferenceTableRow(tableName, guid) {
		if (DPTableNames.includes(tableName)) {
			return customAxios.delete(`/api/vnikti-digitalprofiles/v1/${tableName}/${guid}`)
		} else return customAxios.delete(`/api/vnikti-references/v1/${tableName}/${guid}`)
	},

	//список функциональных ролей
	getFunctionalRoles() {
		return customAxios.get(`/api/vnikti-references/v1/functionalroles`)
	},

	//список ролей, которые принадлежат пользователю
	getSelectedUserRoles(userGuid) {
		return customAxios.get(`/api/vnikti-references/v1/functionalroles/user/${userGuid}`)
	},

	// добавление пользователю роли
	postUserNewRole(userGuid, roleGuid) {
		return customAxios.post(`/api/vnikti-references/v1/functionalroles/add-to-user?userGuid=${userGuid}&roleGuid=${roleGuid}`)
	},

	//удаление роли пользователя
	deleteUserRole(userGuid, roleGuid) {
		return customAxios.delete(`/api/vnikti-references/v1/functionalroles/remove-from-user?userGuid=${userGuid}&roleGuid=${roleGuid}`)
	},

	getSectors() {
		return customAxios.get(`/api/vnikti-references/v1/sectors`)
	},

	getSelectedUserSectors(userGuid) {
		return customAxios.get(`/api/vnikti-references/v1/usersectors/user/${userGuid}`)
	},

	postUserNewSector(userGuid, roleGuid) {
		return customAxios.post(`/api/vnikti-references/v1/usersectors/add-to-user?userGuid=${userGuid}&sectorGuid=${roleGuid}`)
	},

	deleteUserSector(userGuid, roleGuid) {
		return customAxios.delete(`/api/vnikti-references/v1/usersectors/remove-from-user?userGuid=${userGuid}&sectorGuid=${roleGuid}`)
	},

	// Для добавление из селекта модального окна
	postFormSelect(url, requestOptions){
		return customAxios.post(url, requestOptions)
	},
}

import {Button, ConfigProvider, Empty, Spin, TreeSelect, Typography} from "antd"
import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import {useDispatch, useSelector} from "react-redux"
import {PushpinFilled} from "@ant-design/icons"
import {setIsNavigationCollapsed} from "../../redux/layoutSlice"

// Боковая часть с навигацией. общий компонент с tree select
export const NavigationTree = ({treeData, value, onChange, isLoading}) => {
	const dispatch = useDispatch()
	//видно ли навигационное дерево
	const isNavigationCollapsed = useSelector((state) => state.layout.isNavigationCollapsed)

	const handleTreeChange = (newValue, selectedObject) => {
		if (isNavigationCollapsed !== "fixed") {
			dispatch(setIsNavigationCollapsed())
		}
		onChange(newValue, selectedObject)
	}

	return (
		<NavigationCol isNavigationCollapsed={isNavigationCollapsed}>
			<div className="blur" onClick={() => dispatch(setIsNavigationCollapsed())} />
			<div className="menu-content">
				<div className="title-container">
					<div className="navigation-title">
						<Typography.Text>Навигация</Typography.Text>
					</div>
					<StyledButton collapsed={isNavigationCollapsed} onClick={() => dispatch(setIsNavigationCollapsed("fixed"))}>
						<PushpinFilled />
					</StyledButton>
				</div>

				{isLoading === "pending" ? (
					<div className="spin">
						<Spin />
					</div>
				) : (
					<ConfigProvider renderEmpty={customizeRenderEmpty}>
						<TreeSelect
							className="navigation-select"
							value={value}
							dropdownStyle={{
								left: isNavigationCollapsed === "closed" ? "-320px" : "0px",
								visibility: isNavigationCollapsed === "closed" ? "hidden" : "visible",
								maxHeight: "calc(100vh - 250px)",
								overflow: "auto",
								boxShadow: "none",
								backgroundColor: "#f5f5f5",
								zIndex: 10,
							}}
							treeData={treeData}
							fieldNames={{label: "name", value: "guid"}}
							placeholder="Поиск"
							onSelect={handleTreeChange}
							open
							listHeight={window.innerHeight - 175}
							showSearch
							treeNodeFilterProp="name"
							popupClassName={"navigationSelectPopup"}
						/>
					</ConfigProvider>
				)}
			</div>
		</NavigationCol>
	)
}

const NavigationCol = styled("div")`
	.menu-content {
		display: flex;
		flex-direction: column;
		min-width: 320px;
		height: calc(100vh - 40px);
		background-color: #f5f5f5;
		border-right: 1px solid #ccc;
		z-index: 10;
		transform: ${({isNavigationCollapsed}) => (isNavigationCollapsed === "closed" ? "translate(-320px)" : "translate(0)")};
		visibility: ${({isNavigationCollapsed}) => (isNavigationCollapsed === "closed" ? "hidden" : "visible")};
		position: ${({isNavigationCollapsed}) => (isNavigationCollapsed === "fixed" ? "static" : "fixed")};
	}
	.blur {
		position: absolute;
		display: ${({isNavigationCollapsed}) => (isNavigationCollapsed === "open" ? "block" : "none")};
		z-index: 5;
		width: 100vw;
		height: 100vh;
		background-color: #000000;
		opacity: 0.6;
	}
	.title-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-bottom: 10px;
		margin: 12px 16px 10px 16px;
	}
	.navigation-title {
		align-self: end;
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 0.25px;
	}
	.navigation-select {
		width: 288px;
		margin: 0 16px;
	}
	.spin {
		display: flex;
		justify-content: center;
		margin-top: 50px;
	}
`

const StyledButton = styled(Button)`
	background-color: ${({collapsed}) => (collapsed === "fixed" ? "rgb(26, 38, 255)" : "#FFF")};

	svg {
		fill: ${({collapsed}) => (collapsed === "fixed" ? "#FFF" : "rgb(26, 38, 255)")};
	}
`

// кастомизированный вывод картинки с отсутствием данных поиска
const customizeRenderEmpty = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Нет данных"} />

NavigationTree.propTypes = {
	treeData: PropTypes.array, // массив объектов с вложенностями
	value: PropTypes.string, // выбранный узел дерева
	onChange: PropTypes.func, // изменение выбранного узла
	isLoading: PropTypes.string, // загружается ли информация о структуре селекта с сервера
}

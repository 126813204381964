import {Button, Col} from "antd"
import React, {useEffect, useMemo, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {BaseTable} from "../../../../components/BaseTable/baseTable"
import {LOADING_STATE_PENDING} from "../../../../utils/loadingState"
import {Spinner} from "../../../../components/Spinner/spinner"
import {defaultColumnParams} from "../../../../components/BaseTable/defaultColumnParams"
import {PlusOutlined} from "@ant-design/icons"
import {ModalEditor} from "../../../../components/ModalEditor/ModalEditor"
import {
	getFragmentElements,
	getFragmentOperationalParams,
	getFragmentTechnicalParams,
} from "../../../../redux/projectManagementModule/fragmentsAndElementsSlice"
import {useParams} from "react-router-dom"
import {LinkButton} from "../../../../components/Buttons/linkButton"

export const SubFragmentTable = () => {
	const dispatch = useDispatch()
	const params = useParams()

	const [modalActive, setModalActive] = useState(false)
	const [tableName, setTableName] = useState("")
	const [selectedFragmentsAndElementsId, setSelectedFragmentsAndElementsId] = useState("")

	const {activeFragmentGuid, activeFragmentName, elementColumns, elements, activeSubTabName, isLoadingElements} = useSelector(
		(state) => state.projectFragmentAndElement
	)

	const nameCell = (text, record) => {
		return (
			<LinkButton
				text={text}
				handleClick={() => {
					handleNameClick(record)
				}}
			/>
		)
	}

	const elementExtendedColumns = useMemo(() => {
		return elementColumns.map((x) => {
			const column = {...x, ...defaultColumnParams}
			if (x.dataIndex === "equipmentAttribute" || x.dataIndex === "name") {
				return {...column, render: nameCell}
			}
			return column
		})
	}, [elementColumns])

	const handleNameClick = (record) => {
		setSelectedFragmentsAndElementsId(record.guid)
		handleAddButtonClick()
	}

	const handleAddButtonClick = () => {
		if (params.fragmentTab === "elements") {
			setTableName("elements")
		} else if (params.fragmentTab === "technical") {
			setTableName("technical")
		} else if (params.fragmentTab === "operational") {
			setTableName("operational")
		} else return 0

		setModalActive(true)
	}

	const handleSuccess = () => {
		if (params.fragmentTab === "elements") {
			dispatch(getFragmentElements(activeFragmentGuid))
		} else if (params.fragmentTab === "technical") {
			dispatch(getFragmentTechnicalParams(activeFragmentGuid))
		} else if (params.fragmentTab === "operational") {
			dispatch(getFragmentOperationalParams(activeFragmentGuid))
		} else return 0
		setModalActive(false)
		setSelectedFragmentsAndElementsId("")
	}

	const handleCancel = () => {
		setModalActive(false)
		setSelectedFragmentsAndElementsId("")
	}

	useEffect(() => {
		if (activeFragmentGuid) {
			if (params.fragmentTab === "elements") dispatch(getFragmentElements(activeFragmentGuid))
			if (params.fragmentTab === "technical") dispatch(getFragmentTechnicalParams(activeFragmentGuid))
			if (params.fragmentTab === "operational") dispatch(getFragmentOperationalParams(activeFragmentGuid))
		}
	}, [params.fragmentTab, activeFragmentGuid])

	if (isLoadingElements === LOADING_STATE_PENDING) {
		return <Spinner />
	}

	return (
		<Col span={12}>
			<Button
				type="primary"
				icon={<PlusOutlined />}
				onClick={() => {
					handleAddButtonClick("e")
				}}
				disabled={!activeFragmentGuid}>
				{`Создание [${activeSubTabName}] для элемента "${activeFragmentName}"`}
			</Button>

			<BaseTable data={elements} columns={elementExtendedColumns} filteredValues={{}} />

			{modalActive && (
				<ModalEditor
					entityName={tableName}
					isProjects={true}
					entityId={selectedFragmentsAndElementsId}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					link={`${tableName}/form/${activeFragmentGuid}`}
				/>
			)}
		</Col>
	)
}

import React from "react"
import {Col} from "antd"

// текст, который показывается если еще не выбрана категория работы
export const EmptyFragmentsSubPage = () => {
	return (
		<Col span={12}>
			Выберите элемент, чтобы отобразить информацию по нему
		</Col>
	)
}

import React, {useState, useEffect, useRef} from "react"
import {Table, Button, Input, Space, Form, Typography} from "antd"
import {SearchOutlined} from "@ant-design/icons"
import PropTypes from "prop-types"
import styled from "styled-components"
import {useVT} from "virtualizedtableforantd4"
import {COUNT_RECORDS_PER_PAGE} from "../../utils/paginationConstants"
import {BaseTableEditableCell} from "./baseTableEditableCell"
import {baseTableParams} from "./baseTableParams"
import {ColumnFilter} from "./components/columnFilter/columnFilter"
import {ResizableTitle} from "./resizableTitle"
import {getNestingColumnMaxCount} from "./scripts/getNestingColumnMaxCount"
import {setColumnsConfigs} from "./scripts/setColumnsConfigs"
import {updateRecordInTable} from "./scripts/updateRecordInTable"

const {Text} = Typography

export const BaseTableEditableContext = React.createContext(null)

export const BaseTable = ({
	data,
	columns,
	isCustomSearch,
	totalItems,
	pageSize,
	isLoading = false,
	dataKey = "guid",
	scrollSettings,
	onTableParamsChange,
	useVirtualTable = false,
	pageContentHeight = 330,
	filteredValues = {},
	editable = false,
	onTableValueChange,
	disablePagination = false,
	onRow,
	rowSelection,
}) => {
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: pageSize || COUNT_RECORDS_PER_PAGE,
			showSizeChanger: true,
			showQuickJumper: true,
			locale: {items_per_page: "", jump_to: "Перейти на", page: "стр."},
		},
	})

	const [form] = Form.useForm()
	const [tableData, setTableData] = useState([])
	const [tableColumns, setTableColumns] = useState([])
	const [hasGroupedData, setHasGroupedData] = useState(false)
	const [tableIsEdited, setTableIsEdited] = useState(false)

	const handleFieldChange = (record, dataIndex, value) => {
		setTableData((prev) => {
			const newData = JSON.parse(JSON.stringify(prev))
			updateRecordInTable(newData, record, dataKey, dataIndex, value)
			return newData
		})
		setTableIsEdited(true)
	}

	const getColumnSearchProps = (columnProps) => ({
		filterDropdown: (filterDropdownProps) => <ColumnFilter {...filterDropdownProps} columnProps={columnProps} />,
		filterIcon: (filtered) => <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}} />,
		onFilter: (value, record) =>
			(columnProps.onFilter && columnProps.onFilter(value, record, columnProps.dataIndex)) ??
			record[columnProps.dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
		filteredValue: filteredValues || filteredValues[columnProps?.dataIndex] ? filteredValues[columnProps?.dataIndex] : null,
	})

	const columnRender = (text, record, recordIndex, columnRenderFunc) => {
		if (record.children) {
			return <span>{`${record.value} (${record.children.length})`}</span>
		}
		if (columnRenderFunc) {
			return columnRenderFunc(text, record)
		}
		if (data.isRead) {
			return <b>{text}</b>
		}

		return text
	}

	const getColumnCellProps = (column) => {
		return {
			onCell: (record) => {
				return {
					style: {
						fontWeight: record.statusInt === 2 ? "bold" : "",
						maxWidth: 10000,
						minWidth: 100,
						whiteSpace: "normal",
						overflow: "hidden",
						height: "auto",
						borderRight: "1px solid #f5f5f5",
					},
					colSpan: record.children ? tableColumns.length : 1,
					dataIndex: column.dataIndex,
					record,
					editable: editable && column.editable,
					type: "text",
					title: column.title,
					onFieldChange: handleFieldChange,
					inputType: column.inputType,
				}
			},
		}
	}

	const handleTableParamsChange = (pagination, filters, sorter) => {
		setTableParams({pagination, filters, ...sorter})
		onTableParamsChange && onTableParamsChange(pagination, filters, sorter)
	}

	const handleResize =
		(index) =>
		(_, {size}) => {
			const newColumns = [...tableColumns]
			newColumns[index] = {...newColumns[index], width: size.width}
			setTableColumns(newColumns)
		}

	const resizableColumns = tableColumns.map(({onHeaderCell, ...col}, index) => ({
		...col,
		onHeaderCell: (column) => {
			const otherProps = onHeaderCell ? onHeaderCell(column) : {}
			return {
				width: column.width,
				onResize: handleResize(index),
				...otherProps,
			}
		},
	}))

	const getColumnProps = (column, columnIndex) => {
		const searchProps = isCustomSearch || column.disableFiltering ? {} : getColumnSearchProps(column)
		let columnItem = {
			...column,
			...searchProps,
			...getColumnCellProps(column),
		}

		if (hasGroupedData && columnIndex === 0) {
			const columnRenderFunc = columnItem.render
			columnItem.render = (text, record, recordIndex) => columnRender(text, record, recordIndex, columnRenderFunc)
			columnItem.width = baseTableParams.groupedRowColumnWidth
		}

		return columnItem
	}

	useEffect(() => {
		if (columns) {
			const newColumns = [...columns]
			setColumnsConfigs(newColumns, (columnProps, columnIndex) => getColumnProps(columnProps, columnIndex), {}, true)
			setTableColumns(newColumns)
		}
	}, [columns, hasGroupedData, filteredValues])

	useEffect(() => {
		if (data) {
			setHasGroupedData(data.find((x) => x.children) !== undefined)
		}
		if (editable) {
			setTableIsEdited(false)
		}

		setTableData(data)
	}, [data])

	useEffect(() => {
		setTableParams({
			pagination: {
				...tableParams.pagination,
				total: totalItems,
			},
		})
	}, [totalItems])

	useEffect(() => {
		if (tableData && tableIsEdited) {
			onTableValueChange && onTableValueChange(tableData)
		}
	}, [tableData, tableIsEdited])

	const [vt, setVT] = useVT(() => {
		return {
			scroll: {y: 500},
			debug: true,
		}
	})

	const bodyTableComponents = {
		body: {
			cell: BaseTableEditableCell,
		},
	}

	setVT({
		...bodyTableComponents,
	})

	const tableComponents = useVirtualTable ? vt : bodyTableComponents

	// Пересчёт высоты таблицы (заголовки таблицы могут занимать 2 и более строк)
	const heightStyle = `calc(100vh - ${getNestingColumnMaxCount(columns) * baseTableParams.baseHeaderHeight}px - ${pageContentHeight}px)`

	const table = (
		<TableStyled
			size={"small"}
			columns={resizableColumns}
			dataSource={tableData}
			rowKey={dataKey}
			scroll={scrollSettings || {y: 500}}
			heightStyle={heightStyle}
			rowClassName={(record, index) => {
				if (record.children) {
					return "ant-grouped-row"
				}
			}}
			bordered
			components={{
				...tableComponents,
				header: {
					cell: ResizableTitle,
				},
			}}
			onRow={onRow}
			pagination={!disablePagination && tableParams.pagination}
			loading={isLoading || false}
			onChange={handleTableParamsChange}
			rowSelection={rowSelection}
		/>
	)

	return (
		<>
			{editable ? (
				<Form form={form} component={false}>
					<BaseTableEditableContext.Provider value={form}>{table}</BaseTableEditableContext.Provider>
				</Form>
			) : (
				table
			)}
		</>
	)
}

const TableStyled = styled(Table)`
	.ant-table-body {
		min-height: ${({heightStyle}) => heightStyle};
	}
	.ant-grouped-row {
		background-color: #f5f5f5;
		color: #595959;
		font-weight: 600;
	}

	.ant-grouped-row:hover > td {
		background: #f0f0f0 !important;
	}
	.ant-table-filter-trigger {
		margin: 0;
	}
`

BaseTable.propTypes = {
	data: PropTypes.array,
	columns: PropTypes.array,
	totalItems: PropTypes.number,
	pageSize: PropTypes.number,
	dataKey: PropTypes.string,
	isLoading: PropTypes.bool,
	onTableParamsChange: PropTypes.func,
	scrollSettings: PropTypes.object,
	useVirtualTable: PropTypes.bool,
	pageContentHeight: PropTypes.number,
	filteredValues: PropTypes.object,
	editable: PropTypes.bool,
	onTableValueChange: PropTypes.func,
}

import {configureStore} from "@reduxjs/toolkit"
import {appReducer} from "./appSlice"
import {authReducer} from "./authSlice"
import {layoutReducer} from "./layoutSlice"
import {modalEditorReducer} from "./modalEditorSlice"
import {notificationReducer} from "./notificationSlice"
import {referencesNavigationReducer} from "./referencesNavigationSlice"
import {referencesTablesReducer} from "./referencesTablesSlice"
import {digitalProfilesTablesReducer} from "./digitalProfilesTablesSlice"
import {digitalProfilesNavigationReducer} from "./digitalProfilesNavigationSlice"
import {snackbarReducer} from "./snackbarSlice"
import {projectsTablesReducer} from "./projectsTablesSlice"
import {ProjectLogTablesReducer} from "./ProjectLogsTablesSlice"
import {ProjectLogNavigationReducer} from "./ProgectLogNavigationSlice"
import {projectCardReducer} from "./projectManagementModule/projectCardSlice"
import {basicInformationReducer} from "./projectManagementModule/basicInformationSlice"
import {resultsNavigationReducer} from "./projectManagementModule/resultsNavigationSlice"
import {resultsTablesReducer} from "./projectManagementModule/resultsTableSlice"
import {fragmentsAndElementsReducer} from "./projectManagementModule/fragmentsAndElementsSlice"
import {worksTablesReducer} from "./projectManagementModule/worksTablesSlice"
import {worksNavigationReducer} from "./projectManagementModule/worksNavigationSlice"
import {conclusionTablesReducer} from "./projectManagementModule/conclusionTableSlice"
import {conclusionNavigationReducer} from "./projectManagementModule/conclusionsNavigationSlice"
import { reportNavigationReducer } from "./projectManagementModule/reportNavigationSlice"
import { reportTablesReducer } from "./projectManagementModule/reportTableSlice"
import { finalReportFilesTableReducer } from "./projectManagementModule/finalReportFilesTableSlice"
import { elementsNavigationReducer } from "./projectManagementModule/elementsNavigationSlice"
import { elementsTablesReducer } from "./projectManagementModule/elementsTableSlice"
import { fragmentsNavigationReducer } from "./projectManagementModule/fragmentsNavigationSlice"
import { fragmentsTablesReducer } from "./projectManagementModule/fragmentsTableSlice"

export const store = configureStore({
	reducer: {
		app: appReducer,
		auth: authReducer,
		layout: layoutReducer,
		referencesNavigation: referencesNavigationReducer,
		digitalProfilesNavigation: digitalProfilesNavigationReducer,
		userNotification: notificationReducer,
		referencesTables: referencesTablesReducer,
		digitalProfilesTables: digitalProfilesTablesReducer,
		notification: snackbarReducer,
		modalEditor: modalEditorReducer,
		projectsTable: projectsTablesReducer,
		ProjectLogTables: ProjectLogTablesReducer,
		ProjectLogNavigation: ProjectLogNavigationReducer,
		projectCard: projectCardReducer,
		projectBasicInformation: basicInformationReducer,
		projectFragmentsNavigation: fragmentsNavigationReducer,
		projectFragmentsTables: fragmentsTablesReducer,
		projectElementsNavigation: elementsNavigationReducer,
		projectElementsTables: elementsTablesReducer,
		projectResultsNavigation: resultsNavigationReducer,
		projectResultsTables: resultsTablesReducer,
		projectFragmentAndElement: fragmentsAndElementsReducer,
		projectWorksNavigation: worksNavigationReducer,
		projectWorksTables: worksTablesReducer,
		projectConclusionNavigation: conclusionNavigationReducer,
		projectConclusionTables: conclusionTablesReducer,
		projectReportNavigation: reportNavigationReducer,
		projectReportTables: reportTablesReducer,
		projectFinalReportFilesTable: finalReportFilesTableReducer,
	},
})

window.store = store

import React from "react"
import {Col, Row, Space} from "antd"
import {NavigationButton} from "../../components/NavigationButton/NavigationButton"

//Пустая страница Журнал Событий
export const EmptyProjectLogs = () => {
    return (
        <Row wrap={false} style={{margin: "12px", width: "1200px"}}>
            <Col span={16}>
                <Space size="middle">
                    <NavigationButton />
                    <Row>Выберите в навигации журнал, чтобы отобразить его содержание.</Row>
                </Space>
            </Col>
        </Row>
    )
}
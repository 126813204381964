import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {setSnackbarNotification} from "../snackbarSlice"
import {projectManagementAPI} from "./api"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"
import {cardprojectAPI} from "../../pages/CardProjects/api/cardprojectAPI"

const initialState = {
	tableData: [],
	objectTableName: "",
	tableColumns: [],
	functionalRoles: [], //список функциональных ролей

	isLoading: LOADING_STATE_EMPTY, // идет ли запрос за данными для таблицы

	columnFilterValues: {},
	valueType: "numericalresults",
}

// получет данные для отрисовки таблицы в справочнике
export const getResultTable = createAsyncThunk("resultsTables/getResultTable", async (parameters, thunkAPI) => {
	try {
		const {projectGuid, workCategory, valueType} = parameters
		if (valueType === "numericalresults") {
			const response = await projectManagementAPI.getNumericalResults(projectGuid, workCategory)
			return {response: response.data, workCategory}
		} else if (valueType === "graphicalresults") {
			const response = await projectManagementAPI.getGraphicalReport(projectGuid, workCategory)
			return {response: response.data, workCategory}
		} else if (valueType === "files") {
			const response = await cardprojectAPI.getFiles(projectGuid, workCategory)
			return {response: response.data, workCategory}
		}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// работа с таблицей на странице Справочники
const resultsTablesSlice = createSlice({
	name: "resultsTables",
	initialState,
	reducers: {
		setObjectTableName: (state, action) => {
			state.objectTableName = action.payload
		},
		updateColumnFilterValues: (state, action) => {
			state.columnFilterValues = action.payload
		},
		clearColumnFilterValues: (state) => {
			state.columnFilterValues = null
		},
		setCheckedListRoles: (state, action) => {
			state.checkedListRoles = action.payload
		},
		setTableValueType: (state, action) => {
			state.valueType = action.payload
		},
	},
	extraReducers: {
		[getResultTable.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[getResultTable.fulfilled]: (state, action) => {
			const {response} = action.payload
			state.tableData = response.data
			state.tableColumns = response.columns.map((i) => ({...i, ellipsis: true, width: 150}))
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getResultTable.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
			if (state.tableColumns.length !== 0) state.tableData = []
		},
	},
})

export const resultsTablesReducer = resultsTablesSlice.reducer
export const {setObjectTableName, updateColumnFilterValues, clearColumnFilterValues, setTableValueType} = resultsTablesSlice.actions

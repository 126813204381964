import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {makeNavigationTree} from "../utils/makeNavigationTree"
import {createBreadcrumbs} from "../utils/createBreadcrumbs"
import {projectlogAPI} from "../pages/ProjectLogs/api/projectlogAPI";

const initialState = {
    initialData: [], // данные о структуре справочников полученные с сервера
    treeData: [], // данные для treeSelect (древовидная структура справочников)
    breadcrumbsArray: [], // массив отображаемых хлебных крошек
    selectedProjectLog: {guid: "", name: ""}, // выбранный справочник
    isLoading: "idle", // идет ли запрос
}

// получет массив объектов для навигации
export const getProjectLogTreeData = createAsyncThunk("ProjectLogNavigation/getProjectLogTreeData", async (params, thunkAPI) => {
    try {
        const response = await projectlogAPI.getProjectLogNavigationArray()
        return response.data
    } catch (err) {
        return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
    }
})

// tree select Навигация на странице Журналов
const ProjectLogNavigationSlice = createSlice({
    name: "ProjectLogNavigation",
    initialState,
    reducers: {
        setSelectedProjectLog: (state, action) => {
            state.selectedProjectLog = action.payload
        },
        setProjectLogBreadcrumbs: (state, action) => {
            let newArray = []
            createBreadcrumbs(state.initialData, action.payload, newArray)
            state.breadcrumbsArray = newArray
        },
    },
    extraReducers: {
        [getProjectLogTreeData.pending]: (state) => {
            state.isLoading = "pending"
        },
        [getProjectLogTreeData.fulfilled]: (state, action) => {
            state.initialData = action.payload
            let treeData = makeNavigationTree(action.payload)
            state.treeData = treeData
            state.selectedProjectLog.guid = treeData[0].guid

            state.isLoading = "succeeded"
        },
        [getProjectLogTreeData.rejected]: (state) => {
            state.isLoading = "failed"
        },
    },
})
export const {setSelectedProjectLog, setProjectLogBreadcrumbs} = ProjectLogNavigationSlice.actions
export const ProjectLogNavigationReducer = ProjectLogNavigationSlice.reducer

export const getOtherHeadersLevelsToExportExcel = (tableHeader) => {
	const levels = [[]]

	const getHeadersToLevel = (tableHeader, index = 0, headerTitle = "") => {
		let length = 0

		for (let i = 0; i < tableHeader.length; i++) {
			if (!tableHeader[i].children) {
				length++
				if (!levels[index + 1]) {
					levels.push([])
				}
				levels[index + 1].push({
					title: "",
					cellLength: 1,
				})
			} else {
				length += getHeadersToLevel(tableHeader[i].children, index + 1, tableHeader[i].columnTitle || tableHeader[i].title)
			}
		}

		if (!levels[index]) {
			levels.push([])
		}
		levels[index].push({
			title: headerTitle,
			cellLength: length,
		})

		return length
	}

	getHeadersToLevel(tableHeader)

	levels.splice(0, 1)
	levels.splice(levels.length - 1, 1)

	return levels
}

import React, {useEffect, useState, useMemo} from "react"
import PropTypes from "prop-types"
import {Typography, Button, Space} from "antd"
import {getFragmentsTable} from "../../../../redux/projectManagementModule/fragmentsTableSlice"
import {useDispatch, useSelector} from "react-redux"
import {PlusOutlined} from "@ant-design/icons"
import {ExportExcel} from "../../../../components/ExportExcel"
import {BaseTable} from "../../../../components/BaseTable/baseTable"
import {Spinner} from "../../../../components/Spinner/spinner"
import {LinkButton} from "../../../../components/Buttons/linkButton"
import {ModalEditor} from "../../../../components/ModalEditor/ModalEditor"
import {defaultColumnParams} from "../../../../components/BaseTable/defaultColumnParams"
import {LOADING_STATE_PENDING} from "../../../../utils/loadingState"
import {StyledTabs} from "../../../../components/StyledTabs"

const fragmentsTabs = [
	{
		label: "Информация о элементе",
		key: "generalInfo",
	},
	{
		label: "Технические параметры",
		key: "technical",
	},
	{
		label: "Эксплуатационные параметры",
		key: "operational",
	},
]

// таблица
export const FragmentsTable = ({tableName, tableTitle, fragmentGuid}) => {
	const dispatch = useDispatch()

	//колонки таблицы
	const tableColumns = useSelector((state) => state.projectFragmentsTables.tableColumns)
	//данные таблицы
	const tableData = useSelector((state) => state.projectFragmentsTables.tableData)
	// идет ли запрос на бэк за данными для таблицы
	const isLoading = useSelector((state) => state.projectFragmentsTables.isLoading)

	const columnFilterValues = useSelector((state) => state.projectFragmentsTables.columnFilterValues)

	const [selectedId, setSelectedId] = useState("")
	const [showModalEditor, setShowModalEditor] = useState(false)
	const [showFragmentModal, setFragmentModal] = useState(false)
	const [activeTab, setActiveTab] = useState("generalInfo")

	const loadData = () => {
		dispatch(getFragmentsTable({fragmentGuid, activeTab}))
	}

	const showEditFragment = () => {
		setSelectedId(fragmentGuid)
		setFragmentModal(true)
	}

	const showAddModal = () => {
		setShowModalEditor(true)
	}

	const showEditModal = (record) => {
		setSelectedId(record.guid)
		setShowModalEditor(true)
	}

	const handleSuccess = () => {
		setSelectedId("")
		setShowModalEditor(false)
		setFragmentModal(false)
		loadData()
	}

	const handleCancel = () => {
		setSelectedId("")
		setFragmentModal(false)
		setShowModalEditor(false)
	}

	const linkCellRenderer = (text, record) => {
		return <LinkButton text={text} handleClick={() => showEditModal(record)} />
	}

	const editCellRenderer = (text, record) => {
		return (
			<LinkButton
				text={text}
				handleClick={() => {
					showEditModal(record)
				}}
			/>
		)
	}

	const mappedColumns = useMemo(() => {
		//  сортировщик (для чисел и строк)
		const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || "").localeCompare(b || "") : a - b)

		return tableColumns.map((x) => {
			const column = {
				...x,
				...defaultColumnParams,
				columnTitle: x.title,
				sorter: (a, b) => sorter(a[x.dataIndex], b[x.dataIndex]),
			}

			if (x.dataIndex === "equipmentAttribute") {
				return {...column, render: linkCellRenderer}
			}
			if (x.dataIndex === "recommendation" || x.dataIndex === "causeOfDamage" || x.dataIndex === "defect") {
				return {...column, render: editCellRenderer}
			}
			return column
		})
	}, [tableColumns])

	const handleTabChange = (newValue) => {
		setActiveTab(newValue)
		// navigate(`./${newValue}`)
		// dispatch(setActiveSubTableName(fragmentsTabs.find((fT) => fT.key === newValue).label))
	}

	//запрос данных
	useEffect(() => {
		if (activeTab !== "") loadData()
	}, [fragmentGuid, activeTab])

	if (isLoading === LOADING_STATE_PENDING) {
		return <Spinner />
	}

	return (
		<>
			<Space direction="vertical" size="small" style={{width: "100%"}}>
				<div style={{display: "flex", gap: "12px"}}>
					<Typography.Title level={3}>{tableTitle}</Typography.Title>
				</div>
				<StyledTabs type="card" items={fragmentsTabs} activeKey={activeTab} onChange={handleTabChange} destroyInactiveTabPane />
				{activeTab === "generalInfo" ? (
					<>
						<Space>
							<Button
								onClick={showEditFragment}
								icon={<PlusOutlined />}
								type="primary"
								className="mainColor addButton">{`Редактирование [${tableTitle}]`}</Button>
						</Space>
					</>
				) : (
					<>
						<Space>
							<Button
								onClick={showAddModal}
								icon={<PlusOutlined />}
								type="primary"
								className="mainColor addButton">{`Создание параметра для [${tableTitle}]`}</Button>
							<ExportExcel dataToExport={tableData} tableHeader={tableColumns} fileName={tableName} />
						</Space>
					</>
				)}
				<BaseTable
					data={tableData}
					columns={mappedColumns}
					isLoading={isLoading === LOADING_STATE_PENDING}
					pageContentHeight={380}
					filteredValues={columnFilterValues}
					disablePagination={true}
				/>
			</Space>

			{showModalEditor && (
				<ModalEditor
					entityName={activeTab}
					isProjects={true}
					entityId={selectedId}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					link={`${activeTab}/form/${fragmentGuid}`}
				/>
			)}

			{showFragmentModal && (
				<ModalEditor
					entityName={"fragments"}
					isProjects={true}
					entityId={selectedId}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					useLink={true}
				/>
			)}
		</>
	)
}

FragmentsTable.propTypes = {
	tableName: PropTypes.string, // название Таблицы
	tableTitle: PropTypes.string, // подпись Таблицы
	fragmentGuid: PropTypes.string,
}

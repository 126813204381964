import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {setIsLoggedIn, setUser} from "./authSlice"
import {authApi} from "../pages/Login/api/authAPI"

const initialState = {
	isInitialized: false, // загрузились ли необходимые данные для старта приложения
}

// инициализация приложения. проверяет токен, загружает информацию о пользователе
export const setIsInitialized = createAsyncThunk("app/setIsInitialized", async (_, thunkAPI) => {
	if (!localStorage.getItem("aees_token")) {
		return thunkAPI.fulfillWithValue("")
	}
	try {
		const response = await authApi.me()
		await thunkAPI.dispatch(setUser(response.data))
		await thunkAPI.dispatch(setIsLoggedIn(true))
		return response.data
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// инициализация приложения
const appSlice = createSlice({
	name: "app",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(setIsInitialized.fulfilled, (state) => {
				state.isInitialized = true
			})
			.addCase(setIsInitialized.rejected, (state) => {
				state.isInitialized = true
			})
	},
})

export const appReducer = appSlice.reducer

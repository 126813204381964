import {Row} from "antd"
import React, {useEffect} from "react"
import {Outlet} from "react-router-dom"
import {useDispatch} from "react-redux"
import {WorksNavigation} from "./WorksNavigation"
import {setProjectCardActiveTab} from "../../../../redux/projectManagementModule/projectCardSlice"

// лейаут для страницы Карточка проекта -> Работы
export const WorksContainer = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setProjectCardActiveTab("works"))
	}, [])

	return (
		<Row wrap={false}>
			<WorksNavigation />
			<Outlet />
		</Row>
	)
}

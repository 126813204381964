import React from "react"
import {Button} from "antd"
import {MenuFoldOutlined, MenuUnfoldOutlined} from "@ant-design/icons"
import {useDispatch, useSelector} from "react-redux"
import {setIsNavigationCollapsed} from "../../redux/layoutSlice"

export const NavigationButton = () => {
	const dispatch = useDispatch()
	//видно ли навигационное дерево
	const isNavigationCollapsed = useSelector((state) => state.layout.isNavigationCollapsed)
	return (
		<Button onClick={() => dispatch(setIsNavigationCollapsed())} type="primary">
			{isNavigationCollapsed === "closed" ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
		</Button>
	)
}

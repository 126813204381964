import React, {useEffect, useState} from "react"
import {Form, Input, Select, DatePicker, Radio, Button, Upload, Checkbox, List, Divider} from "antd"
import {InputWithTooltip} from "../InputWithTooltip/InputWithTooltip"
import {mapValidationRules} from "../../utils/validation"
import PropTypes from "prop-types"
import styled from "styled-components"
import dayjs from "dayjs"
import "dayjs/locale/ru"
import locale from "antd/es/date-picker/locale/ru_RU"
import {PlusOutlined, UploadOutlined} from "@ant-design/icons"
import {url} from "../../api/url"
import TextArea from "antd/es/input/TextArea"
import {useDispatch, useSelector} from "react-redux"
import {
	postUserNewRole,
	deleteUserRole,
	getFunctionalRoles,
	getSectors,
	postUserNewSector,
	deleteUserSector,
} from "../../redux/referencesTablesSlice.js"
import {Spinner} from "../Spinner/spinner.js"
import {referencesAPI} from "../../pages/References/api/referencesAPI.js"
import {getOptions} from "../../redux/modalEditorSlice.js"

// элемент формы
export const FormItem = ({field, options = [], hidden}) => {
	const dispatch = useDispatch()
	const rules = mapValidationRules(field.validationRules)

	const [newListElement, setNewListElement] = useState("")

	async function addNewListElement() {
		try {
			let link = field.addingLink
			if (link === undefined) {
				link = field.link
			}
			const response = await referencesAPI.postFormSelect(link, {
				name: newListElement,
			})
			dispatch(getOptions([field]))
		} catch (e) {}
	}

	let itemControl
	let getValueProps

	if (field.cellType === "select") {
		itemControl = (
			<Select
				showSearch
				notFoundContent="Нет данных"
				disabled={field.disabled}
				filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
				options={
					Array.isArray(options) &&
					options.length > 0 &&
					options.map((option) => ({
						value: option?.guid,
						label: option?.name,
					}))
				}
			/>
		)
	} else if (field.cellType === "multiple") {
		itemControl = (
			<Select
				showSearch
				mode="multiple"
				allowClear
				notFoundContent="Нет данных"
				disabled={field.disabled}
				filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
				options={
					Array.isArray(options) &&
					options.length > 0 &&
					options.map((option) => ({
						value: option.guid,
						label: option.name,
					}))
				}
			/>
		)
	} else if (field.cellType === "addingSelect") {
		itemControl = (
			<Select
				showSearch
				notFoundContent="Нет данных"
				disabled={field.disabled}
				filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
				dropdownStyle={{zIndex: "5000", position: "fixed"}}
				options={
					Array.isArray(options) &&
					options.length > 0 &&
					options.map((option) => ({
						value: option.guid,
						label: option.name,
					}))
				}
				dropdownRender={(menu) => (
					<div>
						{menu}
						<Divider style={{margin: "4px 0"}} />
						<div
							style={{
								display: "flex",
								flexWrap: "nowrap",
								padding: 8,
							}}>
							<Input
								style={{flex: "auto"}}
								placeholder="Введите..."
								value={newListElement}
								onChange={(e) => setNewListElement(e.target.value)}
								className="MainText"
							/>
							<a
								style={{
									flex: "none",
									padding: "8px",
									display: "block",
									cursor: "pointer",
								}}
								onClick={(event) => {
									event.stopPropagation()
									addNewListElement()
								}}>
								<PlusOutlined />
							</a>
						</div>
					</div>
				)}></Select>
		)
	} else if (field.cellType === "custom_bool") {
		itemControl = (
			<Radio.Group disabled={field.disabled} buttonStyle="solid">
				<Radio.Button value={true}>Относительное</Radio.Button>
				<Radio.Button value={false}>Абсолютное</Radio.Button>
			</Radio.Group>
		)
	} else if (field.cellType === "bool_yes_no_none") {
		itemControl = (
			<Radio.Group disabled={field.disabled} buttonStyle="solid">
				<StyledRadioButtonGreen value={true}>Да</StyledRadioButtonGreen>
				<StyledRadioButtonRed value={false}>Нет</StyledRadioButtonRed>
				<Radio.Button value="">Не выбрано</Radio.Button>
			</Radio.Group>
		)
	} else if (field.cellType === "file") {
		itemControl = (
			<Upload
				disabled={field.disabled}
				action={`${url}${field.value}`}
				name={field.dataIndex}
				headers={{
					authorization: `Bearer ${localStorage.getItem("aees_token")}`,
				}}
				maxCount={1}>
				<Button icon={<UploadOutlined />}>Загрузить файл</Button>
			</Upload>
		)
	} else if (field.cellType === "date") {
		getValueProps = (i) => ({value: i ? dayjs(i) : null})

		itemControl = <DatePicker disabled={field.disabled} placeholder="Выберите дату" format="YYYY/MM/DD" locale={locale} />
	} else if (field.cellType === "bool_required") {
		itemControl = (
			<Radio.Group disabled={field.disabled} buttonStyle="solid">
				<StyledRadioButtonGreen value={true}>Да</StyledRadioButtonGreen>
				<StyledRadioButtonRed value={false}>Нет</StyledRadioButtonRed>
			</Radio.Group>
		)
	} else if (field.cellType === "bool_not_applicable") {
		itemControl = (
			<Radio.Group disabled={field.disabled} buttonStyle="solid">
				<StyledRadioButtonGreen value={true}>Да</StyledRadioButtonGreen>
				<StyledRadioButtonRed value={false}>Нет</StyledRadioButtonRed>
				<Radio.Button value="">Не применимо</Radio.Button>
			</Radio.Group>
		)
	} else if (field.cellType === "textarea") {
		itemControl = <TextArea disabled={field.disabled} placeholder={field.placeholder} />
	} else {
		itemControl = field.tooltip ? (
			<InputWithTooltip disabled={field.disabled} field={field} />
		) : (
			<Input disabled={field.disabled} placeholder={field.placeholder} />
		)
	}

	return (
		<StyledItem
			labelCol={{xs: {span: 20}, style: {padding: 0, width: "90%", margin: "0 auto"}}}
			wrapperCol={{xs: {span: 20}, style: {margin: "0 auto", width: "90%"}}}
			style={{alignSelf: "end"}}
			className={"formItem"}
			name={field.dataIndex}
			label={field.title}
			rules={rules}
			hidden={!!hidden}
			getValueProps={getValueProps}>
			{itemControl}
		</StyledItem>
	)
}

export const RolesCheckboxContent = ({entityId}) => {
	const dispatch = useDispatch()

	const functionalRoles = useSelector((state) => state.referencesTables.functionalRoles)
	const checkedListRoles = useSelector((state) => state.referencesTables.checkedListRoles)
	const isLoading = useSelector((state) => state.referencesTables.isLoadingFunctionalRoles)

	useEffect(() => {
		dispatch(getFunctionalRoles(entityId))
	}, [entityId])

	return (
		<>
			{isLoading === "pending" ? (
				<Spinner />
			) : (
				<List
					dataSource={functionalRoles}
					renderItem={(item) => (
						<List.Item>
							<Checkbox
								onChange={(e) => {
									if (e.target.checked) {
										if (item.guid && entityId) dispatch(postUserNewRole({userGuid: entityId, roleGuid: item.guid}))
									} else {
										if (item.guid && entityId) dispatch(deleteUserRole({userGuid: entityId, roleGuid: item.guid}))
									}
								}}
								checked={checkedListRoles && checkedListRoles.includes(item.guid)}>
								{item.name} {item.description && `/ ${item.description}`}
							</Checkbox>
						</List.Item>
					)}
				/>
			)}
		</>
	)
}

export const SectorsCheckboxContent = ({entityId}) => {
	const dispatch = useDispatch()

	const sectors = useSelector((state) => state.referencesTables.sectors)
	const checkedListSectors = useSelector((state) => state.referencesTables.checkedListSectors)
	const isLoading = useSelector((state) => state.referencesTables.isLoadingSectors)

	useEffect(() => {
		dispatch(getSectors(entityId))
	}, [entityId])

	return (
		<>
			{isLoading === "pending" ? (
				<Spinner />
			) : (
				<List
					dataSource={sectors}
					renderItem={(item) => (
						<List.Item>
							<Checkbox
								onChange={(e) => {
									if (e.target.checked) {
										if (item.guid && entityId) dispatch(postUserNewSector({userGuid: entityId, sectorGuid: item.guid}))
									} else {
										if (item.guid && entityId) dispatch(deleteUserSector({userGuid: entityId, sectorGuid: item.guid}))
									}
								}}
								checked={checkedListSectors && checkedListSectors.includes(item.guid)}>
								{item.name} {item.description && `/ ${item.description}`}
							</Checkbox>
						</List.Item>
					)}
				/>
			)}
		</>
	)
}

FormItem.propTypes = {
	field: PropTypes.object,
	options: PropTypes.array,
	hidden: PropTypes.bool,
}

RolesCheckboxContent.propTypes = {
	entityId: PropTypes.string,
}

const StyledRadioButtonRed = styled(Radio.Button)`
	.ant-radio-button-checked {
		background: red;
		border-color: red;
	}
`

const StyledRadioButtonGreen = styled(Radio.Button)`
	.ant-radio-button-checked {
		background: #019b01;
		border-color: #019b01;
	}
`

const StyledItem = styled(Form.Item)`
	margin-top: 5px;
	margin-bottom: 0;
`

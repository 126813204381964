import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NavigationTree} from "../../../../components/NavigationTree/NavigationTree"
import {useNavigate} from "react-router-dom"
import {getConclusionTreeData, setSelectedConclusion} from "../../../../redux/projectManagementModule/conclusionsNavigationSlice"

// Боковая часть с навигацией на странице Заключения
export const ConclusionsNavigation = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const isLoading = useSelector((state) => state.projectConclusionNavigation.isLoading)
	const treeData = useSelector((state) => state.projectConclusionNavigation.treeData)
	const selectedConclusionGuid = useSelector((state) => state.projectConclusionNavigation.selectedConclusion.guid)

	// выбирает раздел, перерисовывает хлебные крошки
	const onChange = (newValue, selectedConclusion) => {
		dispatch(setSelectedConclusion({guid: selectedConclusion.guid, name: selectedConclusion.name}))
		navigate(`${selectedConclusion.conclusionName}`)
	}

	useEffect(() => {
		dispatch(getConclusionTreeData())
	}, [])

	return <NavigationTree treeData={treeData} value={selectedConclusionGuid} onChange={onChange} isLoading={isLoading} />
}

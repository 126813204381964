import React, {useEffect, useState} from "react"
import {validateMessages} from "../../../../utils/validation.js"
import {useDispatch, useSelector} from "react-redux"
import PropTypes from "prop-types"
import {Button, Modal, Form} from "antd"
import styled from "styled-components"
import {FormItem} from "../../../../components/ModalEditor/FormItem.js"
import {
	addItem,
	editItem,
	getFormInfo,
	getOptions,
	openNewModal,
	closeModal,
	modalDataInitialState,
	getFormTabsInfo,
	updateSelectedOption,
} from "../../../../redux/modalEditorSlice"
import {Spinner} from "../../../../components/Spinner/spinner.js"
import {v4 as uuidv4} from "uuid"
import {referencesAPI} from "../../../References/api/referencesAPI.js"
import DisableBodyScroll from "../../../../components/DisableBodyScroll.js"

const mapFormInfoToAntdForm = (formInfo) => {
	const data = {}
	formInfo.forEach((item) => {
		data[item.dataIndex] = item.value
	})

	return data
}

// всплывающее окно создания/редактирования строк в таблице
export const TakeAJobModalWindow = ({
	entityName,
	entityTitle,
	isReference,
	isProjectLog, //
	isDigitalProfile,
	isProjects,
	isUser,
	entityId,
	onSuccess,
	onCancel,
	afterGetFormInfoFunc,
	cellData,
	getCustomFooter,
	link,
	useLink = false,
	isIntegration,
}) => {
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	//ширина модального окна
	const [modalWidth, setModalWidth] = useState("600px")
	const [modalId] = useState(uuidv4())

	const isLocked = useSelector((state) => state.modalEditor.isLocked)
	const modalDataList = useSelector((state) => state.modalEditor.modalDataList)
	const isLoadingIdle = useSelector((state) => state.modalEditor.isLoadingIdle)

	const currentModalData = modalDataList.find((x) => x.modalId === modalId)

	const {formInfo, tabs, prefix, selectedTab, selectListOptions, isLoading} = currentModalData || modalDataInitialState

	useEffect(() => {
		dispatch(openNewModal(modalId))
	}, [])

	useEffect(() => {
		//получаем поля для формы
		dispatch(
			getFormInfo({
				entityName,
				isReference,
				isProjectLog,
				isUser,
				isProjects,
				isDigitalProfile,
				entityId,
				afterGetFormInfoFunc,
				cellData,
				modalId,
				link,
				useLink,
			})
		) //
		dispatch(getFormTabsInfo({modalId}))
	}, [entityName, isReference, isProjectLog, isDigitalProfile, isProjects, entityId, isUser, link]) //

	useEffect(() => {
		//получаем значения для выпадающих списков в форме
		const selectFields = formInfo.filter(
			(item) => item.cellType === "select" || item.cellType === "multiple" || item.cellType === "addingSelect"
		)
		if (selectFields.length > 0) {
			dispatch(getOptions(selectFields))
		}

		//устанавливаем в форму значения редактируемой сущности
		if (formInfo) {
			form.setFieldsValue(
				mapFormInfoToAntdForm(
					formInfo?.map((item) =>
						item.cellType === "select" &&
						(item.dataIndex === "navigationGuid" || item.dataIndex === "structuralSubdivision" || item.dataIndex === "subdivisionGuid") &&
						(item.value === undefined || item.value === null)
							? {...item, value: window.location.pathname.split("/")[3]}
							: item
					)
				)
			)
		}
	}, [formInfo])

	// Костыль
	const onValuesChange = (changedValues, allValues) => {
		if (entityName === "works" && changedValues.fragmentFK !== undefined) {
			const elementField = formInfo.find((item) => item.dataIndex === "elementFK")
			getElements(changedValues.fragmentFK, elementField.link)
			form.setFieldValue("elementFK", undefined)
		}
	}

	async function getElements(fragmentGuid, link) {
		try {
			const response = await referencesAPI.getReferenceOptions(`${link}?fragmentGuid=${fragmentGuid}`)
			dispatch(updateSelectedOption({dataIndex: "elementFK", newOptions: response}))
		} catch (e) {}
	}

	const handleCancel = () => {
		dispatch(closeModal())
		onCancel()
	}

	const handleAddEdit = async (newValues) => {
		const values = {...newValues}
		if (entityId) {
			values["guid"] = entityId
		}

		const func = entityId ? editItem : addItem
		const response = await dispatch(
			func({entityName, entityId, values, prefix, isUser, isDigitalProfile, isProjects, isProjectLog, isReference, cellData, link})
		)

		if (!response.error) {
			onSuccess(response.payload)
			dispatch(closeModal())
		}
	}

	const Header = () => {
		return <StyledHeader>Выбор исполнителя</StyledHeader>
	}

	const Footer = () => {
		return (
			<>
				<Button disabled={selectedTab === "thermotechnicalCalculation"} onClick={handleCancel}>
					Отмена
				</Button>
				{!isLocked && selectedTab !== "perimeter" && selectedTab !== "roles" && (
					<Button
						type="primary"
						loading={isLoadingIdle === "pending"}
						disabled={isLoading || selectedTab === "thermotechnicalCalculation" || isLoadingIdle === "pending"}
						onClick={() => form.submit()}>
						{entityId || isIntegration ? "Сохранить" : entityName === "Reports" ? "Сформировать отчет" : "Создать"}
					</Button>
				)}
			</>
		)
	}

	const tabItems = tabs
		? tabs.map((tab) => {
				return {
					label: <>{tab.title}</>,
					key: tab.index,
					children: <></>,
				}
		  })
		: null

	const showTabs = tabItems && tabItems.length > 0
	const fieldsToDisplay = showTabs ? formInfo.filter((x) => x.formTabIndex === selectedTab) : formInfo

	const FormControl = fieldsToDisplay.length > 5 ? TwoColumnsForm : Form

	return (
		<Modal
			title={<Header />}
			open={true}
			onCancel={handleCancel}
			width={modalWidth}
			centered
			getContainer={false}
			footer={getCustomFooter ? getCustomFooter(form, isLoading) : <Footer />}>
			<DisableBodyScroll />
			{isLoading ? (
				<Spinner />
			) : (
				<>
					<FormControl
						layout={"vertical"}
						labelCol={{xs: {span: 8}}}
						wrapperCol={{xs: {span: 15}}}
						form={form}
						onFinish={handleAddEdit}
						validateMessages={validateMessages}
						onValuesChange={onValuesChange}
						onKeyPress={(e) => {
							if (e.key === "Enter") {
								form.submit()
							}
						}}>
						{formInfo.map((field, i) => {
							const options = selectListOptions?.find((x) => x.dataIndex === field.dataIndex)?.options || []
							const hidden = field.dataIndex !== "executorFK" || field.hidden

							return <FormItem key={i} field={field} options={options} hidden={hidden} />
						})}
					</FormControl>
					<div style={{marginTop: "12px"}}>
						При нажатии кнопки "Сохранить" в журнал регистрации проб будет добавлена запись о пригодности пробы (элемента).
					</div>
				</>
			)}
		</Modal>
	)
}

const StyledHeader = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: -8px;
`

const TwoColumnsForm = styled(Form)`
	display: flex;
	flex-wrap: wrap;
	.ant-form-item {
		width: 50%;
	}
`

TakeAJobModalWindow.propTypes = {
	entityName: PropTypes.string,
	entityTitle: PropTypes.string,
	isReference: PropTypes.bool,
	isProjects: PropTypes.bool,
	isProjectLog: PropTypes.bool, //
	isUser: PropTypes.bool,
	isDigitalProfile: PropTypes.bool,
	entityId: PropTypes.string,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
	afterGetFormInfoFunc: PropTypes.func,
	getCustomFooter: PropTypes.func,
	link: PropTypes.string,
	useLink: PropTypes.bool,
	isIntegration: PropTypes.bool,
}

import React, {useEffect, useState, useMemo} from "react"
import PropTypes from "prop-types"
import {Typography, Button, Space} from "antd"
import {getProjectsTable} from "../../redux/projectsTablesSlice"
import {useDispatch, useSelector} from "react-redux"
import {PlusOutlined} from "@ant-design/icons"
import {ExportExcel} from "../../components/ExportExcel"
import {LOADING_STATE_PENDING} from "../../utils/loadingState"
import {BaseTable} from "../../components/BaseTable/baseTable"
import {Spinner} from "../../components/Spinner/spinner"
import {ModalEditor} from "../../components/ModalEditor/ModalEditor"
import {defaultColumnParams} from "../../components/BaseTable/defaultColumnParams"
import {Link} from "react-router-dom"
import {setProjectCardActiveTab} from "../../redux/projectManagementModule/projectCardSlice"

// таблица
export const ProjectTable = ({tableName, tableTitle}) => {
	const dispatch = useDispatch()

	//колонки таблицы
	const tableColumns = useSelector((state) => state.projectsTable.tableColumns)
	//данные таблицы
	const tableData = useSelector((state) => state.projectsTable.tableData)
	// идет ли запрос на бэк за данными для таблицы
	const isLoading = useSelector((state) => state.projectsTable.isLoading)

	const columnFilterValues = useSelector((state) => state.projectsTable.columnFilterValues)

	const [selectedId, setSelectedId] = useState("")
	const [showModalEditor, setShowModalEditor] = useState(false)

	const loadData = () => {
		dispatch(getProjectsTable(tableName))
	}

	const showAddModal = () => {
		setShowModalEditor(true)
	}

	const handleSuccess = () => {
		setSelectedId("")
		setShowModalEditor(false)
		loadData()
	}

	const handleCancel = () => {
		setSelectedId("")
		setShowModalEditor(false)
	}

	const linkCellRenderer = (text, record) => {
		const pageToOpen = "basicInformation"
		return (
			<Link to={`/projects/${record.guid}/${pageToOpen}`} onClick={() => dispatch(setProjectCardActiveTab({pageToOpen}))}>
				{text}
			</Link>
		)
	}

	const mappedColumns = useMemo(() => {
		//  сортировщик (для чисел и строк)
		const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || "").localeCompare(b || "") : a - b)

		return tableColumns.map((x) => {
			const column = {
				...x,
				...defaultColumnParams,
				columnTitle: x.title,
				sorter: (a, b) => sorter(a[x.dataIndex], b[x.dataIndex]),
			}

			if (x.dataIndex === "name") {
				return {...column, render: linkCellRenderer}
			}

			return column
		})
	}, [tableColumns])

	//запрос данных
	useEffect(() => {
		loadData()
	}, [tableName])

	if (isLoading === LOADING_STATE_PENDING && tableData) {
		return <Spinner />
	} else if (isLoading === LOADING_STATE_PENDING && !tableData)
		{
			<h1>
			Нет данных
			</h1>
		// Здесь вы можете обработать данные и вернуть соответствующий компонент
	}
	return (
		<>
			<Space direction="vertical" size="small" style={{width: "100%"}}>
				<Typography.Title level={3}>{tableTitle}</Typography.Title>
				<Space>
					<Button
						onClick={showAddModal}
						icon={<PlusOutlined />}
						type="primary"
						className="mainColor addButton">{`Создание [${tableTitle}]`}</Button>
					<ExportExcel dataToExport={tableData} tableHeader={tableColumns} fileName={tableName} />
				</Space>
				<BaseTable
					data={tableData}
					columns={mappedColumns}
					isLoading={isLoading === LOADING_STATE_PENDING}
					pageContentHeight={250}
					filteredValues={columnFilterValues}
				/>
			</Space>

			{showModalEditor && (
				<ModalEditor entityName={tableName} isProjects={true} entityId={selectedId} onSuccess={handleSuccess} onCancel={handleCancel} />
			)}
		</>
	)
}

ProjectTable.propTypes = {
	tableName: PropTypes.string, // название Таблицы
	tableTitle: PropTypes.string, // подпись Таблицы
}

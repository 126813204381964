import styled from "styled-components"
import {Tabs} from "antd"

export const StyledTabs = styled(Tabs)`
	.info-heading {
		font-weight: 600;
		margin: 12px 0 6px;
	}
	.content-container {
		display: flex;
		flex-direction: column;
	}
	.text {
		margin: 0;
	}
  .ant-tabs-nav-more {display: none}
  
`

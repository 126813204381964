import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {projectsAPI} from "../pages/Project/api/projectsAPI"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../utils/loadingState"
import {setSnackbarNotification} from "./snackbarSlice"


const initialState = {
	tableData: [],
	objectTableName: "",
	tableColumns: [],
	functionalRoles: [], //список функциональных ролей

	checkedListRoles: [],

	isLoading: "idle", // идет ли запрос за данными для таблицы
	isLoadingFunctionalRoles: "idle",
	isLoadingPost: "idle",

	columnFilterValues: {},

	basicInformationData: [],
	basicInformationColumns: [],
	isBasicInformationLoading: LOADING_STATE_EMPTY, // идет ли запрос за данными для таблицы

	fragmentsAndElementsData: [],
	fragmentsAndElementsColumns: [],
	isFragmentsAndElementsLoading: LOADING_STATE_EMPTY, // идет ли запрос за данными для таблицы

	resultsData: [],
	resultsColumns: [],
	isResultsLoading: LOADING_STATE_EMPTY, // идет ли запрос за данными для таблицы

	reportData: [],
	reportColumns: [],
	isReportLoading: LOADING_STATE_EMPTY, // идет ли запрос за данными для таблицы

	conclusionData: [],
	conclusionColumns: [],
	isСonclusionLoading: LOADING_STATE_EMPTY, // идет ли запрос за данными для таблицы

	selectedBasicInformationId: null,
	selectedFragmentsAndElementId: null,
	selectedResultsId: null,
	selectedReportId: null,
	selectedWorksId: null,
	selectedConclusionId: null,
	activeTabId: "basicInformation",
}

//получет данные для отрисовки таблицы в справочнике
export const getProjectsTable = createAsyncThunk("projectsTables/getProjectsTable", async (tableName, thunkAPI) => {
	try {
		const response = await projectsAPI.getProjectsTableData(tableName)
		return {response: response.data, tableName}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

//список функциональных ролей
export const getFunctionalRoles = createAsyncThunk("ProjectLogTables/getFunctionalRoles", async (userGuid, thunkAPI) => {
	try {
		const response1 = await projectsAPI.getFunctionalRoles()
		const response2 = await projectsAPI.getSelectedUserRoles(userGuid)

		return {functionalRoles: response1.data, selectedUserRoles: response2.data}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// добавление пользователю роли
export const postUserNewRole = createAsyncThunk("ProjectsTables/postUserNewRole", async (parameters, thunkAPI) => {
	try {
		const {userGuid, roleGuid} = parameters
		const response = await projectsAPI.postUserNewRole(userGuid, roleGuid)

		if (response.status === 200) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Роль успешно добавлена", type: "success"}))

			return thunkAPI.fulfillWithValue({
				roleGuid: roleGuid,
			})
		}
	} catch (err) {
		thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка добавления роли. Повторите попытку", type: "error"}))
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})
export const deleteUserRole = createAsyncThunk("ProjectsTables/deleteUserRole", async (parameters, thunkAPI) => {
	try {
		const {userGuid, roleGuid} = parameters
		const response = await projectsAPI.deleteUserRole(userGuid, roleGuid)
		if (response.status === 200) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Роль пользователя удалена", type: "success"}))

			return thunkAPI.fulfillWithValue({
				roleGuid: roleGuid,
			})
		}
	} catch (err) {
		thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка удаления роли. Повторите попытку", type: "error"}))
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})
export const getBasicInformationId = createAsyncThunk(
	"src/pages/Project/projectsTable/getBasicInformationId",
	async (basicInformationId, thunkAPI) => {
		try {
			const response = await projectsAPI.getBasicInformationId(basicInformationId)
			return response.data
		} catch (err) {
			return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
		}
	})
export const getFragmentsAndElementsId = createAsyncThunk(
	"src/pages/Project/projectsTable/getFragmentsAndElementsId",
	async (fragmentsAndElementsId, thunkAPI) => {
		try {
			const response = await projectsAPI.getFragmentsAndElementsId(fragmentsAndElementsId)
			return response.data
		} catch (err) {
			return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
		}
	}
)
export const getWorksId = createAsyncThunk(
	"src/pages/Project/projectsTable/getWorksId",
	async (worksId, thunkAPI) => {
		try {
			const response = await projectsAPI.getWorksId(worksId)
			return response.data
		} catch (err) {
			return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
		}
	}
)
export const getResultsId = createAsyncThunk(
	"src/pages/Project/projectsTable/getResultsId",
	async (resultsId, thunkAPI) => {
		try {
			const response = await projectsAPI.getResultsId(resultsId)
			return response.data
		} catch (err) {
			return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
		}
	}
)
export const getReportId = createAsyncThunk(
	"src/pages/Project/projectsTable/getReportId",
	async (reportId, thunkAPI) => {
		try {
			const response = await projectsAPI.getReportId(reportId)
			return response.data
		} catch (err) {
			return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
		}
	}
)
export const getConclusionId = createAsyncThunk(
	"src/pages/Project/projectsTable/getBasicInformationId",
	async (conclusionId, thunkAPI) => {
		try {
			const response = await projectsAPI.getConclusionId(conclusionId)
			return response.data
		} catch (err) {
			return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
		}
	})
// работа с таблицей на странице Управление проектами
const projectsTablesSlice = createSlice({
	name: "ProjectsTables",
	initialState,
	reducers: {
		setObjectTableName: (state, action) => {
			state.objectTableName = action.payload
		},
		updateColumnFilterValues: (state, action) => {
			state.columnFilterValues = action.payload
		},
		clearColumnFilterValues: (state) => {
			state.columnFilterValues = null
		},
		setCheckedListRoles: (state, action) => {
			state.checkedListRoles = action.payload
		},
		setSelectedBasicInformationId: (state, action) => {
			state.selectedBasicInformationId = action.payload
		},
		setSelectedFragmentsAndElementsId: (state, action) => {
			state.selectedFragmentsAndElementsId = action.payload
		},
		setSelectedWorksId: (state, action) => {
			state.selectedWorksId = action.payload
		},
		setSelectedResultsId: (state, action) => {
			state.selectedResultsId = action.payload
		},
		setSelectedReportId: (state, action) => {
			state.selectedResultsId = action.payload
		},
		setSelectedConclusionId: (state, action) => {
			state.selectedConclusionId = action.payload
		},
		setProjectTablesActiveTab: (state, action) => {
			state.activeTabId = action.payload
		},
		setInitialProjectTablesTabValue: (state) => {
			state.activeTabId = "basicInformation"
		},
	},
	extraReducers: {
		[getProjectsTable.pending]: (state) => {
			state.isLoading = "pending"
		},
		[getProjectsTable.fulfilled]: (state, action) => {
			const {response} = action.payload
			state.tableData = response.data
			state.tableColumns = response.columns.map((i) => ({...i, ellipsis: true, width: 150}))
			state.isLoading = "succeeded"
		},
		[getProjectsTable.rejected]: (state) => {
			state.isLoading = "failed"
		},
		[getFunctionalRoles.pending]: (state) => {
			state.isLoadingFunctionalRoles = "pending"
		},
		[getFunctionalRoles.fulfilled]: (state, action) => {
			state.functionalRoles = action.payload?.functionalRoles ? action.payload?.functionalRoles : []
			state.checkedListRoles = action.payload?.selectedUserRoles ? action.payload.selectedUserRoles.map((item) => item.guid) : []

			state.isLoadingFunctionalRoles = "succeeded"
		},
		[getFunctionalRoles.rejected]: (state) => {
			state.isLoadingFunctionalRoles = "failed"
		},[postUserNewRole.pending]: (state) => {
			state.isLoadingPost = "pending"
		},
		[postUserNewRole.rejected]: (state) => {
			state.isLoadingPost = "failed"
		},
		[postUserNewRole.fulfilled]: (state, action) => {
			const {
				payload: {roleGuid},
			} = action

			state.checkedListRoles = [...state.checkedListRoles, roleGuid]

			state.isLoadingPost = "succeeded"
		},
		[deleteUserRole.pending]: (state) => {
			state.isLoadingPost = "pending"
		},
		[deleteUserRole.rejected]: (state) => {
			state.isLoadingPost = "failed"
		},
		[deleteUserRole.fulfilled]: (state, action) => {
			const {
				payload: {roleGuid},
			} = action

			state.checkedListRoles = state.checkedListRoles.filter((guid) => guid !== roleGuid)

			state.isLoadingPost = "succeeded"
		},
				[getBasicInformationId.pending]: (state) => {
			state.isBasicInformationLoading = LOADING_STATE_PENDING
		},
		[getBasicInformationId.fulfilled]: (state, action) => {
			state.isBasicInformationLoading = LOADING_STATE_SUCCEEDED
			state.basicInformationData = action.payload.data
			state.basicInformationColumns = action.payload.columns
		},
		[getBasicInformationId.rejected]: (state) => {
			state.isBasicInformationLoading = LOADING_STATE_FAILED
		},
		[getFragmentsAndElementsId.pending]: (state) => {
			state.isFragmentsAndElementsLoading = LOADING_STATE_PENDING
		},
		[getFragmentsAndElementsId.fulfilled]: (state, action) => {
			state.isFragmentsAndElementsLoading = LOADING_STATE_SUCCEEDED
			state.fragmentsAndElementsData = action.payload.data
			state.fragmentsAndElementsColumns = action.payload.columns
		},
		[getFragmentsAndElementsId.rejected]: (state) => {
			state.isFragmentsAndElementsLoading = LOADING_STATE_FAILED
		},
		[getWorksId.pending]: (state) => {
			state.isWorksLoading = LOADING_STATE_PENDING
		},
		[getWorksId.fulfilled]: (state, action) => {
			state.isWorksLoading = LOADING_STATE_SUCCEEDED
			state.worksData = action.payload.data
			state.worksColumns = action.payload.columns
		},
		[getWorksId.rejected]: (state) => {
			state.isWorksLoading = LOADING_STATE_FAILED
		},
		[getResultsId.pending]: (state) => {
			state.isResultsLoading = LOADING_STATE_PENDING
		},
		[getWorksId.fulfilled]: (state, action) => {
			state.isResultsLoading = LOADING_STATE_SUCCEEDED
			state.resultsData = action.payload.data
			state.resultsColumns = action.payload.columns
		},
		[getResultsId.rejected]: (state) => {
			state.isResultsLoading = LOADING_STATE_FAILED
		},
		[getReportId.pending]: (state) => {
			state.isReportLoading = LOADING_STATE_PENDING
		},
		[getReportId.fulfilled]: (state, action) => {
			state.isReportLoading = LOADING_STATE_SUCCEEDED
			state.reportData = action.payload.data
			state.reportColumns = action.payload.columns
		},
		[getReportId.rejected]: (state) => {
			state.isReportLoading = LOADING_STATE_FAILED
		},
		[getConclusionId.pending]: (state) => {
			state.isConclusionLoading = LOADING_STATE_PENDING
		},
		[getConclusionId.fulfilled]: (state, action) => {
			state.isConclusionLoading = LOADING_STATE_SUCCEEDED
			state.reportData = action.payload.data
			state.reportColumns = action.payload.columns
		},
		[getConclusionId.rejected]: (state) => {
			state.isReportLoading = LOADING_STATE_FAILED
		},

	},
})
export const projectsTablesReducer =projectsTablesSlice.reducer
export const {setSelectedBasicInformationId,setSelectedFragmentsAndElementsId,setSelectedWorksId,setSelectedResultsId,setSelectedReportId,setSelectedConclusionId,setObjectTableName, updateColumnFilterValues, clearColumnFilterValues, setProjectTablesActiveTab} =
	projectsTablesSlice.actions

import React, {useRef} from "react"
import {Button, Input, Space, InputNumber, Select, DatePicker} from "antd"
import {SearchOutlined} from "@ant-design/icons"
import dayjs from "dayjs"
import PropTypes from "prop-types"

export const ColumnFilter = ({setSelectedKeys, selectedKeys, confirm, clearFilters, close, columnProps}) => {
	const searchInput = useRef(null)

	const placeholder = columnProps?.columnTitle ? `Поиск: ${columnProps.columnTitle}` : "Поиск"

	const handleSearch = (confirm) => {
		confirm()
	}

	const handleSearchChange = (e, setSelectedKeys, clearFilters) => {
		if (e.target.value === "") {
			clearFilters && clearFilters()
		}

		setSelectedKeys(e.target.value ? [e.target.value] : [])
	}

	const handleSelectChange = (value, option, confirm) => {
		setSelectedKeys(columnProps.mode !== undefined ? [value] : value)
		confirm()
	}

	const handleDateChange = (value) => {
		setSelectedKeys([value.toJSON()])
	}

	const handleClearFilters = (clearFilters, confirm, close) => {
		clearFilters()
		confirm()
		close()
	}

	const handleCancel = (close) => {
		close()
	}

	return (
		<div style={{padding: 8, display: "flex", flexDirection: "column"}} onKeyDown={(e) => e.stopPropagation()}>
			{columnProps.filterType === "text" && (
				<Input
					ref={searchInput}
					placeholder={placeholder}
					value={selectedKeys[0]}
					onChange={(e) => handleSearchChange(e, setSelectedKeys, clearFilters, selectedKeys)}
					onPressEnter={() => handleSearch(confirm)}
					style={{
						marginBottom: 8,
						display: "block",
					}}
				/>
			)}
			{columnProps.filterType === "number" && (
				<InputNumber
					placeholder={placeholder}
					value={selectedKeys[0]}
					onChange={(e) => handleSearchChange(e, setSelectedKeys, clearFilters, selectedKeys)}
					onPressEnter={() => handleSearch(confirm)}
					style={{
						marginBottom: 8,
						display: "block",
					}}
				/>
			)}
			{columnProps.filterType === "select" && (
				<Select
					showSearch
					mode={columnProps.mode ?? "multiple"}
					notFoundContent="Нет данных"
					filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
					options={columnProps.options.map((option) => ({
						value: option.guid,
						label: option.name,
					}))}
					value={selectedKeys}
					onChange={(value, option) => handleSelectChange(value, option, confirm)}
					style={{
						marginBottom: 8,
						display: "block",
					}}
				/>
			)}
			{columnProps.filterType === "date" && (
				<>
					<DatePicker
						value={selectedKeys[0] ? dayjs(selectedKeys[0]) : ""}
						onChange={(value) => handleDateChange(value)}
						style={{
							marginBottom: 8,
							display: "block",
						}}
					/>
				</>
			)}

			<Space>
				<Button
					type="primary"
					onClick={() => handleSearch(confirm, columnProps.dataIndex, selectedKeys)}
					icon={<SearchOutlined />}
					size="small"
					style={{width: 90}}>
					Поиск
				</Button>
				<Button onClick={() => handleClearFilters(clearFilters, confirm, close)} size="small">
					Сбросить фильтр
				</Button>
				<Button onClick={() => handleCancel(close)} size="small" type="link" style={{width: 90}}>
					Отмена
				</Button>
			</Space>
		</div>
	)
}

ColumnFilter.propTypes = {
	setSelectedKeys: PropTypes.func,
	selectedKeys: PropTypes.array,
	confirm: PropTypes.func,
	clearFilters: PropTypes.func,
	close: PropTypes.func,
	columnProps: PropTypes.object,
}

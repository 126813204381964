import React, {useEffect} from "react"
import {Link, Navigate, Outlet, useNavigate} from "react-router-dom"
import {cardProjectTabsConfig} from "./CardProjectTabsConfig"
import {StyledTabs} from "../../components/StyledTabs"
import {useDispatch, useSelector} from "react-redux"
import {useParams} from "react-router-dom"
import {getProjectInfo, setProjectCardActiveTab, setProjectCardGuid} from "../../redux/projectManagementModule/projectCardSlice"

// лейаут для страницы Проектов
export const CardProjectContainer = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const params = useParams()

	const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
	const activeTabId = useSelector((state) => state.projectCard.activeTabId)
	const projectName = useSelector((state) => state.projectCard.name)

	const tabs = cardProjectTabsConfig.map((x) => ({
		label: x.name,
		key: x.id,
		// children: React.createElement(x.component),
	}))

	const handleTabChange = (newValue) => {
		navigate(`./${newValue}`)
		dispatch(setProjectCardActiveTab(newValue))
	}

	useEffect(() => {
		if (params.guid) {
			dispatch(setProjectCardGuid(params.guid))
			dispatch(getProjectInfo(params.guid))
		}
	}, [params.guid])

	if (!isLoggedIn) {
		return <Navigate to="/login" replace={true} />
	}

	return (
		<div style={{height: "calc(100vh - 40px - 24px)", width: "calc(100vw - 24px)", backgroundColor: "#fff", margin: "12px"}} id="cardProject">
			<Link to="/projects">
				<h2>{projectName}</h2>
			</Link>
			<StyledTabs type="card" items={tabs} activeKey={activeTabId} onChange={handleTabChange} destroyInactiveTabPane />
			<Outlet />
		</div>
	)
}

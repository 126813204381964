import React from "react"
import PropTypes from "prop-types"
import {Modal} from "antd"
import DisableBodyScroll from "../DisableBodyScroll"

export const ConfirmationModal = ({title, message, isModalOpen, okClick, cancelClick, okText = "Да", cancelText = "Отмена"}) => {
	const handleOk = () => {
		okClick && okClick()
	}

	const handleCancel = () => {
		cancelClick && cancelClick()
	}

	return (
		<Modal
			title={title}
			open={isModalOpen}
			onOk={handleOk}
			onCancel={handleCancel}
			centered
			cancelText={cancelText}
			okText={okText}
			okType="danger">
			<DisableBodyScroll />
			{message}
		</Modal>
	)
}

ConfirmationModal.propTypes = {
	title: PropTypes.string,
	message: PropTypes.string,
	isModalOpen: PropTypes.bool,
	okClick: PropTypes.func,
	cancelClick: PropTypes.func,
	okText: PropTypes.string,
	cancelText: PropTypes.string,
}

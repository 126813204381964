import React, {useState, useEffect, useRef, useContext} from "react"
import {Form, InputNumber, Input} from "antd"
import {BaseTableEditableContext} from "./baseTable"
import styled from "styled-components"

const EditableCell = ({editable, dataIndex, title, inputType, record, index, onFieldChange, children, ...restProps}) => {
	const form = useContext(BaseTableEditableContext)
	const [editing, setEditing] = useState(false)
	const inputRef = useRef(null)

	const toggleEdit = () => {
		setEditing(!editing)
		form.setFieldsValue({[dataIndex]: record[dataIndex]})
	}

	const handleChangeInputValue = () => {
		const values = form.getFieldValue(dataIndex)
		onFieldChange(record, dataIndex, values)
	}

	const handleOnKeyPress = (event) => {
		if (event && event.key === "Enter") {
			handleChangeInputValue()
			toggleEdit()
		}
	}

	const handleOnBlur = () => {
		handleChangeInputValue()
		toggleEdit()
	}

	const getFormControl = () => {
		switch (inputType) {
			case "number":
				return <InputNumber ref={inputRef} onKeyUp={handleOnKeyPress} onBlur={handleOnBlur} style={{width: "100%"}} />
			case "text":
			default:
				return <Input ref={inputRef} onKeyUp={handleOnKeyPress} onBlur={handleOnBlur} />
		}
	}

	useEffect(() => {
		if (editing && inputRef && inputRef.current) {
			inputRef.current.focus()
			inputRef.current.select()
		}
	}, [editing])

	if (!editable) {
		return <td {...restProps}>{children}</td>
	}

	return (
		<td {...restProps}>
			{editing ? (
				<Form.Item name={dataIndex} style={{margin: 0}}>
					{getFormControl()}
				</Form.Item>
			) : (
				<div className="editable-cell-value-wrap" onClick={toggleEdit}>
					{children}
				</div>
			)}
		</td>
	)
}

export const BaseTableEditableCell = styled(EditableCell)`
	.editable-cell-value-wrap {
		height: 32px;
		cursor: pointer;
		padding-left: 12px;
		display: flex;
		align-items: center;
		border: 1px solid #f1f1f1;
		border-radius: 4px;

		&:hover {
			border-color: #ccc;
		}
	}
`

import {Col, Space} from "antd"
import React from "react"
import {useParams} from "react-router-dom"
import {CardProjectTable} from "./CardProjectTable";

// Страница проектов
export const CardProject = () => {
    const params = useParams()

    return (
        <Col flex="auto" style={{height: "calc(100vh - 40px - 24px)", backgroundColor: "#fff", margin: "12px"}}>
            <CardProjectTable  />
        </Col>
    )
}
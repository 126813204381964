import {Button, Col, Row, Space, Typography} from "antd"
import React, {useEffect, useMemo, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {BaseTable} from "../../../components/BaseTable/baseTable"
import {LOADING_STATE_PENDING} from "../../../utils/loadingState"
import {Spinner} from "../../../components/Spinner/spinner"
import {defaultColumnParams} from "../../../components/BaseTable/defaultColumnParams"
import {ArrowRightOutlined} from "@ant-design/icons"
import {getProjectBasedInfo} from "../../../redux/projectManagementModule/basicInformationSlice"
import {ModalEditor} from "../../../components/ModalEditor/ModalEditor"
import {setProjectCardActiveTab} from "../../../redux/projectManagementModule/projectCardSlice"
import {useParams} from "react-router-dom"

export const BasicInformation = () => {
	const dispatch = useDispatch()
	const params = useParams()

	const data = useSelector((state) => state.projectBasicInformation.projectBasicInformationData)
	const isLoading = useSelector((state) => state.projectBasicInformation.isLoading)
	const projectGuid = useSelector((state) => state.projectCard.guid)
	const [modalActive, setModalActive] = useState(false)

	const columns = [
		{
			title: "Наименование",
			dataIndex: "title",
		},
		{
			title: "Параметр",
			dataIndex: "value",
		},
	]

	const extendedColumns = useMemo(() => {
		return columns.map((x) => {
			const column = {...x, ...defaultColumnParams}
			return column
		})
	}, [columns])

	const handleNameClick = () => {
		setModalActive(true)
	}

	const handleSuccess = () => {
		dispatch(getProjectBasedInfo(projectGuid))
		setModalActive(false)
	}

	const handleCancel = () => {
		dispatch(getProjectBasedInfo(projectGuid))
		setModalActive(false)
	}

	useEffect(() => {
		dispatch(getProjectBasedInfo(params.guid))
	}, [params.guid])

	useEffect(() => {
		dispatch(setProjectCardActiveTab("basicInformation"))
	}, [])

	if (isLoading === LOADING_STATE_PENDING) {
		return <Spinner />
	}

	return (
		<Space size="middle" direction="vertical">
			<Row>
				<Col flex="auto">
					<Typography.Title level={3}>Основная информация</Typography.Title>
				</Col>
				<Col flex="none">
					<Button type="link" icon={<ArrowRightOutlined />} onClick={() => handleNameClick()}>
						Изменить информацию
					</Button>
				</Col>
			</Row>
			<BaseTable
				data={data}
				columns={extendedColumns}
				isLoading={isLoading === LOADING_STATE_PENDING}
				filteredValues={{}}
				disablePagination={true}
			/>
			{modalActive && (
				<ModalEditor entityName={"projects"} isProjects={true} entityId={projectGuid} onSuccess={handleSuccess} onCancel={handleCancel} />
			)}
		</Space>
	)
}

import React, {useState} from "react"
import {Row, Space, Col, Button} from "antd"
import {NavigationButton} from "../../../../components/NavigationButton/NavigationButton"
import {PlusOutlined} from "@ant-design/icons"
import {ModalEditor} from "../../../../components/ModalEditor/ModalEditor"
import {useParams} from "react-router-dom"
import {useDispatch} from "react-redux"
import {getElementTreeData} from "../../../../redux/projectManagementModule/elementsNavigationSlice"

// текст, который показывается если еще не выбран раздел заключения
export const EmptyElementPage = () => {
	const params = useParams()
	const dispatch = useDispatch()

	const [showElementModal, setElementModal] = useState(false)

	const showAddElement = () => {
		setElementModal(true)
	}

	const handleSuccess = () => {
		setElementModal(false)
		dispatch(getElementTreeData(params.guid))
	}

	const handleCancel = () => {
		setElementModal(false)
	}

	return (
		<Row wrap={false} style={{margin: "12px", width: "1200px"}}>
			<Col span={16}>
				<Space size="middle">
					<NavigationButton />
					<Button
						onClick={showAddElement}
						icon={<PlusOutlined />}
						type="primary"
						className="mainColor addButton">{`Создание пробы`}</Button>
					<Row>
						Выберите в навигации раздел, чтобы отобразить его содержание. Для поиска по названию (части названия) раздел заключения
						воспользуйтесь полем поиска в верхней части блока навигации.
					</Row>
				</Space>
			</Col>
			{showElementModal && (
				<ModalEditor
					entityName={"elements"}
					isProjects={true}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					useLink={true}
					link={`elements/form/${params.guid}`}
				/>
			)}
		</Row>
	)
}

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {digitalProfilesAPI} from "../pages/DigitalProfile/api/digitalProfilesAPI";

const initialState = {
	tableData: [],
	objectTableName: "",
	tableColumns: [],
	isLoading: "idle", // идет ли запрос за данными для таблицы

	isLoadingPost: "idle",

	columnFilterValues: {},
}

// получет данные для отрисовки таблицы в справочнике
export const getDigitalProfileTableByNavID = createAsyncThunk("digitalProfilesTables/getDigitalProfileTableByNavID", async (navID, thunkAPI) => {
	try {
		const response = await digitalProfilesAPI.getDigitalProfileByNavID(navID)
		return {response: response.data}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// работа с таблицей на странице Цифрового профиля
const digitalProfilesTablesSlice = createSlice({
	name: "digitalProfilesTables",
	initialState,
	reducers: {
		setObjectTableName: (state, action) => {
			state.objectTableName = action.payload
		},
		updateColumnFilterValues: (state, action) => {
			state.columnFilterValues = action.payload
		},
		clearColumnFilterValues: (state) => {
			state.columnFilterValues = null
		},
	},
	extraReducers: {
		[getDigitalProfileTableByNavID.pending]: (state) => {
			state.isLoading = "pending"
		},
		[getDigitalProfileTableByNavID.fulfilled]: (state, action) => {
			const {response} = action.payload
			state.tableData = response.data
			state.tableColumns = response.columns.map((i) => ({...i, ellipsis: true, width: 150}))
			state.isLoading = "succeeded"
		},
		[getDigitalProfileTableByNavID.rejected]: (state) => {
			state.isLoading = "failed"
		},
	},
})

export const digitalProfilesTablesReducer = digitalProfilesTablesSlice.reducer
export const {setObjectTableName, updateColumnFilterValues, clearColumnFilterValues} = digitalProfilesTablesSlice.actions

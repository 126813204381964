import {createSlice} from "@reduxjs/toolkit"

const initialState = {
	isNavigationCollapsed: "fixed", // open | closed | fixed
}

const layoutSlice = createSlice({
	name: "layout",
	initialState,
	reducers: {
		setIsNavigationCollapsed: (state, action) => {
			// если передали "fixed" устанавливаем "fixed" иначе закрываем или открываем окно
			if (action.payload === "fixed") {
				state.isNavigationCollapsed = state.isNavigationCollapsed === "fixed" ? "open" : "fixed"
			} else {
				state.isNavigationCollapsed = state.isNavigationCollapsed === "closed" ? "open" : "closed"
			}
		},
	},
})

export const {setIsNavigationCollapsed} = layoutSlice.actions
export const layoutReducer = layoutSlice.reducer

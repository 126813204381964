import customAxios from "../../../api/customAxios"
import { url } from "../../../api/url"

const baseURL = `/api/vnikti-projects/v1`

// методы страницы справочников
export const cardprojectAPI = {
	getCardProjectBaseUrl() {
		return baseURL
	},
	postResult(data){
		return customAxios.post(`${baseURL}/numericalresults`, data)
	},
	postCopyResult(resultGuid) {
		return customAxios.post(`${baseURL}/numericalresults/copyresult?guid=${resultGuid}`)
	},
	getConclusions(projectGuid) {
		return customAxios.get(`${baseURL}/conclusions/columns/${projectGuid}`)
	},
	postConclusion(data) {
		return customAxios.post(`${baseURL}/conclusions`, data)
	},
	putConclusion(data) {
		return customAxios.put(`${baseURL}/conclusions`, data)
	},
	postNewResultsStatus(resultType, resultsGuid, State) {
		return customAxios.post(`${baseURL}/${resultType}/changeState?resultsGuid=${resultsGuid}&State=${State}`)
	},
	getResultsInterpretations(projectGuid, workTypGuid) {
		return customAxios.get(`${baseURL}/resultsinterpretations/columns/${projectGuid}/${workTypGuid}`)
	},
	postResultsInterpretation(data) {
		return customAxios.post(`${baseURL}/resultsinterpretations`, data)
	},
	putResultsInterpretation(data) {
		return customAxios.put(`${baseURL}/resultsinterpretations`, data)
	},
	getFiles(projectGuid, workTypGuid) {
		// return customAxios.get(`${baseURL}/files/columns`)
		return customAxios.get(`${baseURL}/files/filestable?projectGuid=${projectGuid}&workGuid=${workTypGuid}`)
	},
	getTechnicalReport(projectGuid) {
		window.open(`${url}${baseURL}/projects/${projectGuid}/report`, "_blank")
		//return customAxios.get(`${url}${baseURL}/projects/${projectGuid}/report`)
	},
	getConditions(){
		return customAxios.get(`/api/vnikti-journals/v1/conditionRoomJournal/getconditions`)
	},
	getEquipmentAttributes(){
		return customAxios.get(`/api/vnikti-references/v1/equipmentAttributes`)
	},
	getUnitsOfMeasurement(){
		return customAxios.get(`/api/vnikti-references/v1/unitsOfMeasurement`)
	},
	getExecutors(){
		return customAxios.get(`${baseURL}/users/executors`)
	},
}

export const serializationPreparedDataToExportExcel = (preparedData) => {
	const serializedPreparedData = []

	const serializationPreparedData = (dataToExportList, outlineLevel = 0) => {
		dataToExportList.forEach((data) => {
			const {children, value, dataIndex} = data
			if (children) {
				serializedPreparedData.push({[dataIndex]: value, outlineLevel, isGroupName: true})
				serializationPreparedData(data.children, outlineLevel + 1)
			} else {
				serializedPreparedData.push({...data, outlineLevel})
			}
		})
	}

	serializationPreparedData(preparedData)

	return serializedPreparedData
}

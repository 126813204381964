import customAxios from "../../../api/customAxios"

//кэш запросов элементов для выпадающих списков
const optionsCacheMap = new Map()

// методы страницы справочников
export const digitalProfilesAPI = {
	getReferenceBaseUrl() {
		return "/api/vnikti-digitalprofiles/v1"
	},
	getDigitalProfilesNavigationArray() {
		return customAxios.get("/api/vnikti-digitalprofiles/v1/digitalprofilenavigation")
	},
	getDigitalProfileByNavID: (navID) => {
		return customAxios.get(`/api/vnikti-digitalprofiles/v1/digitalprofiles/columns/${navID}`)
	},

	getDigitalProfileTableData() {
		return customAxios.get(`/api/vnikti-digitalprofiles/v1/digitalprofiles/columns`)
	},
	getDigitalProfileFormInfo(tableName, guid) {
		const additionalParam = guid ? `?guid=${guid}` : ""
		return customAxios.get(`/api/vnikti-digitalprofiles/v1/digitalprofiles/form${additionalParam}`)
	},
	async getDigitalProfileOptions(url) {
		if (!optionsCacheMap.has(url)) {
			try {
				const response = await customAxios.get(url)
				optionsCacheMap.set(url, response.data)
			} catch (error) {
				return []
			}
		}

		return optionsCacheMap.get(url)
	},

	//добавление строки таблицы
	postDigitalProfilesTableRow(tableName, requestOptions) {
		return customAxios.post(`/api/vnikti-digitalprofiles/v1/digitalprofiles`, requestOptions)
	},

	//редактирование строки таблицы
	editDigitalProfileRow(requestOptions) {
		return customAxios.put(`/api/vnikti-digitalprofiles/v1/digitalprofiles/`, requestOptions)
	},

	//удаление строки таблицы
	deleteDigitalProfileTableRow(guid) {
		return customAxios.delete(`/api/vnikti-digitalprofiles/v1/digitalprofiles/${guid}`)
	},
}

import {Col} from "antd"
import React from "react"
import {ProjectTable} from "./ProjectsTable"

// Страница проектов
export const Projects = () => {
	return (
		<Col flex="auto" style={{height: "calc(100vh - 40px - 24px)", backgroundColor: "#fff", margin: "12px"}}>
			<ProjectTable tableName={"projects"} tableTitle={"Проекты"} />
		</Col>
	)
}

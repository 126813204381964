import React, {useEffect} from "react"
import "./App.css"
import {Route, Routes} from "react-router-dom"
import {Login} from "./pages/Login/Login"
import {ContentContainer} from "./components/ContentContainer"
import {Main} from "./pages/Main/Main"
import {References} from "./pages/References/References"
import {useDispatch, useSelector} from "react-redux"
import {setIsInitialized} from "./redux/appSlice"
import {ConfigProvider, Spin} from "antd"
import styled from "styled-components"
import {ReferencesContainer} from "./pages/References/ReferencesContainer"
import {EmptyReferencesPage} from "./pages/References/EmptyReferencesPage"
import {Help} from "./pages/Help/Help"

import {EmptyDigitalProfilesPage} from "./pages/DigitalProfile/EmptyDigitalProfilesPage"
import {DigitalProfiles} from "./pages/DigitalProfile/DigitalProfiles"
import {DigitalProfilesContainer} from "./pages/DigitalProfile/DigitalProfilesContainer"

import {ProjectContainer} from "./pages/Project/ProjectContainer"
import {Projects} from "./pages/Project/Projects"
import {CardProjectContainer} from "./pages/CardProjects/CardProjectContainer"
import {CardProject} from "./pages/CardProjects/CardProject"
import {EmptyCardProject} from "./pages/CardProjects/EmptyCardProject"
import {EmptyWorksPage} from "./pages/CardProjects/cardProjectTabs/works/EmptyWorksPage"
import {WorksContainer} from "./pages/CardProjects/cardProjectTabs/works/WorksContainer"
import {Works} from "./pages/CardProjects/cardProjectTabs/works/Works"
import {ProjectLogsContainer} from "./pages/ProjectLogs/ProjectLogsContainer"
import {ProjectLogs} from "./pages/ProjectLogs/ProjectLogs"
import {EmptyProjectLogs} from "./pages/ProjectLogs/EmptyProjectLogs"
import {BasicInformation} from "./pages/CardProjects/cardProjectTabs/basicInformation"
import {FragmentsAndElements} from "./pages/CardProjects/cardProjectTabs/Fragmnets&Elements/FragmentsAndElements"
import {EmptyResultsPage} from "./pages/CardProjects/cardProjectTabs/results/EmptyResultsPage"
import {ResultsContainer} from "./pages/CardProjects/cardProjectTabs/results/ResultsContainer"
import {Results} from "./pages/CardProjects/cardProjectTabs/results/Results"
import {Conclusion} from "./pages/CardProjects/cardProjectTabs/Conclusion/Conclusions"
import {ConclusionsContainer} from "./pages/CardProjects/cardProjectTabs/Conclusion/ConclusionsContainer"
import {EmptyConclusionPage} from "./pages/CardProjects/cardProjectTabs/Conclusion/EmptyConclusionPage"
import {FinalConclusion} from "./pages/CardProjects/cardProjectTabs/Conclusion/FinalConclusions"
import {SubFragmentTable} from "./pages/CardProjects/cardProjectTabs/Fragmnets&Elements/SubFragmentTable"
import {EmptyFragmentsSubPage} from "./pages/CardProjects/cardProjectTabs/Fragmnets&Elements/EmptyFragmentsSubtable"
import {EmptyReportPage} from "./pages/CardProjects/cardProjectTabs/Report/EmptyReportPage"
import {ReportContainer} from "./pages/CardProjects/cardProjectTabs/Report/ReportContainer"
import {Report} from "./pages/CardProjects/cardProjectTabs/Report/Report"
import {FinalReportPage} from "./pages/CardProjects/cardProjectTabs/finalReportPage"
import {Elements} from "./pages/CardProjects/cardProjectTabs/Elements/Elements"
import {ElementsContainer} from "./pages/CardProjects/cardProjectTabs/Elements/ElementsContainer"
import {EmptyElementPage} from "./pages/CardProjects/cardProjectTabs/Elements/EmptyElementsPage"
import {FragmentsContainer} from "./pages/CardProjects/cardProjectTabs/Fragments/FragmentsContainer"
import {EmptyFragmentPage} from "./pages/CardProjects/cardProjectTabs/Fragments/EmptyFragmentsPage"
import {Fragments} from "./pages/CardProjects/cardProjectTabs/Fragments/Fragments"

export const App = () => {
	const dispatch = useDispatch()

	const isInitialized = useSelector((state) => state.app.isInitialized)

	// проверяет валидность токена из localstorage, получает данные о пользователе, переводит isInitialized в true
	useEffect(() => {
		dispatch(setIsInitialized())
	}, [])

	return (
		<ConfigProvider
			theme={{
				token: {
					fontFamily: "WixMadeforDisplay",
					colorPrimary: "rgb(26, 38, 255)",
					colorLink: "rgb(26, 38, 255)",
				},
			}}>
			<>
				{isInitialized ? (
					<Routes>
						<Route path="/login" element={<Login />} /> {/*Логин*/}
						<Route path="/" element={<ContentContainer />}>
							<Route path="/" element={<Main />}></Route>
							<Route path="/references" element={<ReferencesContainer />}>
								{/*Справочники*/}
								<Route path="/references" element={<EmptyReferencesPage />} /> {/*Пустая страница Справочника*/}
								<Route path="/references/:tableName" element={<References />} /> {/*Справочник*/}
							</Route>
							<Route path="/digitalprofiles" element={<DigitalProfilesContainer />}>
								{/*Цифровые профили*/}
								<Route path="/digitalprofiles" element={<EmptyDigitalProfilesPage />} /> {/*Пустая страница Цифрового профиля*/}
								<Route path="/digitalprofiles/:guid" element={<DigitalProfiles />} /> {/*Цифровой профиль*/}
							</Route>

							{/*Помощь*/}
							<Route path="/help" element={<Help />} />

							{/* Журналы */}
							<Route path="/events" element={<ProjectLogsContainer />}>
								{/*Пустая страница Журнал событий*/}
								<Route path="/events" element={<EmptyProjectLogs />} />
								<Route path="/events/:tableName" element={<ProjectLogs />} /> {/*Страница Журнал событий*/}
								<Route path="/events/:upperTableName/:tableName" element={<ProjectLogs />} /> {/*Страница Журнал событий*/}
							</Route>

							{/* Карточка проекта */}
							<Route path="/cardproject" element={<CardProjectContainer />}>
								<Route path="/cardproject" element={<EmptyCardProject />} /> {/*Пустая страницаКарточка проекта*/}
								<Route path="/cardproject/:tableName" element={<CardProject />} /> {/*Страница Карточка проекта*/}
							</Route>

							<Route path="*" element={<div>Страница не найдена</div>} />
							{/* Управление проектами */}
							<Route path="/projects" element={<ProjectContainer />}>
								<Route path="/projects" element={<Projects />} /> {/*Страница Управления проектами*/}
								<Route path="/projects/:guid" element={<CardProjectContainer />}>
									<Route path="/projects/:guid/basicInformation" element={<BasicInformation />} />
									<Route path="/projects/:guid/fragmentsAndElements" element={<FragmentsAndElements />}>
										<Route path="/projects/:guid/fragmentsAndElements" element={<EmptyFragmentsSubPage />} />
										<Route path="/projects/:guid/fragmentsAndElements/:fragmentTab" element={<SubFragmentTable />} />
									</Route>
									<Route path="/projects/:guid/fragments" element={<FragmentsContainer />}>
										<Route path="/projects/:guid/fragments" element={<EmptyFragmentPage />} />
										<Route path="/projects/:guid/fragments/:fragmentGuid" element={<Fragments />} />
									</Route>
									<Route path="/projects/:guid/elements" element={<ElementsContainer />}>
										<Route path="/projects/:guid/elements" element={<EmptyElementPage />} />
										<Route path="/projects/:guid/elements/:elementGuid" element={<Elements />} />
									</Route>
									<Route path="/projects/:guid/results" element={<ResultsContainer />}>
										<Route path="/projects/:guid/results" element={<EmptyResultsPage />} />
										<Route path="/projects/:guid/results/:workType" element={<Results />} />
									</Route>
									<Route path="/projects/:guid/works" element={<WorksContainer />}>
										<Route path="/projects/:guid/works" element={<EmptyWorksPage />} />
										<Route path="/projects/:guid/works/:workType" element={<Works />} />
									</Route>
									<Route path="/projects/:guid/conclusion" element={<ConclusionsContainer />}>
										<Route path="/projects/:guid/conclusion" element={<EmptyConclusionPage />} />
										<Route path="/projects/:guid/conclusion/conclusion" element={<FinalConclusion />} />
										<Route path="/projects/:guid/conclusion/:tableName" element={<Conclusion />} />
									</Route>
									<Route path="/projects/:guid/report" element={<ReportContainer />}>
										<Route path="/projects/:guid/report" element={<EmptyReportPage />} />
										<Route path="/projects/:guid/report/:workType" element={<Report />} />
									</Route>
									<Route path="/projects/:guid/finalReport" element={<FinalReportPage />} />
									<Route path="/projects/:guid/:activeTab" element={<EmptyCardProject />} /> {/*Страница Карточка проекта*/}
								</Route>
							</Route>
						</Route>
					</Routes>
				) : (
					<SpinContainer>
						<Spin size="large" />
					</SpinContainer>
				)}
			</>
		</ConfigProvider>
	)
}

const SpinContainer = styled("div")`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`

import {Button, Col, Row, Space, Typography} from "antd"
import React, {useEffect, useMemo, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {BaseTable} from "../../../../components/BaseTable/baseTable"
import {LOADING_STATE_PENDING} from "../../../../utils/loadingState"
import {Spinner} from "../../../../components/Spinner/spinner"
import {defaultColumnParams} from "../../../../components/BaseTable/defaultColumnParams"
import {PlusOutlined} from "@ant-design/icons"
import {setProjectCardActiveTab} from "../../../../redux/projectManagementModule/projectCardSlice"
import {ModalEditor} from "../../../../components/ModalEditor/ModalEditor"
import {
	getProjectFragments,
	setActiveFragmentGuid,
	setActiveSubTableName,
} from "../../../../redux/projectManagementModule/fragmentsAndElementsSlice"
import {StyledTabs} from "../../../../components/StyledTabs"
import {Outlet, useNavigate, useParams} from "react-router-dom"
import {LinkButton} from "../../../../components/Buttons/linkButton"

const fragmentsTabs = [
	{
		label: "Элементы//Пробы//Образцы",
		key: "elements",
	},
	{
		label: "Технические параметры",
		key: "technical",
	},
	{
		label: "Эксплуатационные параметры",
		key: "operational",
	},
]

export const FragmentsAndElements = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const params = useParams()

	const [modalActive, setModalActive] = useState(false)
	const [tableName, setTableName] = useState("")
	const [selectedFragmentsAndElementsId, setSelectedFragmentsAndElementsId] = useState("")
	const [activeTab, setActiveTab] = useState("")

	const projectGuid = useSelector((state) => state.projectCard.guid)
	const {fragmentColumns, fragments, isLoadingFragments} = useSelector((state) => state.projectFragmentAndElement)

	const nameCell = (text, record) => {
		return (
			<LinkButton
				text={text}
				handleClick={() => {
					handleNameClick(record)
				}}
			/>
		)
	}

	const codeCell = (text, record) => {
		return (
			<LinkButton
				text={text}
				handleClick={() => {
					handleNameClick(record)
				}}
			/>
		)
	}

	const fragmentExtendedColumns = useMemo(() => {
		return fragmentColumns.map((x) => {
			const column = {...x, ...defaultColumnParams}
			if (x.dataIndex === "cipher") {
				return {...column, render: codeCell}
			}
			if (x.dataIndex === "name") {
				return {...column, render: nameCell}
			}
			return column
		})
	}, [fragmentColumns])

	const handleNameClick = (record) => {
		setSelectedFragmentsAndElementsId(record.guid)
		handleAddButtonClick()
	}

	const handleAddButtonClick = () => {
		setTableName("fragments")

		setModalActive(true)
	}

	const handleSuccess = () => {
		dispatch(getProjectFragments(projectGuid))

		setModalActive(false)
		setSelectedFragmentsAndElementsId("")
	}

	const handleCancel = () => {
		setModalActive(false)
		setSelectedFragmentsAndElementsId("")
	}

	const handleTabChange = (newValue) => {
		setActiveTab(newValue)
		navigate(`./${newValue}`)
		dispatch(setActiveSubTableName(fragmentsTabs.find((fT) => fT.key === newValue).label))
	}

	useEffect(() => {
		if (projectGuid !== null) dispatch(getProjectFragments(projectGuid))
	}, [projectGuid])

	useEffect(() => {
		dispatch(setProjectCardActiveTab("fragmentsAndElements"))
		if (params.fragmentTab !== undefined) {
			setActiveTab(params.fragmentTab)
			dispatch(setActiveSubTableName(fragmentsTabs.find((fT) => fT.key === params.fragmentTab).label))
		}
	}, [])

	if (isLoadingFragments === LOADING_STATE_PENDING) {
		return <Spinner />
	}

	return (
		<Space size="middle" direction="vertical">
			<Row gutter={[16, 24]}>
				<Col span={12}>
					<Typography.Title level={3}>Элементы и пробы</Typography.Title>
				</Col>
				<Col span={12}>
					<StyledTabs type="card" items={fragmentsTabs} activeKey={activeTab} onChange={handleTabChange} destroyInactiveTabPane />
				</Col>
			</Row>
			<Row gutter={[16, 24]}>
				<Col span={12}>
					<Button
						type="primary"
						icon={<PlusOutlined />}
						onClick={() => {
							handleAddButtonClick("f")
						}}>{`Создание [Элемента]`}</Button>
					<BaseTable
						data={fragments}
						columns={fragmentExtendedColumns}
						filteredValues={{}}
						onRow={(record, rowIndex) => {
							return {
								onClick: () => {
									dispatch(setActiveFragmentGuid({guid: record.guid, name: record.name}))
									if (activeTab === "") {
										handleTabChange("elements")
									}
								},
							}
						}}
					/>
				</Col>
				<Outlet />
			</Row>
			{modalActive && (
				<ModalEditor
					entityName={tableName}
					isProjects={true}
					entityId={selectedFragmentsAndElementsId}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					link={`fragments/form/${projectGuid}`}
				/>
			)}
		</Space>
	)
}

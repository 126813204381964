import customAxios from "../../../api/customAxios"

//кэш запросов элементов для выпадающих списков
const optionsCacheMap = new Map()

// методы страницы проектов
export const projectsAPI = {
	getProjectBaseUrl() {
		return "/api/vnikti-projects/v1"
	},
	getProjectsTableData(tableName) {
		return customAxios.get(`/api/vnikti-projects/v1/${tableName}/columns`)
	},
	getProjectsFormInfo(tableName, guid) {
		const additionalParam = guid ? `?guid=${guid}` : ""
		return customAxios.get(`/api/vnikti-projects/v1/${tableName}/form${additionalParam}`)
	},
	getProjects: () => {
		return customAxios.get(`/api/vnikti-projects/v1/projects/columns/}`)
	},
	getProjectByID: (projectID) => {
		return customAxios.get(`/api/vnikti-projects/v1/projects/columns/${projectID}`)
	},
	getProjectFormInfo(tableName, guid) {
		const additionalParam = guid ? `?guid=${guid}` : ""
		return customAxios.get(`/api/vnikti-projects/v1/projects/form${additionalParam}`)
	},
	async getProjectsOptions(url) {
		if (!optionsCacheMap.has(url)) {
			try {
				const response = await customAxios.get(url)
				optionsCacheMap.set(url, response.data)
			} catch (error) {
				return []
			}
		}

		return optionsCacheMap.get(url)
	},

	//добавление строки таблицы
	postProjectsTableRow(tableName, requestOptions) {
		return customAxios.post(`/api/vnikti-projects/v1/${tableName}`, requestOptions)
		// return customAxios.post(`/api/vnikti-projects/v1/projects`, requestOptions)
	},

	//редактирование строки таблицы
	editProjectsTableRow(tableName, requestOptions) {
		return customAxios.put(`/api/vnikti-projects/v1/${tableName}`, requestOptions)
	},

	//удаление строки таблицы
	deleteProjectsTableRow(tableName, guid) {
		return customAxios.delete(`/api/vnikti-projects/v1/${tableName}/${guid}`)
	},
}

import React from "react"
import {Row, Space, Col} from "antd"
import {NavigationButton} from "../../../../components/NavigationButton/NavigationButton"

// текст, который показывается если еще не выбрана категория работы
export const EmptyWorksPage = () => {
    return (
        <Row wrap={false} style={{margin: "12px", width: "1200px"}}>
            <Col span={16}>
                <Space size="middle">
                    <NavigationButton />
                    <Row>
                        Выберите в навигации категорию работы, чтобы отобразить её содержание. Для поиска по названию (части названия) работы
                        воспользуйтесь полем поиска в верхней части блока навигации.
                    </Row>
                </Space>
            </Col>
        </Row>
    )
}

import React, {useEffect, useState} from "react"
import {validateMessages} from "../../utils/validation.js"
import {useDispatch, useSelector} from "react-redux"
import PropTypes from "prop-types"
import {Button, Modal, Form} from "antd"
import {DownloadOutlined} from "@ant-design/icons"
import styled from "styled-components"
import {FormItem, RolesCheckboxContent, SectorsCheckboxContent} from "./FormItem"
import {StyledTabs} from "../StyledTabs"
import {ConfirmationModal} from "../ConfirmationModal/ConfirmationModal"
import {
	addItem,
	deleteItem,
	editItem,
	getFormInfo,
	getOptions,
	setSelectedTab,
	openNewModal,
	closeModal,
	modalDataInitialState,
	getFormTabsInfo,
	getFileEntity,
	updateSelectedOption,
} from "../../redux/modalEditorSlice"
import {Spinner} from "../Spinner/spinner.js"
import {mainAPI} from "../../pages/Main/api/mainAPI"
import {v4 as uuidv4} from "uuid"
import {ModalTreeSelect} from "./ModalTreeSelect"
import {referencesAPI} from "../../pages/References/api/referencesAPI.js"
import {projectManagementAPI} from "../../redux/projectManagementModule/api.js"
import DisableBodyScroll from "../DisableBodyScroll.js"

const mapFormInfoToAntdForm = (formInfo) => {
	const data = {}
	formInfo.forEach((item) => {
		data[item.dataIndex] = item.value
	})

	return data
}

// всплывающее окно создания/редактирования строк в таблице
export const ModalEditor = ({
	entityName,
	entityTitle,
	isReference,
	isProjectLog, //
	isDigitalProfile,
	isProjects,
	isUser,
	entityId,
	onSuccess,
	onCancel,
	onDelete,
	afterGetFormInfoFunc,
	allowDelete = true,
	cellData,
	getCustomFooter,
	link,
	useLink = false,
	isIntegration,
}) => {
	const dispatch = useDispatch()
	const [form] = Form.useForm()
	//ширина модального окна
	const [modalWidth, setModalWidth] = useState("1200px")
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false)
	const [modalId] = useState(uuidv4())

	const isLocked = useSelector((state) => state.modalEditor.isLocked)
	const modalDataList = useSelector((state) => state.modalEditor.modalDataList)
	const isLoadingIdle = useSelector((state) => state.modalEditor.isLoadingIdle)

	const currentModalData = modalDataList.find((x) => x.modalId === modalId)

	const {formInfo, tabs, prefix, selectedTab, selectListOptions, isLoading} = currentModalData || modalDataInitialState

	useEffect(() => {
		dispatch(openNewModal(modalId))
	}, [])

	useEffect(() => {
		//получаем поля для формы
		dispatch(
			getFormInfo({
				entityName,
				isReference,
				isProjectLog,
				isUser,
				isProjects,
				isDigitalProfile,
				entityId,
				afterGetFormInfoFunc,
				cellData,
				modalId,
				link,
				useLink,
			})
		) //
		dispatch(getFormTabsInfo({modalId}))
	}, [entityName, isReference, isProjectLog, isDigitalProfile, isProjects, entityId, isUser, link]) //

	useEffect(() => {
		// Переменная для частного изменения ссылки, в случаях окон редактирования с селектами зависящими от значений других селектов
		const isLinkUpdateRequire = entityId && entityName === "works"

		//получаем значения для выпадающих списков в форме
		const selectFields = formInfo
			.map((x) => {
				return {...x}
			})
			.filter((item) => item.cellType === "select" || item.cellType === "multiple" || item.cellType === "addingSelect")
		if (selectFields.length > 0) {
			if (isLinkUpdateRequire) {
				const fragmentFieldID = selectFields.findIndex((item) => item.dataIndex === "fragmentFK")
				if (selectFields[fragmentFieldID].value) {
					const elementFieldID = selectFields.findIndex((item) => item.dataIndex === "elementFK")
					selectFields[elementFieldID].link = `${selectFields[elementFieldID].link}?fragmentGuid=${selectFields[fragmentFieldID].value}`
				}
			}
			dispatch(getOptions(selectFields))
		}

		//устанавливаем в форму значения редактируемой сущности
		if (formInfo) {
			form.setFieldsValue(
				mapFormInfoToAntdForm(
					formInfo?.map((item) =>
						item.cellType === "select" &&
						(item.dataIndex === "navigationGuid" || item.dataIndex === "structuralSubdivision" || item.dataIndex === "subdivisionGuid") &&
						(item.value === undefined || item.value === null)
							? {...item, value: window.location.pathname.split("/")[3]}
							: item
					)
				)
			)
		}
	}, [formInfo])

	// Костыль
	const onValuesChange = (changedValues, allValues) => {
		if (entityName === "works" && changedValues.fragmentFK !== undefined) {
			const elementField = formInfo.find((item) => item.dataIndex === "elementFK")
			// elementField.disabled=false
			getElements(changedValues.fragmentFK, elementField.link)
			form.setFieldValue("elementFK", undefined)
		}
	}

	async function getElements(fragmentGuid, link) {
		try {
			const response = await referencesAPI.getReferenceOptions(`${link}?fragmentGuid=${fragmentGuid}`)

			dispatch(updateSelectedOption({dataIndex: "elementFK", newOptions: response}))
		} catch (e) {}
	}

	const handleCancel = () => {
		dispatch(closeModal())
		onCancel()
	}

	const handleAddEdit = async (newValues) => {
		const values = {...newValues}
		if (entityId) {
			values["guid"] = entityId
		}

		const func = entityId ? editItem : addItem
		const response = await dispatch(
			func({entityName, entityId, values, prefix, isUser, isDigitalProfile, isProjects, isProjectLog, isReference, cellData, link})
		)

		if (!response.error) {
			onSuccess(response.payload)
			dispatch(closeModal())
		}
	}

	const handleDelete = async () => {
		if (entityId) {
			const response = await dispatch(deleteItem({entityName, entityId, isProjectLog, isProjects, isReference, isDigitalProfile}))

			if (!response.error) {
				onDelete ? onDelete() : onSuccess()
				dispatch(closeModal())
			}
		}
	}

	const onTabChange = (tabKey) => {
		dispatch(setSelectedTab(tabKey))
	}

	const Header = () => {
		return (
			<StyledHeader>
				{entityId || isIntegration ? "Редактирование" : "Создание"}
				{entityTitle && ` [${entityTitle}]`}
				{entityName === "Reports" && ` (Отчет ${cellData})`}
			</StyledHeader>
		)
	}

	const handleDownloadFile = async () => {
		const res = await dispatch(getFileEntity({entityId})).unwrap()
		projectManagementAPI.getFile(res.fileGuid)
	}

	const handleDownloadExcel = () => {
		mainAPI.getExcelFile(entityName, entityId)
	}

	const Footer = () => {
		return (
			<>
				{entityId && entityName === "files" && (
					<Button onClick={handleDownloadFile} icon={<DownloadOutlined />} ghost type="primary">
						{" "}
						Скачать файл
					</Button>
				)}
				{entityId && (entityName === "control_procedures" || entityName === "EnergyPassport") && (
					<Button onClick={handleDownloadExcel} icon={<DownloadOutlined />} ghost type="primary">
						{" "}
						Экспорт в .xlsx
					</Button>
				)}
				<Button disabled={selectedTab === "thermotechnicalCalculation"} onClick={handleCancel}>
					Отмена
				</Button>
				{entityId && allowDelete && selectedTab !== "roles" && selectedTab !== "perimeter" && !isIntegration && (
					<Button
						type="primary"
						disabled={isLoading || selectedTab === "thermotechnicalCalculation"}
						danger
						onClick={() => setShowConfirmDeleteDialog(true)}>
						Удалить данные
					</Button>
				)}
				{!isLocked && selectedTab !== "perimeter" && selectedTab !== "roles" && (
					<Button
						type="primary"
						loading={isLoadingIdle === "pending"}
						disabled={isLoading || selectedTab === "thermotechnicalCalculation" || isLoadingIdle === "pending"}
						onClick={() => form.submit()}>
						{entityId || isIntegration ? "Сохранить" : entityName === "Reports" ? "Сформировать отчет" : "Создать"}
					</Button>
				)}
			</>
		)
	}

	const tabItems = tabs
		? tabs.map((tab) => {
				return {
					label: <>{tab.title}</>,
					key: tab.index,
					children: <></>,
				}
		  })
		: null

	const showTabs = tabItems && tabItems.length > 0
	const fieldsToDisplay = showTabs ? formInfo.filter((x) => x.formTabIndex === selectedTab) : formInfo

	const FormControl = fieldsToDisplay.length > 5 ? TwoColumnsForm : Form

	return (
		<Modal
			title={<Header />}
			open={true}
			onCancel={handleCancel}
			width={modalWidth}
			centered
			getContainer={false}
			footer={getCustomFooter ? getCustomFooter(form, isLoading) : <Footer />}>
			<DisableBodyScroll />
			{isLoading ? (
				<Spinner />
			) : (
				<>
					{showConfirmDeleteDialog && (
						<ConfirmationModal
							title="Подтвердите действие"
							message="Все данные будут удалены без возможности восстановления. Пожалуйста, подтвердите данное действие."
							isModalOpen={showConfirmDeleteDialog}
							okClick={handleDelete}
							cancelClick={() => setShowConfirmDeleteDialog(false)}
							okText="Удалить"
						/>
					)}
					{showTabs && <StyledTabs type="card" items={tabItems} onChange={onTabChange} activeKey={selectedTab} />}
					{selectedTab === "indicators" && <>lol</>}
					{selectedTab === "perimeter" && <ModalTreeSelect entityId={entityId} />}
					{selectedTab === "roles" && <RolesCheckboxContent entityId={entityId} />}
					{selectedTab === "sectors" && <SectorsCheckboxContent entityId={entityId} />}
					{selectedTab !== "esm" && selectedTab !== "perimeter" && (
						<FormControl
							disabled={isLocked || selectedTab === "thermotechnicalCalculation"}
							layout={"vertical"}
							labelCol={{xs: {span: 8}}}
							wrapperCol={{xs: {span: 15}}}
							form={form}
							onFinish={handleAddEdit}
							validateMessages={validateMessages}
							onValuesChange={onValuesChange}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									form.submit()
								}
							}}>
							{formInfo.map((field, i) => {
								const options = selectListOptions?.find((x) => x.dataIndex === field.dataIndex)?.options || []
								const hidden = !fieldsToDisplay.includes(field) || field.hidden

								return <FormItem key={i} field={field} options={options} hidden={hidden} />
							})}
						</FormControl>
					)}
				</>
			)}
		</Modal>
	)
}

const StyledHeader = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: -8px;
`

const TwoColumnsForm = styled(Form)`
	display: flex;
	flex-wrap: wrap;
	.ant-form-item {
		width: 50%;
	}
`

ModalEditor.propTypes = {
	entityName: PropTypes.string,
	entityTitle: PropTypes.string,
	isReference: PropTypes.bool,
	isProjects: PropTypes.bool,
	isProjectLog: PropTypes.bool, //
	isUser: PropTypes.bool,
	isDigitalProfile: PropTypes.bool,
	entityId: PropTypes.string,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
	onDelete: PropTypes.func,
	afterGetFormInfoFunc: PropTypes.func,
	allowDelete: PropTypes.bool,
	getCustomFooter: PropTypes.func,
	link: PropTypes.string,
	useLink: PropTypes.bool,
	isIntegration: PropTypes.bool,
}

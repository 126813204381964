import {BasicInformation} from "./cardProjectTabs/basicInformation"
import {ResultsContainer} from "./cardProjectTabs/results/ResultsContainer"
import {WorksContainer} from "./cardProjectTabs/works/WorksContainer"
import {Report} from "./cardProjectTabs/Report/Report"
import {ConclusionsTable} from "./cardProjectTabs/Conclusion/ConclusionsTable"
import { FinalReportPage } from "./cardProjectTabs/finalReportPage"
import { Fragments } from "./cardProjectTabs/Fragments/Fragments"
import { Elements } from "./cardProjectTabs/Elements/Elements"

//Табы
export const cardProjectTabsConfig = [
	{
		id: "basicInformation",
		name: "Основная информация",
		component: BasicInformation,
	},
	{
		id: "fragments",
		name: "Элементы",
		component: Fragments,
	},
	{
		id: "elements",
		name: "Пробы",
		component: Elements,
	},
	{
		id: "works",
		name: "Работы",
		component: WorksContainer,
	},
	{
		id: "results",
		name: "Результаты",
		component: ResultsContainer,
	},
	{
		id: "conclusion",
		name: "Заключение",
		component: ConclusionsTable,
	},
	{
		id: "report",
		name: "Подготовка отчета",
		component: Report,
	},
	{
		id: "finalReport",
		name: "Oтчет",
		component: FinalReportPage,
	},
]

import React, {useEffect, useMemo, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import PropTypes from "prop-types"
import {Button, Modal} from "antd"
import styled from "styled-components"
import {Spinner} from "../../../../components/Spinner/spinner.js"
import {BaseTable} from "../../../../components/BaseTable/baseTable.js"
import {LOADING_STATE_PENDING} from "../../../../utils/loadingState.js"
import {postNewWorkStatus} from "../../../../redux/projectManagementModule/worksTablesSlice.js"
import DisableBodyScroll from "../../../../components/DisableBodyScroll.js"

// всплывающее окно создания/редактирования строк в таблице
export const SendWorksModalWindow = ({searchedStatusID, newStatus, onSuccess, onCancel, workName, workTableMappedColumns, projectGuid}) => {
	const dispatch = useDispatch()

	//колонки таблицы
	const tableColumns = useSelector((state) => state.projectWorksTables.tableColumns)
	//данные таблицы
	const tableData = useSelector((state) => state.projectWorksTables.tableData)
	// идет ли запрос на бэк за данными для таблицы
	const isLoading = useSelector((state) => state.projectWorksTables.isLoading)

	const columnFilterValues = useSelector((state) => state.projectWorksTables.columnFilterValues)

	const [filteredData, setFilteredData] = useState([])

	//ширина модального окна
	const [modalWidth, setModalWidth] = useState("1200px")

	const [checkedWorks, setCheckedWorks] = useState([])

	const handleCancel = () => {
		onCancel()
	}

	const handleAddEdit = async () => {
		try {
			checkedWorks.forEach((checked) => {
				dispatch(
					postNewWorkStatus({
						workGuid: checked,
						statusCode: newStatus,
						unsuitability: undefined,
						workCategory: workName,
						projectGuid: projectGuid,
					})
				)
			})
		} catch (e) {
		} finally {
			onSuccess()
		}
	}

	const handleUnScope = async () => {
		if (searchedStatusID !== 2) return 0
		try {
			checkedWorks.forEach((checked) => {
				dispatch(
					postNewWorkStatus({
						workGuid: checked,
						statusCode: 4,
						unsuitability: undefined,
						workCategory: workName,
						projectGuid: projectGuid,
					})
				)
			})
		} catch (e) {
		} finally {
			onSuccess()
		}
	}

	const Header = () => {
		return <StyledHeader>Отправка работ</StyledHeader>
	}

	const Footer = () => {
		if (searchedStatusID === 2) {
			return (
				<>
					<Button onClick={handleCancel}>Отмена</Button>
					<Button type="primary" onClick={() => handleUnScope()}>
						Не согласовывать
					</Button>
					<Button type="primary" onClick={() => handleAddEdit()}>
						Согласовать
					</Button>
				</>
			)
		}
		return (
			<>
				<Button onClick={handleCancel}>Отмена</Button>
				<Button type="primary" onClick={() => handleAddEdit()}>
					Да, отправить
				</Button>
			</>
		)
	}

	const mappedColumns = useMemo(() => {
		const preColumns = workTableMappedColumns.filter(
			(x) =>
				x.title === "Шифр работы" ||
				x.title === "Фрагмент" ||
				x.title === "Элемент" ||
				x.title === "Проба" ||
				x.title === "Вид работы" ||
				x.title === "Исполнитель"
		)
		return preColumns
	}, [tableColumns])

	function filterData() {
		// фильтрация таблицы по столбцам
		const dataFiltered = tableData.filter((item) => item.workStatusID === searchedStatusID)
		setFilteredData(dataFiltered)
	}

	useEffect(() => {
		filterData()
	}, [])

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setCheckedWorks(selectedRows.map((row) => row.guid))
		},
		getCheckboxProps: (record) => ({
			disabled: record.name === "Disabled User",
			// Column configuration not to be checked
			name: record.name,
		}),
	}

	return (
		<Modal title={<Header />} open={true} onCancel={handleCancel} width={modalWidth} centered getContainer={false} footer={<Footer />}>
			<DisableBodyScroll />
			{isLoading === LOADING_STATE_PENDING ? (
				<Spinner />
			) : (
				<BaseTable
					data={filteredData}
					columns={mappedColumns}
					isLoading={isLoading === LOADING_STATE_PENDING}
					pageContentHeight={360}
					filteredValues={columnFilterValues}
					rowSelection={{
						type: "checkbox",
						...rowSelection,
					}}
				/>
			)}
		</Modal>
	)
}

const StyledHeader = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: -8px;
`

SendWorksModalWindow.propTypes = {
	searchedStatusID: PropTypes.number,
	newStatus: PropTypes.number,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
	workName: PropTypes.string,
	workTableMappedColumns: PropTypes.array,
	projectGuid: PropTypes.string,
}

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {makeNavigationTree} from "../../utils/makeNavigationTree"
import {projectManagementAPI} from "./api"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"
import {createBreadcrumbs} from "../../utils/createBreadcrumbs"

const initialState = {
	initialData: [], // данные о структуре справочников полученные с сервера
	treeData: [], // данные для treeSelect (древовидная структура справочников)
	breadcrumbsArray: [], // массив отображаемых хлебных крошек
	selectedReport: {guid: "", name: "", parentGuid: null}, // выбранный справочник
	isLoading: LOADING_STATE_EMPTY, // идет ли запрос
}

// получет массив объектов для навигации
export const getReportTreeData = createAsyncThunk("reportNavigation/getReportTreeData", async (params, thunkAPI) => {
	try {
		const response = await projectManagementAPI.getReportResultsNavigationArray(params)
		return response.data
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// tree select Навигация на странице Проект.Отчёты
const reportNavigationSlice = createSlice({
	name: "reportNavigation",
	initialState,
	reducers: {
		setSelectedReport: (state, action) => {
			state.selectedReport = action.payload
		},
		setReportBreadcrumbs: (state, action) => {
			let newArray = []
			createBreadcrumbs(state.initialData, action.payload, newArray)
			state.breadcrumbsArray = newArray
		},
	},
	extraReducers: {
		[getReportTreeData.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[getReportTreeData.fulfilled]: (state, action) => {
			state.initialData = action.payload
			let treeData = makeNavigationTree(action.payload)
			state.treeData = treeData
			state.selectedReport.guid = treeData[0].guid
			state.breadcrumbsArray = [{name: treeData[0].name, guid: treeData[0].guid}]
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getReportTreeData.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
		},
	},
})
export const {setSelectedReport, setReportBreadcrumbs} = reportNavigationSlice.actions
export const reportNavigationReducer = reportNavigationSlice.reducer

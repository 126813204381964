import {Col, Space} from "antd"
import React from "react"
import {ReportTable} from "./ReportTable"
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux"
import {NavigationButton} from "../../../../components/NavigationButton/NavigationButton"

// Страница Результаты
export const Report = () => {
	const params = useParams()

	//заголовок таблицы
	const tableTitle = useSelector((state) => state.projectReportNavigation.selectedReport.name)
	const selectedReportParentGuid = useSelector((state) => state.projectReportNavigation.selectedReport.parentGuid)

	return (
		<Col flex="auto" className="mainCol">
			<Space size="middle" style={{marginBottom: "12px"}}>
				<NavigationButton />
			</Space>
			<ReportTable tableName={params.workType} tableTitle={tableTitle} isWork={selectedReportParentGuid} />
		</Col>
	)
}

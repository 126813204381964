import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NavigationTree} from "../../../../components/NavigationTree/NavigationTree"
import {useNavigate, useParams} from "react-router-dom"
import {getReportTreeData, setReportBreadcrumbs, setSelectedReport} from "../../../../redux/projectManagementModule/reportNavigationSlice"
import {getNavigationTreeNodeByGuid} from "../../../../utils/getNavigationTreeNodeByGuid"

// Боковая часть с навигацией на странице Проект.Отчёты
export const ReportNavigation = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const params = useParams()

	const isLoading = useSelector((state) => state.projectReportNavigation.isLoading)
	const treeData = useSelector((state) => state.projectReportNavigation.treeData)
	const selectedReportGuid = useSelector((state) => state.projectReportNavigation.selectedReport.guid)

	// выбирает работу
	const onChange = (newValue, selectedReport) => {
		dispatch(setSelectedReport({guid: selectedReport.guid, name: selectedReport.name, parentGuid: selectedReport.parentGuid}))
		dispatch(setReportBreadcrumbs(newValue))
		navigate(`${selectedReport.guid}`)
	}

	useEffect(() => {
		dispatch(getReportTreeData(params.guid))
	}, [])

	useEffect(() => {
		if (treeData.length !== 0) {
			const referenceTreeNode = getNavigationTreeNodeByGuid(treeData, params.workType)
			if (referenceTreeNode) onChange(referenceTreeNode.guid, referenceTreeNode)
		}
	}, [params.workType, treeData])

	return <NavigationTree treeData={treeData} value={selectedReportGuid} onChange={onChange} isLoading={isLoading} />
}

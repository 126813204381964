import axios from "axios"
import customAxios from "../../../api/customAxios"
import {url} from "../../../api/url"

// методы авторизации
export const authApi = {
	me() {
		return customAxios.get("/api/vnikti-access/v1/account/user-info")
	},
	login(loginData) {
		return axios.post(`${url}/api/vnikti-access/v1/account/token`, loginData)
	},
}

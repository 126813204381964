import axios from "axios"
import {url} from "./url"

const customAxios = axios.create()
// 	{
// 	baseURL: url,
// })

customAxios.interceptors.request.use((config) => {
	config.headers.Authorization = `Bearer ${localStorage.getItem("aees_token")}`
	return config
})

export default customAxios

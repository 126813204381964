import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NavigationTree} from "../../components/NavigationTree/NavigationTree"
import {getReferencesTreeData, setReferencesBreadcrumbs, setSelectedReference} from "../../redux/referencesNavigationSlice"
import {useNavigate} from "react-router-dom"

// Боковая часть с навигацией на странице Справочников
export const ReferencesNavigation = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const isLoading = useSelector((state) => state.referencesNavigation.isLoading)
	const treeData = useSelector((state) => state.referencesNavigation.treeData)
	const selectedReferenceGuid = useSelector((state) => state.referencesNavigation.selectedReference.guid)

	// выбирает справочник, перерисовывает хлебные крошки
	const onChange = (newValue, selectedReference) => {
		dispatch(setSelectedReference({guid: selectedReference.guid, name: selectedReference.name}))
		dispatch(setReferencesBreadcrumbs(newValue))
		navigate(`${selectedReference.referenceName}`)
	}

	useEffect(() => {
		dispatch(getReferencesTreeData())
	}, [])

	return <NavigationTree treeData={treeData} value={selectedReferenceGuid} onChange={onChange} isLoading={isLoading} />
}

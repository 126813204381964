import {Input, Tooltip} from "antd"
import PropTypes from "prop-types"
import React from "react"

export const InputWithTooltip = ({value, onChange, field}) => {
	return (
		<Tooltip title={field.tooltip} trigger={["focus"]} placement="topLeft">
			<Input value={value} placeholder={field.placeholder} onChange={onChange} />
		</Tooltip>
	)
}

InputWithTooltip.propTypes = {
	value: PropTypes.any,
	onChange: PropTypes.func,
	field: PropTypes.object,
}

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {projectManagementAPI} from "./api"
import {setSnackbarNotification} from "../snackbarSlice"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"

const initialState = {
	tableData: [],
	objectTableName: "",
	tableColumns: [],

	checkedListRoles: [],

	isLoading: LOADING_STATE_EMPTY, // идет ли запрос за данными для таблицы

	columnFilterValues: {},
}

// получает данные для отрисовки таблицы
export const getWorkTable = createAsyncThunk("worksTables/getWorkTable", async (parameters, thunkAPI) => {
	try {
		const {projectGuid, workCategory} = parameters

		const response = await projectManagementAPI.getWorksByProjectsAndWorkType(projectGuid, workCategory)
		return {response: response.data, workCategory}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// меняет статус задачи
export const postNewWorkStatus = createAsyncThunk("worksTables/postNewWorkStatus", async (parameters, thunkAPI) => {
	try {
		const {workGuid, statusCode, unsuitability, projectGuid, workCategory} = parameters
		const response = await projectManagementAPI.postNewWorkStatus(workGuid, statusCode, unsuitability)

		if (response.status === 200) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Статус задачи успешно изменён", type: "success"}))

			if (statusCode === 7) {
				thunkAPI.dispatch(getWorkTable({workCategory: workCategory, projectGuid: projectGuid}))
			}

			return thunkAPI.fulfillWithValue({
				workStatus: statusCode,
				workGuid: workGuid,
			})
		}
	} catch (err) {
		thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка смены статуса. Повторите попытку", type: "error"}))
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// работа с таблицей на странице Справочники
const worksTablesSlice = createSlice({
	name: "worksTables",
	initialState,
	reducers: {
		setObjectTableName: (state, action) => {
			state.objectTableName = action.payload
		},
		updateColumnFilterValues: (state, action) => {
			state.columnFilterValues = action.payload
		},
		clearColumnFilterValues: (state) => {
			state.columnFilterValues = null
		},
		setCheckedListRoles: (state, action) => {
			state.checkedListRoles = action.payload
		},
	},
	extraReducers: {
		[getWorkTable.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[getWorkTable.fulfilled]: (state, action) => {
			const {response} = action.payload
			state.tableData = response.data
			state.tableColumns = response.columns.map((i) => ({...i, ellipsis: true, width: 150}))
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getWorkTable.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
		},
		[postNewWorkStatus.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[postNewWorkStatus.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
		},
		[postNewWorkStatus.fulfilled]: (state, action) => {
			const currentWork = state.tableData.find((work) => work.guid === action.payload.workGuid)
			if (currentWork) {
				currentWork.workStatusID = action.payload.workStatus
				// 1 - Новая
				// 2 - Ожидает согласования
				// 3 - Согласована
				// 4 - Не согласована
				// 5 - Выполняется
				// 6 - Просрочена
				// 7 - Завершена
				// 0 - Удалена
				switch (action.payload.workStatus) {
					case 1:
						currentWork.workStatus = "Новая"
						break

					case 2:
						currentWork.workStatus = "Ожидает согласования"
						break

					case 3:
						currentWork.workStatus = "Согласована"
						break

					case 4:
						currentWork.workStatus = "Не согласована"
						break
					case 5:
						currentWork.workStatus = "Выполняется"
						break
					case 6:
						currentWork.workStatus = "Просрочена"
						break
					case 7:
						currentWork.workStatus = "Завершена"
						break
					case 0:
						currentWork.workStatus = "Удалена"
						break
					default:
						currentWork.workStatus = "Произошла ошибка"
						break
				}
			}

			state.isLoading = LOADING_STATE_SUCCEEDED
		},
	},
})

export const worksTablesReducer = worksTablesSlice.reducer
export const {setObjectTableName, updateColumnFilterValues, clearColumnFilterValues} = worksTablesSlice.actions

import React from "react"
import PropTypes from "prop-types"
import {Button} from "antd"
import {EditOutlined} from "@ant-design/icons"

// кнопка в таблице, вызывающее действие (открытие модального окна)
export const LinkButton = ({text, handleClick}) => {
	const handleButtonClick = () => {
		handleClick && handleClick()
	}

	return (
		<Button
			style={{height: "100%"}}
			className="mainColor"
			children={
				<div style={{height: "100%", display: "flex", textAlign: "start", gap: "5px"}}>
					<EditOutlined style={{alignSelf: "start", display: "block", paddingTop: "4px"}} />
					<div style={{width: "100%", position: "static", whiteSpace: "normal"}}>{text}</div>
				</div>
			}
			type="link"
			// icon={<EditOutlined />}
			onClick={handleButtonClick}
		/>
	)
}

LinkButton.propTypes = {
	text: PropTypes.string,
	handleClick: PropTypes.func,
}

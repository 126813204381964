import React, {useEffect, useState, useMemo} from "react"
import PropTypes from "prop-types"
import {Typography, Button, Space} from "antd"
import {getWorkTable, postNewWorkStatus} from "../../../../redux/projectManagementModule/worksTablesSlice"
import {useDispatch, useSelector} from "react-redux"
import {PlusOutlined} from "@ant-design/icons"
import {ExportExcel} from "../../../../components/ExportExcel"
import {LOADING_STATE_PENDING} from "../../../../utils/loadingState"
import {BaseTable} from "../../../../components/BaseTable/baseTable"
import {Spinner} from "../../../../components/Spinner/spinner"
import {ModalEditor} from "../../../../components/ModalEditor/ModalEditor"
import {defaultColumnParams} from "../../../../components/BaseTable/defaultColumnParams"
import {Link} from "react-router-dom"
import {TakeAJobModalWindow} from "./TakeAJobModalWindow"
import {ChangeWorkStatusModalWindow} from "./ChangeWorkStatusModalWindow"
import {SendWorksModalWindow} from "./SendWorksModalWindow"

// таблица
export const WorkTable = ({tableName, tableTitle}) => {
	const dispatch = useDispatch()

	//колонки таблицы
	const tableColumns = useSelector((state) => state.projectWorksTables.tableColumns)
	//данные таблицы
	const tableData = useSelector((state) => state.projectWorksTables.tableData)
	// идет ли запрос на бэк за данными для таблицы
	const isLoading = useSelector((state) => state.projectWorksTables.isLoading)

	const projectGuid = useSelector((state) => state.projectCard.guid)

	const columnFilterValues = useSelector((state) => state.projectWorksTables.columnFilterValues)

	const [selectedId, setSelectedId] = useState("")
	const [showModalEditor, setShowModalEditor] = useState(false)
	const [setInWork, setSetInWork] = useState(false)
	const [showExecuterModal, setShowExecuterModal] = useState(false)
	const [showSendWorks, setShowSendWorks] = useState(false)
	const [showScopeWorks, setShowScopeWorks] = useState(false)

	const [showChangeStatusModal, setChangeStatusModal] = useState(false)
	const [currentStatus, setCurrentStatus] = useState(-1)
	const [newStatus, setNewStatus] = useState(-1)
	const [isDeclined, setIsDeclined] = useState(false)

	const loadData = () => {
		dispatch(getWorkTable({workCategory: tableName, projectGuid: projectGuid}))
	}

	const showAddModal = () => {
		setShowModalEditor(true)
	}

	const showEditModal = (record, declined = false) => {
		if (declined) {
			setIsDeclined(true)
		}
		setSelectedId(record.guid)
		setShowModalEditor(true)
	}

	const showTakeAJob = (record) => {
		setSelectedId(record.guid)
		setShowExecuterModal(true)
	}

	const showMultiWorksModal = () => {
		setShowSendWorks(true)
	}

	const showScopingWorks = () => {
		setShowScopeWorks(true)
	}

	const handleSuccess = () => {
		if (setInWork) {
			setNewWorkStatus(selectedId, 5)
		}
		if (isDeclined) {
			setNewWorkStatus(selectedId, 1)
		}
		setSetInWork(false)
		setSelectedId("")

		setShowModalEditor(false)
		setShowExecuterModal(false)
		setShowSendWorks(false)
		setShowScopeWorks(false)
		loadData()
	}

	const handleCancel = () => {
		setSetInWork(false)
		setSelectedId("")
		setShowModalEditor(false)
		setShowExecuterModal(false)
		setShowSendWorks(false)
		setShowScopeWorks(false)
	}

	const setNewWorkStatus = (workGuid, statusCode, unsuitability) => {
		dispatch(
			postNewWorkStatus({
				workGuid: workGuid,
				statusCode: statusCode,
				unsuitability: unsuitability,
				workCategory: tableName,
				projectGuid: projectGuid,
			})
		)
		setSelectedId("")
		setChangeStatusModal(false)
	}

	const openChangeStatusModal = (workGuid, curStatus, newStatus) => {
		setSelectedId(workGuid)
		setChangeStatusModal(true)
		setCurrentStatus(curStatus)
		setNewStatus(newStatus)
	}

	const actionCellRender = (text, record) => {
		switch (record.workStatusID) {
			case 1:
				return (
					<>
						<Button type="link" onClick={() => openChangeStatusModal(record.guid, record.workStatusID, 2)} style={{whiteSpace: "normal"}}>
							Отправить на согласование
						</Button>
						<Button type="link" onClick={() => showEditModal(record)} style={{whiteSpace: "normal"}}>
							Редактировать
						</Button>
						<Button type="link" onClick={() => openChangeStatusModal(record.guid, record.workStatusID, 0)} style={{whiteSpace: "normal"}}>
							Удалить
						</Button>
					</>
				)
			case 2:
				return (
					<>
						<Button type="link" onClick={() => openChangeStatusModal(record.guid, record.workStatusID, 3)} style={{whiteSpace: "normal"}}>
							Согласовать
						</Button>
						<Button type="link" onClick={() => openChangeStatusModal(record.guid, record.workStatusID, 4)} style={{whiteSpace: "normal"}}>
							Не согласовать
						</Button>
					</>
				)
			case 3:
				return (
					<>
						<Button
							type="link"
							style={{whiteSpace: "normal"}}
							onClick={() => {
								showTakeAJob(record)
								setSetInWork(true)
							}}>
							Взять в работу
						</Button>
						<Button
							type="link"
							style={{whiteSpace: "normal"}}
							onClick={() => {
								setSelectedId(record.guid)
								openChangeStatusModal(record.guid, record.workStatusID, 4)
							}}>
							Отклонить
						</Button>
					</>
				)
			case 4:
				return (
					<>
						<Button
							type="link"
							style={{whiteSpace: "normal"}}
							onClick={() => {
								showEditModal(record, true)
							}}>
							Редактировать
						</Button>
						<Button type="link" style={{whiteSpace: "normal"}} onClick={() => openChangeStatusModal(record.guid, record.workStatusID, 0)}>
							Удалить
						</Button>
					</>
				)
			case 5:
				return (
					<>
						<Link to={`/projects/${projectGuid}/results/${record.guid}`} style={{whiteSpace: "normal"}}>
							Внести результаты
						</Link>
						<Button type="link" onClick={() => openChangeStatusModal(record.guid, record.workStatusID, 7)} style={{whiteSpace: "normal"}}>
							Завершить
						</Button>
					</>
				)
			case 6:
				return (
					<>
						<Link to={`/results/${record.guid}`} style={{whiteSpace: "normal"}}>
							Внести результаты
						</Link>
						<Button type="link" onClick={() => openChangeStatusModal(record.guid, record.workStatusID, 7)} style={{whiteSpace: "normal"}}>
							Завершить
						</Button>
					</>
				)
			case 7:
				return (
					<>
						<Link to={`/projects/${projectGuid}/report/${record.guid}`} style={{whiteSpace: "normal"}}>
							Посмотреть результаты
						</Link>
					</>
				)
			case 0:
				return (
					<>
						<Button type="link" onClick={() => openChangeStatusModal(record.guid, record.workStatusID, 1)} style={{whiteSpace: "normal"}}>
							Отменить удаление
						</Button>
					</>
				)
			default:
				return <>Ошибка</>
		}
	}

	const getWorkReferring = () => {}

	const mappedColumns = useMemo(() => {
		//  сортировщик (для чисел и строк)
		const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || "").localeCompare(b || "") : a - b)

		const preColumns = tableColumns.map((x) => {
			const column = {
				...x,
				...defaultColumnParams,
				columnTitle: x.title,
				sorter: (a, b) => sorter(a[x.dataIndex], b[x.dataIndex]),
			}

			if (x.title === "Действие") {
				return {...x, render: actionCellRender}
			}

			return column
		})

		const actionColumns = preColumns.find((column) => column.title === "Действие")

		if (!actionColumns)
			preColumns.push({...defaultColumnParams, title: "Действие", dataIndex: "action", columnTitle: "Действие", render: actionCellRender})
		return preColumns
	}, [tableColumns])

	//запрос данных
	useEffect(() => {
		loadData()
	}, [tableName, projectGuid])

	if (isLoading === LOADING_STATE_PENDING) {
		return <Spinner />
	}

	return (
		<>
			<Space direction="vertical" size="small" style={{width: "100%"}}>
				<Typography.Title level={3}>{tableTitle}</Typography.Title>
				<Space>
					<Button onClick={showAddModal} icon={<PlusOutlined />} type="primary" className="mainColor addButton">{`Добавить работу`}</Button>
					<ExportExcel dataToExport={tableData} tableHeader={tableColumns} fileName={tableName} />
					<Button onClick={showMultiWorksModal} type="primary" className="mainColor addButton">{`Отправить на согласование`}</Button>
					<Button onClick={showScopingWorks} type="primary" className="mainColor addButton">{`Согласовать`}</Button>
					<Button onClick={getWorkReferring} type="primary" disabled={true}>{`Направление`}</Button>
				</Space>
				<BaseTable
					data={tableData}
					columns={mappedColumns}
					isLoading={isLoading === LOADING_STATE_PENDING}
					pageContentHeight={360}
					filteredValues={columnFilterValues}
				/>
			</Space>

			{showModalEditor && (
				<ModalEditor
					entityName={"works"}
					isProjects={true}
					entityId={selectedId}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					useLink={true}
					link={`works/getform?projectGuid=${projectGuid}&workCategoryGuid=${tableName}`}
				/>
			)}
			{showExecuterModal && (
				<TakeAJobModalWindow
					entityName={"works"}
					isProjects={true}
					entityId={selectedId}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					useLink={true}
					link={`works/getform?projectGuid=${projectGuid}&workCategoryGuid=${tableName}`}
				/>
			)}
			{showChangeStatusModal && (
				<ChangeWorkStatusModalWindow
					currentStatus={currentStatus}
					newStatus={newStatus}
					onSuccess={(unsuitability, newStatus) => setNewWorkStatus(selectedId, newStatus, unsuitability)}
					onCancel={() => setChangeStatusModal(false)}
					workName={tableTitle}
				/>
			)}
			{showSendWorks && (
				<SendWorksModalWindow
					searchedStatusID={1}
					workCategoryGuid={tableName}
					projectGuid={projectGuid}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					newStatus={2}
					workName={tableName}
					workTableMappedColumns={mappedColumns}
				/>
			)}
			{showScopeWorks && (
				<SendWorksModalWindow
					searchedStatusID={2}
					workCategoryGuid={tableName}
					projectGuid={projectGuid}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					newStatus={3}
					workName={tableName}
					workTableMappedColumns={mappedColumns}
				/>
			)}
		</>
	)
}

WorkTable.propTypes = {
	tableName: PropTypes.string, // название Таблицы
	tableTitle: PropTypes.string, // подпись Таблицы
}

import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NavigationTree} from "../../../../components/NavigationTree/NavigationTree"
import {useNavigate, useParams} from "react-router-dom"
import {getFragmentTreeData, setSelectedFragment} from "../../../../redux/projectManagementModule/fragmentsNavigationSlice"
import {getNavigationTreeNodeByGuid} from "../../../../utils/getNavigationTreeNodeByGuid"

// Боковая часть с навигацией на странице Заключения
export const FragmentsNavigation = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const params = useParams()

	const isLoading = useSelector((state) => state.projectFragmentsNavigation.isLoading)
	const treeData = useSelector((state) => state.projectFragmentsNavigation.treeData)
	const selectedFragmentGuid = useSelector((state) => state.projectFragmentsNavigation.selectedFragment.guid)

	// выбирает раздел, перерисовывает хлебные крошки
	const onChange = (newValue, selectedFragment) => {
		dispatch(setSelectedFragment({guid: selectedFragment.guid, name: selectedFragment.name}))
		navigate(`${selectedFragment.guid}`)
	}

	useEffect(() => {
		dispatch(getFragmentTreeData(params.guid))
	}, [])

	useEffect(() => {
		if (treeData.length !== 0) {
			const fragmentsTreeNode = getNavigationTreeNodeByGuid(treeData, params.fragmentGuid)
			if (fragmentsTreeNode) onChange(fragmentsTreeNode.guid, fragmentsTreeNode)
		}
	}, [params.fragmentGuid, treeData])

	return <NavigationTree treeData={treeData} value={selectedFragmentGuid} onChange={onChange} isLoading={isLoading} />
}

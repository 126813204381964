import {Button, Col, Space} from "antd"
import React, {useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {NavigationButton} from "../../../../components/NavigationButton/NavigationButton"
import {ElementsTable} from "./ElementsTable"
import {PlusOutlined} from "@ant-design/icons"
import {ModalEditor} from "../../../../components/ModalEditor/ModalEditor"
import {getElementTreeData} from "../../../../redux/projectManagementModule/elementsNavigationSlice"

// Страница Справочники
export const Elements = () => {
	const params = useParams()
	const dispatch = useDispatch()

	//заголовок таблицы
	const tableTitle = useSelector((state) => state.projectElementsNavigation.selectedElement.name)

	const [showElementModal, setElementModal] = useState(false)

	const showAddElement = () => {
		setElementModal(true)
	}

	const handleSuccess = () => {
		dispatch(getElementTreeData(params.guid))
		setElementModal(false)
	}

	const handleCancel = () => {
		setElementModal(false)
	}

	return (
		<Col flex="auto" className="mainCol">
			<Space size="middle" style={{marginBottom: "12px", display: "flex"}}>
				<NavigationButton />
				<Button onClick={showAddElement} icon={<PlusOutlined />} type="primary" className="mainColor addButton">{`Создание пробы`}</Button>
			</Space>
			{showElementModal && (
				<ModalEditor
					entityName={"elements"}
					isProjects={true}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					useLink={true}
					link={`elements/form/${params.guid}`}
				/>
			)}
			<ElementsTable tableName={params.tableName} tableTitle={tableTitle} elementGuid={params.elementGuid} />
		</Col>
	)
}

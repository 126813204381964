import {Row} from "antd"
import {Outlet} from "react-router-dom"
import {FragmentsNavigation} from "./FragmentsNavigation"
import React, {useEffect} from "react"
import {useDispatch} from "react-redux"
import {setProjectCardActiveTab} from "../../../../redux/projectManagementModule/projectCardSlice"

// лейаут для страницы Карточка проекта -> Заключение
export const FragmentsContainer = () => {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(setProjectCardActiveTab("fragments"))
	}, [])

	return (
		<Row wrap={false}>
			<FragmentsNavigation />
			<Outlet />
		</Row>
	)
}

import React from "react"
import PropTypes from "prop-types"
import * as ExcelJS from "exceljs"
import FileSaver from "file-saver"
import {Button} from "antd"
import {DownloadOutlined} from "@ant-design/icons"
import {setSnackbarNotification} from "../redux/snackbarSlice"
import {useDispatch} from "react-redux"
import {serializationPreparedDataToExportExcel} from "../utils/serializationPreparedDataToExportExcel"
import {getOtherHeadersLevelsToExportExcel} from "../utils/getHeadersLevelsToExportExcel"

export const ExportExcel = ({dataToExport, fetchExcelData, formatData, tableHeader, fileName = "Таблица"}) => {
	const dispatch = useDispatch()

	const getTopLevelTableHeaderForColumn = (tableHeaderLevel) =>
		tableHeaderLevel
			.map((i) => {
				if (!i.children) {
					return {header: i.columnTitle || i.title, key: i.dataIndex, width: 20}
				}

				return getTopLevelTableHeaderForColumn(i.children)
			})
			.flat()

	let tableHeaderForColumn = tableHeader ? getTopLevelTableHeaderForColumn(tableHeader) : []
	let otherTableHeaderForColumn = tableHeader ? getOtherHeadersLevelsToExportExcel(tableHeader) : []

	//подготвка данных для экспорта в эксель
	const preparationData = (dataToExport) => {
		let preparedData
		//если не нужно дополнительно форматировать данные передаем dataToExport без изменений
		if (!formatData) {
			// preparedData = [...dataToExport]
			preparedData = JSON.parse(JSON.stringify(dataToExport))
		} else {
			//форматируем данные для дальнейшего экспорта
			preparedData = formatData(dataToExport)
		}

		preparedData = serializationPreparedDataToExportExcel(preparedData)

		let headerKeys = ["outlineLevel", "isGroupName"]
		// создаем массив с ключами экспортируемых данных
		for (let i = 0; i < tableHeaderForColumn.length; i++) {
			headerKeys.push(tableHeaderForColumn[i].key)
		}
		// фильтруем данные, забираем нужные столбцы
		const filterExportData = (array) =>
			array.map((item) => {
				return headerKeys.reduce((acc, curr) => {
					if (typeof item[curr] === "boolean") {
						acc[curr] = item[curr] ? "Да" : "Нет"
					} else {
						acc[curr] = item[curr] ?? ""
					}

					return acc
				}, {})
			})

		return filterExportData(preparedData)
	}

	const saveExcel = async () => {
		try {
			//получаем данные для таблицы (если не переданы данные делаем запрос)
			const tableData = dataToExport ? dataToExport : await fetchExcelData()
			//обрабатываем данные для отображения в таблице
			const preparedTableData = await preparationData(tableData)
			// создаем workbook
			const workbook = new ExcelJS.Workbook()
			// создаем таблицу в workbook
			const worksheet = workbook.addWorksheet("Таблица")
			// добавляем заголовки таблицы с установленой шириной
			worksheet.columns = tableHeaderForColumn

			// заполняем таблицу данными добавляя ряды
			preparedTableData.forEach((row) => {
				worksheet.addRow(row)
			})

			// добавление и горизонтальное объединение нижних уровней заголовков в столбцах
			otherTableHeaderForColumn.forEach((level, index) => {
				const levelHeaderRow = level
					.map((item) => {
						if (item.cellLength > 1) {
							const oneTitleHeaderCellList = [item.title]
							for (let i = 0; i < item.cellLength - 1; i++) {
								oneTitleHeaderCellList.push("")
							}
							return oneTitleHeaderCellList
						}

						return item.title
					})
					.flat()

				worksheet.insertRow(index + 1, levelHeaderRow)

				const levelRow = worksheet.getRow(index + 1)
				let headerCellAddressStart = ""
				let mergeCellsCount = -1

				levelRow.eachCell((cell) => {
					if (mergeCellsCount > 0) {
						mergeCellsCount--
					}

					if (mergeCellsCount === 0) {
						worksheet.mergeCells(`${headerCellAddressStart}:${cell.address}`)
						mergeCellsCount = -1
					} else if (cell.text !== "") {
						mergeCellsCount =
							level.find((item) => item.title === cell.text && cell.text !== "" && item.cellLength > 1)?.cellLength - 1 ?? -1
						headerCellAddressStart = cell.address
					}
				})
			})

			// вертикальное объединение заголовков в столбцах
			tableHeaderForColumn.forEach((_, index) => {
				const column = worksheet.getColumn(index + 1)
				let headerCellAddressStart = ""
				column.eachCell((cell, rowNumber) => {
					if (rowNumber <= otherTableHeaderForColumn.length + 1) {
						if (headerCellAddressStart === "" && cell.text === "") {
							headerCellAddressStart = cell.address
						}

						if (headerCellAddressStart !== "" && cell.text !== "") {
							worksheet.getCell(headerCellAddressStart).value = cell.text
							worksheet.mergeCells(`${cell.address}:${headerCellAddressStart}`)
							headerCellAddressStart = ""
						}
					}
				})
			})

			for (let i = 1; i <= otherTableHeaderForColumn.length + 1; i++) {
				worksheet.getRow(i).font = {bold: true, size: 12}
			}

			// добавляем границы таблицы и ячеек
			worksheet.eachRow({includeEmpty: false}, (row) => {
				// достаем массив с ячейками в ряду row
				const currentCells = row._cells
				// применяем границу только для непустой ячейки
				currentCells.forEach((singleCell) => {
					// сохраняем адрес ячейки (A1, A2, A3, B1, B2, B3, ...)
					const cellAddress = singleCell._address
					const currentCell = worksheet.getCell(cellAddress)
					// добавляем границы
					currentCell.border = {
						top: {style: "thin"},
						left: {style: "thin"},
						bottom: {style: "thin"},
						right: {style: "thin"},
					}
					// выравниваем тект по центру ячейки, включаем перенос слов в строке
					currentCell.alignment = {vertical: "middle", horizontal: "center", wrapText: true}
				})
			})

			preparedTableData.forEach((item, index) => {
				if (item.outlineLevel) {
					worksheet.getRow(index + 2 + otherTableHeaderForColumn.length).outlineLevel = item.outlineLevel
				}
				if (item.isGroupName && item.isGroupName !== "") {
					const row = worksheet.getRow(index + 2 + otherTableHeaderForColumn.length)

					let groupName = ""
					for (let i = 0; i < tableHeaderForColumn.length; i++) {
						if (item[tableHeaderForColumn[i].key] !== "") {
							groupName = item[tableHeaderForColumn[i].key]
						}
					}
					worksheet.mergeCells(`${row.getCell(1).address}:${row.getCell(row.cellCount).address}`)
					const firstCellInRow = row.getCell(1)
					firstCellInRow.value = `${"      ".repeat(item.outlineLevel)}${groupName}`
					firstCellInRow.alignment = {vertical: "middle", horizontal: "left", wrapText: true}
					firstCellInRow.font = {bold: true, size: 12}
					firstCellInRow.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: {argb: "F5F5F5"},
					}
				}
			})

			worksheet.properties.outlineProperties = {
				summaryBelow: false,
				summaryRight: false,
			}

			// записываем workbook в буфер
			const buffer = await workbook.xlsx.writeBuffer()
			// сохраняем таблицу
			const blob = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"})
			FileSaver.saveAs(blob, `${fileName}.xlsx`)
		} catch (err) {
			console.log("err", err)
			dispatch(setSnackbarNotification({message: "Ошибка экспорта. Повторите попытку", type: "error"}))
		}
	}

	return (
		<Button onClick={saveExcel} icon={<DownloadOutlined />}>
			Экспортировать в .xlsx
		</Button>
	)
}

ExportExcel.propTypes = {
	dataToExport: PropTypes.array,
	fetchExcelData: PropTypes.func,
	formatData: PropTypes.func,
	tableHeader: PropTypes.array,
	fileName: PropTypes.string,
}

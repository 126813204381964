import {Col, Space} from "antd"
import React from "react"
import {ResultTable} from "./ResultTable"
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux"
import {NavigationButton} from "../../../../components/NavigationButton/NavigationButton"

// Страница Результаты
export const Results = () => {
	const params = useParams()

	//заголовок таблицы
	const tableTitle = useSelector((state) => state.projectResultsNavigation.selectedResult.name)

	return (
		<Col flex="auto" className="mainCol">
			<Space size="middle" style={{marginBottom: "12px"}}>
				<NavigationButton />
			</Space>
			<ResultTable tableName={params.workType} tableTitle={tableTitle} />
		</Col>
	)
}

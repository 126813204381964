import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"

const initialState = {
	isNotification: false,
}

export const getIsNotification = createAsyncThunk("userNotification/getIsNotification", async (thunkAPI) => {
	try {
		return "test"
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

export const notificationSlice = createSlice({
	name: "userNotification",
	initialState,
	extraReducers: {
		[getIsNotification.fulfilled]: (state, action) => {
			state.isNotification = action.payload
		},
	},
})

export const {setNotification} = notificationSlice.actions
export const notificationReducer = notificationSlice.reducer

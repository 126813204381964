import React, {useState} from "react"
import {Row, Space, Col, Button} from "antd"
import {NavigationButton} from "../../../../components/NavigationButton/NavigationButton"
import {PlusOutlined} from "@ant-design/icons"
import {useDispatch} from "react-redux"
import {useParams} from "react-router-dom"
import {ModalEditor} from "../../../../components/ModalEditor/ModalEditor"
import {getFragmentTreeData} from "../../../../redux/projectManagementModule/fragmentsNavigationSlice"

// текст, который показывается если еще не выбран раздел заключения
export const EmptyFragmentPage = () => {
	const params = useParams()
	const dispatch = useDispatch()

	const [showFragmentModal, setFragmentModal] = useState(false)

	const showAddFragment = () => {
		setFragmentModal(true)
	}

	const handleSuccess = () => {
		dispatch(getFragmentTreeData(params.guid))
		setFragmentModal(false)
	}

	const handleCancel = () => {
		setFragmentModal(false)
	}

	return (
		<Row wrap={false} style={{margin: "12px", width: "1200px"}}>
			<Col span={16}>
				<Space size="middle">
					<NavigationButton />
					<Button
						onClick={showAddFragment}
						icon={<PlusOutlined />}
						type="primary"
						className="mainColor addButton">{`Создание элемента`}</Button>
					<Row>
						Выберите в навигации раздел, чтобы отобразить его содержание. Для поиска по названию (части названия) раздел заключения
						воспользуйтесь полем поиска в верхней части блока навигации.
					</Row>
				</Space>
			</Col>
			{showFragmentModal && (
				<ModalEditor
					entityName={"fragments"}
					isProjects={true}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					useLink={true}
					link={`fragments/form/${params.guid}`}
				/>
			)}
		</Row>
	)
}

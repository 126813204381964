import React from "react";
import {useSelector} from "react-redux";
import {Navigate, Outlet} from "react-router-dom";
import {Row} from "antd";
import {ProjectLogsNavigation} from "./ProjectLogsNavigation";

export const ProjectLogsContainer= () => {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)

    if (!isLoggedIn) {
        return <Navigate to="/login" replace={true} />
    }

    return (
        <Row wrap={false}>
            <ProjectLogsNavigation />
            <Outlet />
        </Row>
    )
}


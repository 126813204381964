import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {makeNavigationTree} from "../../utils/makeNavigationTree"
import {projectManagementAPI} from "./api"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"
import { createBreadcrumbs } from "../../utils/createBreadcrumbs"

const initialState = {
	initialData: [], // данные о структуре справочников полученные с сервера
	treeData: [], // данные для treeSelect (древовидная структура справочников)
	breadcrumbsArray: [], // массив отображаемых хлебных крошек
	selectedResult: {guid: "", name: ""}, // выбранный справочник
	isLoading: LOADING_STATE_EMPTY, // идет ли запрос
}

// получет массив объектов для навигации
export const getResultsTreeData = createAsyncThunk("resultsNavigation/getResultsTreeData", async (params, thunkAPI) => {
	try {
		const response = await projectManagementAPI.getWorkResultsNavigationTree(params)
		return response.data
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// tree select Навигация на странице Проект.Результаты
const resultsNavigationSlice = createSlice({
	name: "resultsNavigation",
	initialState,
	reducers: {
		setSelectedResult: (state, action) => {
			state.selectedResult = action.payload
		},
		setResultsBreadcrumbs: (state, action) => {
			let newArray = []
			createBreadcrumbs(state.initialData, action.payload, newArray)
			state.breadcrumbsArray = newArray
		},
	},
	extraReducers: {
		[getResultsTreeData.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[getResultsTreeData.fulfilled]: (state, action) => {
			state.initialData = action.payload
			let treeData = makeNavigationTree(action.payload)
			state.treeData = treeData
			state.selectedResult.guid = treeData[0].guid
			state.breadcrumbsArray = [{name: treeData[0].name, guid: treeData[0].guid}]
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getResultsTreeData.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
		},
	},
})
export const {setSelectedResult, setResultsBreadcrumbs} = resultsNavigationSlice.actions
export const resultsNavigationReducer = resultsNavigationSlice.reducer

import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NavigationTree} from "../../components/NavigationTree/NavigationTree"

import {setSelectedProjectLog} from "../../redux/ProgectLogNavigationSlice";
import {useNavigate} from "react-router-dom"
import {getProjectLogTreeData} from "../../redux/ProgectLogNavigationSlice";

// Боковая часть с навигацией на странице Справочников
export const ProjectLogsNavigation = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isLoading = useSelector((state) => state.ProjectLogNavigation.isLoading)
    const treeData = useSelector((state) => state.ProjectLogNavigation.treeData)
    const selectedProjectLogGuid = useSelector((state) => state.ProjectLogNavigation.selectedProjectLog.guid)

    // выбирает справочник
    const onChange = (selectedProjectLogGuid, selectedProjectLog) => {
        dispatch(setSelectedProjectLog({guid: selectedProjectLog.guid, name: selectedProjectLog.name}));
        navigate(`${selectedProjectLog.referenceName}`)
        console.log(navigate(`${selectedProjectLog.referenceName}`))
    }

    useEffect(() => {
        dispatch(getProjectLogTreeData())
    }, [])

    return <NavigationTree treeData={treeData} value={selectedProjectLogGuid} onChange={onChange} isLoading={isLoading} />
}
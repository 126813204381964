import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NavigationTree} from "../../../../components/NavigationTree/NavigationTree"
import {useNavigate, useParams} from "react-router-dom"
import {getElementTreeData, setSelectedElement} from "../../../../redux/projectManagementModule/elementsNavigationSlice"
import {getNavigationTreeNodeByGuid} from "../../../../utils/getNavigationTreeNodeByGuid"

// Боковая часть с навигацией на странице Заключения
export const ElementsNavigation = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const params = useParams()

	const isLoading = useSelector((state) => state.projectElementsNavigation.isLoading)
	const treeData = useSelector((state) => state.projectElementsNavigation.treeData)
	const selectedElementGuid = useSelector((state) => state.projectElementsNavigation.selectedElement.guid)

	// выбирает раздел, перерисовывает хлебные крошки
	const onChange = (newValue, selectedElement) => {
		dispatch(setSelectedElement({guid: selectedElement.guid, name: selectedElement.name}))
		if (selectedElement.parentGuid !== null) navigate(`${selectedElement.guid}`)
		else navigate(`.`)
	}

	useEffect(() => {
		dispatch(getElementTreeData(params.guid))
	}, [])

	useEffect(() => {
		if (treeData.length !== 0) {
			const elementsTreeNode = getNavigationTreeNodeByGuid(treeData, params.elementGuid)
			if (elementsTreeNode) onChange(elementsTreeNode.guid, elementsTreeNode)
		}
	}, [params.elementGuid, treeData])

	return <NavigationTree treeData={treeData} value={selectedElementGuid} onChange={onChange} isLoading={isLoading} />
}

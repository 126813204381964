import {Col, Space} from "antd"
import React from "react"
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux"
import { NavigationButton } from "../../../../components/NavigationButton/NavigationButton"
import { ConclusionsTable } from "./ConclusionsTable"

// Страница Справочники
export const Conclusion = () => {
	const params = useParams()
    
	//заголовок таблицы
	const tableTitle = useSelector((state) => state.projectConclusionNavigation.selectedConclusion.name)

	return (
		<Col flex="auto" className="mainCol">
			<Space size="middle" style={{marginBottom: "12px"}}>
				<NavigationButton />
			</Space>			
			<ConclusionsTable tableName={params.tableName} tableTitle={tableTitle} />
		</Col>
	)
}
import customAxios from "../../../api/customAxios"

// методы главной страницы
export const mainAPI = {
	//Получение списка всех записей для пользователя (Auth)
	getMainNavigationArray() {
		return customAxios.get("/api/vnikti-objectmanagement/v1/navigation-tree/user")
	},

	// //Получение списка всех записей
	// getNavigationTree() {
	// 	return customAxios.get("/api/vnikti-objectmanagement/v1/navigation-tree")
	// },

	/////////////////////////////////////
	//Получение списка объектов и колонок
	getObjectTableDataByLevel(tableName, guid, onlyActive) {
		return customAxios.get(`/api/vnikti-objectmanagement/v1/${tableName}/columns/ByLevel/${guid}/${onlyActive}`)
	},

	getObjectTableData(tableName) {
		return customAxios.get(`/api/vnikti-objectmanagement/v1/${tableName}/columns`)
	},

	getObjectTableDataByNavId(tableName, guid, onlyActive, getPartUrl = "columns", swagger = "objectmanagement") {
		if (tableName === "EnergyPassport") {
			tableName = "docep"
			//меняем ссылку на swagger
			swagger = "energypassport"
		}

		return customAxios.get(`/api/vnikti-${swagger}/v1/${tableName}/${getPartUrl}/${guid}/${onlyActive}`)
	},

	/////////////////////////////////////
	//Получение списка колонок (table struct)
	getTableStruct(tableName) {
		return customAxios.get(`/api/vnikti-objectmanagement/v1/${tableName}/table-struct`)
	},

	/////////////////////////////////////
	//Получение сгруппированных данных
	getGroupedData(tableName, keyFields, groupFields, sorting, filters, complexFilter, onlyActive, countRecords, numberPage, params) {
		const data = {
			keyFields: keyFields,
			groupFields: groupFields,
			sorting: sorting,
			filters: filters,
			complexFilter: complexFilter,
			onlyActive,
			countRecords,
			numberPage,
		}
		return customAxios.post(`/api/vnikti-objectmanagement/v1/${tableName}/table-group`, data, {params})
	},

	///////////////////////////////////
	//добавление строки таблицы Обьекта
	postObjectTableRow(tableName, requestOptions, link, swagger = "objectmanagement") {
		if (tableName === "window") {
			tableName = "buildingWindow"
		}
		if (tableName === "EnergyPassport") {
			tableName = "docep"
			//меняем ссылку на swagger
			swagger = "energypassport"
		}
		if (link) return customAxios.post(link, requestOptions)
		else return customAxios.post(`/api/vnikti-${swagger}/v1/${tableName}`, requestOptions)
	},

	//редактирование строки таблицы Обьекта
	editObjectTableRow(tableName, requestOptions, prefix, swagger = "objectmanagement") {
		if (tableName === "Cars") {
			tableName = "car"
		}
		if (tableName === "window") {
			tableName = "buildingWindow"
		}
		if (tableName === "EnergyPassport") {
			tableName = "docep"
			//меняем ссылку на swagger
			swagger = "energypassport"
		}
		return customAxios.put(`/api/vnikti-${swagger}/v1/${tableName}${prefix ? `/${prefix}` : ""}`, requestOptions)
	},

	// получение данных полей для формы
	getObjectFormInfo(tableName, guid, formType = "form", link, swagger = "objectmanagement") {
		const additionalParam = guid ? `?guid=${guid}` : ""
		if (tableName === "Cars") {
			tableName = "car"
		}
		if (tableName === "window") {
			tableName = "buildingWindow"
		}
		if (tableName === "EnergyPassport") {
			tableName = "docep"
			//меняем ссылку на swagger
			swagger = "energypassport"
		}

		if (link) return customAxios.get(link + "/form")
		else return customAxios.get(`/api/vnikti-${swagger}/v1/${tableName}/${formType}${additionalParam}`)
	},

	//удаление строки таблицы
	deleteObjectTableRow(tableName, guid, swagger = "objectmanagement") {
		return customAxios.delete(`/api/vnikti-${swagger}/v1/${tableName}/${guid}`)
	},

	///////////////////////////////////
	//Управление профилями отображения
	getProfileList(tableName) {
		return customAxios.get(`/api/vnikti-objectmanagement/v1/${tableName}/profiles`)
	},

	getTableProfile(tableName, profileGuid) {
		return customAxios.get(`/api/vnikti-objectmanagement/v1/${tableName}/profile/${profileGuid}`)
	},

	updateTableProfile(tableName, data) {
		return customAxios.put(`/api/vnikti-objectmanagement/v1/${tableName}/profile`, data)
	},

	createTableProfile(tableName, profileName, data, config) {
		return customAxios.post(`/api/vnikti-objectmanagement/v1/${tableName}/profile/${profileName}`, data, config)
	},

	deleteTableProfile(tableName, profileGuid) {
		return customAxios.delete(`/api/vnikti-objectmanagement/v1/${tableName}/profile/${profileGuid}`)
	},
}

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"
import {projectManagementAPI} from "./api"

const initialState = {
	fragmentColumns: [],
	fragments: [],
	activeFragmentGuid: null,
	activeFragmentName: "",
	activeSubTabName: "",
	elementColumns: [],
	elements: [],

	isLoadingFragments: LOADING_STATE_EMPTY,
	isLoadingElements: LOADING_STATE_EMPTY,
}

export const getProjectFragments = createAsyncThunk("fragmentsAndElements/getProjectFragments", async (projectGuid, thunkAPI) => {
	try {
		const response = await projectManagementAPI.getProjectFragments(projectGuid)
		return response.data
	} catch (error) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

export const getFragmentElements = createAsyncThunk("fragmentsAndElements/getFragmentElements", async (activeFragmentGuid, thunkAPI) => {
	try {
		const response = await projectManagementAPI.getElementsByFragment(activeFragmentGuid)
		return response.data
	} catch (error) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

export const getFragmentTechnicalParams = createAsyncThunk("fragmentsAndElements/getFragmentTechnicalParams", async (activeFragmentGuid, thunkAPI)=>{
	try {
		const response = await projectManagementAPI.getFragmentTechnical(activeFragmentGuid)
		return response.data
	}catch (error) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

export const getFragmentOperationalParams = createAsyncThunk("fragmentsAndElements/getFragmentOperationalParams", async (activeFragmentGuid, thunkAPI)=>{
	try {
		const response = await projectManagementAPI.getFragmentOperational(activeFragmentGuid)
		return response.data
	}catch (error) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

const fragmentsAndElementsSlice = createSlice({
	name: "fragmentsAndElements",
	initialState,
	reducers: {
		setActiveFragmentGuid: (state, action) => {
			state.activeFragmentGuid = action.payload.guid
			state.activeFragmentName = action.payload.name
		},
		setActiveSubTableName: (state, action) => {
			state.activeSubTabName = action.payload
		}
	},
	extraReducers: {
		[getProjectFragments.pending]: (state) => {
			state.isLoadingFragments = LOADING_STATE_PENDING
		},
		[getProjectFragments.fulfilled]: (state, action) => {
			state.fragments = action.payload.data
			state.fragmentColumns = action.payload.columns
			state.isLoadingFragments = LOADING_STATE_SUCCEEDED
		},
		[getProjectFragments.rejected]: (state) => {
			state.fragments = []
			state.isLoadingFragments = LOADING_STATE_FAILED
		},
		[getFragmentElements.pending]: (state) => {
			state.isLoadingElements = LOADING_STATE_PENDING
		},
		[getFragmentElements.fulfilled]: (state, action) => {
			state.elements = action.payload.data
			state.elementColumns = action.payload.columns
			state.isLoadingElements = LOADING_STATE_SUCCEEDED
		},
		[getFragmentElements.rejected]: (state) => {
			state.elements = []
			state.isLoadingElements = LOADING_STATE_FAILED
		},
		[getFragmentTechnicalParams.pending]: (state) => {
			state.isLoadingElements = LOADING_STATE_PENDING
		},
		[getFragmentTechnicalParams.fulfilled]: (state, action) => {
			state.elements = action.payload.data
			state.elementColumns = action.payload.columns
			state.isLoadingElements = LOADING_STATE_SUCCEEDED
		},
		[getFragmentTechnicalParams.rejected]: (state) => {
			state.elements = []
			state.isLoadingElements = LOADING_STATE_FAILED
		},
		[getFragmentOperationalParams.pending]: (state) => {
			state.isLoadingElements = LOADING_STATE_PENDING
		},
		[getFragmentOperationalParams.fulfilled]: (state, action) => {
			state.elements = action.payload.data
			state.elementColumns = action.payload.columns
			state.isLoadingElements = LOADING_STATE_SUCCEEDED
		},
		[getFragmentOperationalParams.rejected]: (state) => {
			state.elements = []
			state.isLoadingElements = LOADING_STATE_FAILED
		},
	},
})

export const {setActiveFragmentGuid, setActiveSubTableName} = fragmentsAndElementsSlice.actions
export const fragmentsAndElementsReducer = fragmentsAndElementsSlice.reducer

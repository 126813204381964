import React from "react"
import {Link, NavLink, useNavigate} from "react-router-dom"
import styled from "styled-components"
import {Badge, Col, Dropdown, List, Row, Space} from "antd"
import {LogoutOutlined, UserOutlined} from "@ant-design/icons"
import {useDispatch, useSelector} from "react-redux"
import {setIsLoggedIn, setUser} from "../../redux/authSlice"

// Верхнее меню системы
export const HeaderBar = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	//статус есть ли не прчитаенные оповещения
	const isNotification = useSelector((state) => state.userNotification.isNotification)

	const user = useSelector((state) => state.auth.user)

	//получение статуса прочитанных оповещений
	// useEffect(() => {
	// 	const requestInterval = setInterval(() => dispatch(getIsNotification({})), 30000)
	// 	return () => {
	// 		clearInterval(requestInterval)
	// 	}
	// }, [])

	/**функция при клике на кнопку Выйти из системы*/
	const handleExit = () => {
		localStorage.removeItem("aees_token")
		dispatch(setUser({}))
		dispatch(setIsLoggedIn(false))
		navigate("/login")
	}

	const userMenu = {
		items: [
			{
				label: (
					<NavButton className="logout-button" onClick={handleExit}>
						<LogoutOutlined style={{marginRight: "5px"}} />
						Выйти
					</NavButton>
				),
				key: "3",
			},
		],
	}

	return (
		<Row
			style={{
				borderBottom: "2px solid #1A26FF",
			}}>
			<Col span={24} style={{height: "38px", backgroundColor: "#ffffff"}}>
				<StyledUl>
					<div className="menu-buttons">
						<List.Item>
							<Link to="/projects" className="logo-link" style={{color: "#000000"}}>
								АИСУП ВНИКТИ
							</Link>
						</List.Item>
						<List.Item>
							<NavLink to="/references">Справочники</NavLink>
						</List.Item>
						<List.Item>
							<NavLink to="/digitalProfiles">Цифровые профили</NavLink>
						</List.Item>
						<List.Item>
							<NavLink to="/projects">Управление проектами</NavLink>
						</List.Item>
						<List.Item>
							<NavLink to="/events">Журналы</NavLink>
						</List.Item>
					</div>
					<div className="menu-dropdowns">
						<List.Item>
							<Dropdown menu={userMenu} trigger={["click"]}>
								<div className="dropdown-button">
									<Space size={3}>
										<UserOutlined />
										<Badge dot={isNotification}>{user.firstName ? `${user.firstName} ${user.lastName}` : ""}</Badge>
									</Space>
								</div>
							</Dropdown>
						</List.Item>
					</div>
				</StyledUl>
			</Col>
		</Row>
	)
}

const StyledUl = styled.ul`
	margin: 0;
	padding: 0;
	display: flex;
	justify-content: space-between;
	white-space: nowrap;
	align-items: center;
	.menu-buttons {
		display: flex;
		margin-left: 10px;
	}
	.menu-dropdowns {
		height: 38px;
		display: flex;
		align-items: center;
		margin-right: 10px;
	}
	li {
		list-style-type: none;
		padding: 4px 8px;
	}
	a {
		color: black;
	}
	a:hover {
		color: #808080;
	}
	.active {
		color: #1a26ff;
	}
	.logo-link {
		font-size: 15px;
		letter-spacing: 0.4px;
		color: #fff;
	}
	.dropdown-button {
		color: black;
	}
	.dropdown-button:hover {
		cursor: pointer;
	}
`

const NavButton = styled.div`
	::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		content: "";
	}
`

import {Col, Form, Space, Input, Button} from "antd"
import React, {useEffect, useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {NavigationButton} from "../../../../components/NavigationButton/NavigationButton"
import {cardprojectAPI} from "../../api/cardprojectAPI"
import {setSnackbarNotification} from "../../../../redux/snackbarSlice"

const {TextArea} = Input

// Страница Справочники
export const FinalConclusion = () => {
	const params = useParams()
	const dispatch = useDispatch()

	//заголовок таблицы
	const tableTitle = useSelector((state) => state.projectConclusionNavigation.selectedConclusion.name)

	const [conclusion, setConclusion] = useState("")
	const [isNew, setIsNew] = useState(false)
	const [conclusionGuid, setConclusionGuid] = useState("")

	async function getConclusion(projectGuid) {
		try {
			const response = await cardprojectAPI.getConclusions(projectGuid)
			console.log(response.data.data)
			if (response.data.data.length === 0) {
				setIsNew(true)
			} else {
				setConclusion(response.data.data[0].conclusionText)
				setConclusionGuid(response.data.data[0].guid)
				setIsNew(false)
			}
		} catch (e) {}
	}

	async function postConclusion() {
		if (isNew) {
			try {
				const response = await cardprojectAPI.postConclusion({
					conclusionText: conclusion,
					projectFK: params.guid,
				})
				setIsNew(false)
				dispatch(setSnackbarNotification({message: "Выводы успешно сохранены", type: "success"}))
			} catch (e) {
				dispatch(setSnackbarNotification({message: "Ошибка соединения. Повторите попытку", type: "error"}))
			}
		} else {
			try {
				const response = await cardprojectAPI.putConclusion({
					conclusionText: conclusion,
					projectFK: params.guid,
					guid: conclusionGuid,
				})
				dispatch(setSnackbarNotification({message: "Выводы успешно сохранены", type: "success"}))
			} catch (e) {
				dispatch(setSnackbarNotification({message: "Ошибка соединения. Повторите попытку", type: "error"}))
			}
		}
	}

	function onSubmit() {
		postConclusion()
	}

	useEffect(() => {
		getConclusion(params.guid)
	}, [params.guid])

	return (
		<Col flex="auto" className="mainCol">
			<Space size="middle" style={{marginBottom: "12px"}}>
				<NavigationButton />
			</Space>
			<Form>
				<Form.Item label="Выводы">
					<TextArea rows={10} value={conclusion} onChange={(e) => setConclusion(e.target.value)} />
				</Form.Item>
				<Button type="primary" onClick={onSubmit} className="mainColor addButton">
					Сохранить изменения
				</Button>
			</Form>
		</Col>
	)
}

import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Alert} from "antd"
import styled from "styled-components"
import {clearSnackbarNotification} from "../redux/snackbarSlice"

export const SimpleSnackbar = () => {
	const dispatch = useDispatch()
	let notificationMessage = useSelector((state) => state.notification.notificationMessage)
	let notificationType = useSelector((state) => state.notification.notificationType)
	let isVisible = useSelector((state) => state.notification.isVisible)

	const handleClose = () => {
		dispatch(clearSnackbarNotification())
	}

	setTimeout(() => {
		dispatch(clearSnackbarNotification())
	}, 15000)

	return (
		<>{isVisible ? <StyledAlert message={notificationMessage} type={notificationType} showIcon closable onClose={handleClose} /> : null} </>
	)
}

const StyledAlert = styled(Alert)`
	position: fixed;
	top: 41px;
	right: 0;
	min-width: 300px;
	z-index: 1000;
`

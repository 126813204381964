import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {referencesAPI} from "../pages/References/api/referencesAPI"
import {setSnackbarNotification} from "./snackbarSlice"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../utils/loadingState"

const initialState = {
	tableData: [],
	objectTableName: "",
	tableColumns: [],

	functionalRoles: [], //список функциональных ролей
	checkedListRoles: [],

	sectors: [],
	checkedListSectors: [],

	isLoading: LOADING_STATE_EMPTY, // идет ли запрос за данными для таблицы
	isLoadingFunctionalRoles: LOADING_STATE_EMPTY,
	isLoadingSectors: LOADING_STATE_EMPTY,
	isLoadingPost: LOADING_STATE_EMPTY,

	columnFilterValues: {},
}

// получет данные для отрисовки таблицы в справочнике
export const getReferenceTable = createAsyncThunk("referencesTables/getReferenceTable", async (tableName, thunkAPI) => {
	try {
		const response = await referencesAPI.getReferenceTableData(tableName)
		return {response: response.data, tableName}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

//список функциональных ролей
export const getFunctionalRoles = createAsyncThunk("referencesTables/getFunctionalRoles", async (userGuid, thunkAPI) => {
	try {
		const response1 = await referencesAPI.getFunctionalRoles()
		const response2 = await referencesAPI.getSelectedUserRoles(userGuid)

		return {functionalRoles: response1.data, selectedUserRoles: response2.data}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// добавление пользователю роли
export const postUserNewRole = createAsyncThunk("referencesTables/postUserNewRole", async (parameters, thunkAPI) => {
	try {
		const {userGuid, roleGuid} = parameters
		const response = await referencesAPI.postUserNewRole(userGuid, roleGuid)

		if (response.status === 200) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Роль успешно добавлена", type: "success"}))

			return thunkAPI.fulfillWithValue({
				roleGuid: roleGuid,
			})
		}
	} catch (err) {
		thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка добавления роли. Повторите попытку", type: "error"}))
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

export const deleteUserRole = createAsyncThunk("referencesTables/deleteUserRole", async (parameters, thunkAPI) => {
	try {
		const {userGuid, roleGuid} = parameters
		const response = await referencesAPI.deleteUserRole(userGuid, roleGuid)
		if (response.status === 200) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Роль пользователя удалена", type: "success"}))

			return thunkAPI.fulfillWithValue({
				roleGuid: roleGuid,
			})
		}
	} catch (err) {
		thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка удаления роли. Повторите попытку", type: "error"}))
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

//список секторов
export const getSectors = createAsyncThunk("referencesTables/getSectors", async (userGuid, thunkAPI) => {
	try {
		const response1 = await referencesAPI.getSectors()
		const response2 = await referencesAPI.getSelectedUserSectors(userGuid)

		return {sectors: response1.data, selectedUserSectors: response2.data}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// добавление пользователя в сектор
export const postUserNewSector = createAsyncThunk("referencesTables/postUserNewSector", async (parameters, thunkAPI) => {
	try {
		const {userGuid, sectorGuid} = parameters
		const response = await referencesAPI.postUserNewSector(userGuid, sectorGuid)

		if (response.status === 200) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Сектор успешно добавлен", type: "success"}))

			return thunkAPI.fulfillWithValue({
				sectorGuid: sectorGuid,
			})
		}
	} catch (err) {
		thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка добавления сектора. Повторите попытку", type: "error"}))
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

export const deleteUserSector = createAsyncThunk("referencesTables/deleteUserSector", async (parameters, thunkAPI) => {
	try {
		const {userGuid, sectorGuid} = parameters
		const response = await referencesAPI.deleteUserSector(userGuid, sectorGuid)
		if (response.status === 200) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Сектор у пользователя удален", type: "success"}))

			return thunkAPI.fulfillWithValue({
				sectorGuid: sectorGuid,
			})
		}
	} catch (err) {
		thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка удаления сектора. Повторите попытку", type: "error"}))
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// работа с таблицей на странице Справочники
const referencesTablesSlice = createSlice({
	name: "referencesTables",
	initialState,
	reducers: {
		setObjectTableName: (state, action) => {
			state.objectTableName = action.payload
		},
		updateColumnFilterValues: (state, action) => {
			state.columnFilterValues = action.payload
		},
		clearColumnFilterValues: (state) => {
			state.columnFilterValues = null
		},
		setCheckedListRoles: (state, action) => {
			state.checkedListRoles = action.payload
		},
	},
	extraReducers: {
		[getReferenceTable.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[getReferenceTable.fulfilled]: (state, action) => {
			const {response} = action.payload
			state.tableData = response.data
			state.tableColumns = response.columns.map((i) => ({...i, ellipsis: true, width: 150}))
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getReferenceTable.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
		},
		[getFunctionalRoles.pending]: (state) => {
			state.isLoadingFunctionalRoles = LOADING_STATE_PENDING
		},
		[getFunctionalRoles.fulfilled]: (state, action) => {
			state.functionalRoles = action.payload?.functionalRoles ? action.payload?.functionalRoles : []
			state.checkedListRoles = action.payload?.selectedUserRoles ? action.payload.selectedUserRoles.map((item) => item.guid) : []

			state.isLoadingFunctionalRoles = LOADING_STATE_SUCCEEDED
		},
		[getFunctionalRoles.rejected]: (state) => {
			state.isLoadingFunctionalRoles = LOADING_STATE_FAILED
		},
		[postUserNewRole.pending]: (state) => {
			state.isLoadingPost = LOADING_STATE_PENDING
		},
		[postUserNewRole.rejected]: (state) => {
			state.isLoadingPost = LOADING_STATE_FAILED
		},
		[postUserNewRole.fulfilled]: (state, action) => {
			const {
				payload: {roleGuid},
			} = action

			state.checkedListRoles = [...state.checkedListRoles, roleGuid]

			state.isLoadingPost = LOADING_STATE_SUCCEEDED
		},
		[deleteUserRole.pending]: (state) => {
			state.isLoadingPost = LOADING_STATE_PENDING
		},
		[deleteUserRole.rejected]: (state) => {
			state.isLoadingPost = LOADING_STATE_FAILED
		},
		[deleteUserRole.fulfilled]: (state, action) => {
			const {
				payload: {roleGuid},
			} = action

			state.checkedListRoles = state.checkedListRoles.filter((guid) => guid !== roleGuid)

			state.isLoadingPost = LOADING_STATE_SUCCEEDED
		},
		[getSectors.rejected]: (state) => {
			state.isLoadingSectors = LOADING_STATE_FAILED
		},
		[getSectors.pending]: (state) => {
			state.isLoadingSectors = LOADING_STATE_PENDING
		},
		[getSectors.fulfilled]: (state, action) => {
			state.sectors = action.payload?.sectors ? action.payload?.sectors : []
			state.checkedListSectors = action.payload?.selectedUserSectors ? action.payload.selectedUserSectors.map((item) => item.guid) : []

			state.isLoadingSectors = LOADING_STATE_SUCCEEDED
		},
		[postUserNewSector.pending]: (state) => {
			state.isLoadingPost = LOADING_STATE_PENDING
		},
		[postUserNewSector.rejected]: (state) => {
			state.isLoadingPost = LOADING_STATE_FAILED
		},
		[postUserNewSector.fulfilled]: (state, action) => {
			const {
				payload: {sectorGuid},
			} = action

			state.checkedListSectors = [...state.checkedListSectors, sectorGuid]

			state.isLoadingPost = LOADING_STATE_SUCCEEDED
		},
		[deleteUserSector.pending]: (state) => {
			state.isLoadingPost = LOADING_STATE_PENDING
		},
		[deleteUserSector.rejected]: (state) => {
			state.isLoadingPost = LOADING_STATE_FAILED
		},
		[deleteUserSector.fulfilled]: (state, action) => {
			const {
				payload: {sectorGuid},
			} = action

			state.checkedListSectors = state.checkedListSectors.filter((guid) => guid !== sectorGuid)

			state.isLoadingPost = LOADING_STATE_SUCCEEDED
		},
	},
})

export const referencesTablesReducer = referencesTablesSlice.reducer
export const {setObjectTableName, updateColumnFilterValues, clearColumnFilterValues} = referencesTablesSlice.actions

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {cardprojectAPI} from "../pages/CardProjects/api/cardprojectAPI";

const initialState = {
    tableData: [],
    objectTableName: "",
    tableColumns: [],
    isLoading: "idle", // идет ли запрос за данными для таблицы

    isLoadingPost: "idle",

    columnFilterValues: {},
}

// получет данные для отрисовки таблицы в справочнике
export const  getCardProjectTableId = createAsyncThunk("cardProjectTable/getCardProjectTableId", async (navID, thunkAPI) => {
    try {
        const response = await cardprojectAPI.getCardProjectTableId(navID)
        return {response: response.data}
    } catch (err) {
        return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
    }
})

// работа с таблицей на странице Цифрового профиля
const cardProjectsTablesSlice = createSlice({
    name: "cardProjectsTables",
    initialState,
    reducers: {
        setObjectTableName: (state, action) => {
            state.objectTableName = action.payload
        },
        updateColumnFilterValues: (state, action) => {
            state.columnFilterValues = action.payload
        },
        clearColumnFilterValues: (state) => {
            state.columnFilterValues = null
        },
    },
    extraReducers: {
        [getCardProjectTableId.pending]: (state) => {
            state.isLoading = "pending"
        },
        [getCardProjectTableId.fulfilled]: (state, action) => {
            const {response} = action.payload
            state.tableData = response.data
            state.tableColumns = response.columns.map((i) => ({...i, ellipsis: true, width: 150}))
            state.isLoading = "succeeded"
        },
        [getCardProjectTableId.rejected]: (state) => {
            state.isLoading = "failed"
        },
    },
})

export const cardProjectsTablesReducer = cardProjectsTablesSlice.reducer
export const {setObjectTableName, updateColumnFilterValues, clearColumnFilterValues} =  cardProjectsTablesSlice.actions

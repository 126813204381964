import {Breadcrumb} from "antd"
import React from "react"
import styled from "styled-components"
import {useDispatch, useSelector} from "react-redux"
import {Link, useNavigate} from "react-router-dom"
import {setDigitalProfilesBreadcrumbs, setSelectedDigitalProfile} from "../../redux/digitalProfilesNavigationSlice";

//хлебные крошки на странице Цифровые профили
export const DigitalProfilesBreadcrumbs = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const breadcrumbsArray = useSelector((state) => state.digitalProfilesNavigation.breadcrumbsArray)
	const selectedDigitalProfileGuid = useSelector((state) => state.digitalProfilesNavigation.selectedDigitalProfileID)

	// выбирает справочник, перерисовывает крошки
	const onBreadcrumbClick = (selectedDigitalProfile) => {
		if (selectedDigitalProfileGuid !== selectedDigitalProfile.guid) {
			dispatch(setSelectedDigitalProfile(selectedDigitalProfile))
			dispatch(setDigitalProfilesBreadcrumbs(selectedDigitalProfile.guid))
			if (selectedDigitalProfile.guid) {
				navigate(`/digitalprofiles/columns/${selectedDigitalProfile.guid}`)
			} else {
				navigate(`/digitalprofiles`)
			}
		}
	}
	const onMainBreadcrumbClick = () => {
	}

	return (
		<BreadcrumbsStyled>
			<Breadcrumb.Item key="main" onClick={onMainBreadcrumbClick}>
				<Link to="/" className={"main-link text"}>
					Главная
				</Link>
			</Breadcrumb.Item>
			{breadcrumbsArray.map((item, index) => (
				<Breadcrumb.Item
					key={index}
					className={item.guid !== selectedDigitalProfileGuid ? "link text" : "text"}
					onClick={() => onBreadcrumbClick(item)}>
					{item.name}
				</Breadcrumb.Item>
			))}
		</BreadcrumbsStyled>
	)
}

const BreadcrumbsStyled = styled(Breadcrumb)`
	.main-link:hover {
		color: rgba(0, 0, 0, 0.45);
	}
	.link:hover {
		cursor: pointer;
	}
	.text {
		font-size: 12px;
	}
`

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {mainAPI} from "../pages/Main/api/mainAPI"
import {referencesAPI} from "../pages/References/api/referencesAPI"
import {setSnackbarNotification} from "./snackbarSlice"
import {makeNavigationTree} from "../utils/makeNavigationTree"
import {digitalProfilesAPI} from "../pages/DigitalProfile/api/digitalProfilesAPI"
import {projectsAPI} from "../pages/Project/api/projectsAPI"

import {projectlogAPI} from "../pages/ProjectLogs/api/projectlogAPI"
import {projectManagementAPI} from "./projectManagementModule/api"

const initialState = {
	modalDataList: [],
	currentModalId: null,
	// tree: [],
	tree: [{guid: "", name: "", level: "", type: "", parentGuid: "", formTabIndex: "", children: []}],
	isLoadingIdle: "idle",
}

export const modalDataInitialState = {
	modalId: null,
	formInfo: [],
	selectListOptions: [],
	tabs: [],
	prefix: "",
	isLocked: false,
	selectedTab: "",
	isLoading: false,
}

export const addItem = createAsyncThunk("modalEditor/addItem", async (parameters, thunkAPI) => {
	try {
		let {entityName, values, isReference, isProjects, isProjectLog, isDigitalProfile, cellData, link} = parameters //

		const response = isReference
			? await referencesAPI.postReferenceTableRow(entityName, values)
			: isDigitalProfile
			? await digitalProfilesAPI.postDigitalProfilesTableRow(entityName, values)
			: isProjects
			? await projectsAPI.postProjectsTableRow(entityName, values)
			: isProjectLog
			? await projectlogAPI.postProjectLogTableRow(entityName, values) //
			: entityName === "Reports"
			? await mainAPI.postReportTableRow(entityName, values, cellData)
			: await mainAPI.postObjectTableRow(entityName, values, link)

		if (response.status === 200) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Данные добавлены", type: "success"}))

			return thunkAPI.fulfillWithValue({
				...response.data,
			})
		}
	} catch (err) {
		if (err.response.status === 400) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Одно из полей заполнено неверно", type: "error"}))
		} else {
			thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка соединения. Повторите попытку", type: "error"}))
		}
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

export const editItem = createAsyncThunk("modalEditor/editItem", async (parameters, thunkAPI) => {
	try {
		const {entityName, values, isReference, isProjectLog, isDigitalProfile, isProjects, prefix} = parameters

		const response = isProjects
			? await projectsAPI.editProjectsTableRow(entityName, values)
			: isProjectLog
			? await projectlogAPI.editProjectLogTableRow(entityName, values)
			: isReference
			? await referencesAPI.editReferenceTableRow(entityName, values)
			: isDigitalProfile
			? await digitalProfilesAPI.editDigitalProfileRow(values)
			: await mainAPI.editObjectTableRow(entityName, values, prefix)

		if (response.status === 200) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Данные обновлены успешно", type: "success"}))

			return thunkAPI.fulfillWithValue({
				...response.data,
			})
		}
	} catch (err) {
		if (err.response.status === 400) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Одно из полей заполнено неверно", type: "error"}))
		} else {
			thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка соединения. Повторите попытку", type: "error"}))
		}

		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

export const deleteItem = createAsyncThunk("modalEditor/deleteItem", async (parameters, thunkAPI) => {
	try {
		const {entityName, entityId, isProjectLog, isReference, isProjects, isDigitalProfile} = parameters

		const response = isProjects
			? await projectsAPI.deleteProjectsTableRow(entityName, entityId)
			: isProjectLog
			? await projectlogAPI.deleteProjectLogTableRow(entityName, entityId)
			: isReference
			? await referencesAPI.deleteReferenceTableRow(entityName, entityId)
			: isDigitalProfile
			? await digitalProfilesAPI.deleteDigitalProfileTableRow(entityId)
			: await mainAPI.deleteObjectTableRow(entityName, entityId)

		if (response.status === 200) {
			thunkAPI.dispatch(setSnackbarNotification({message: "Данные удалены", type: "success"}))

			return thunkAPI.fulfillWithValue(entityId)
		}
	} catch (err) {
		thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка соединения. Повторите попытку", type: "error"}))
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// получет данные о полях справочника для отрисовки формы создания/редактирования
export const getFormInfo = createAsyncThunk("modalEditor/getFormInfo", async (parameters, thunkAPI) => {
	try {
		const {
			entityName,
			isProjectLog,
			isReference,
			isProjects,
			isDigitalProfile,
			entityId,
			afterGetFormInfoFunc,
			modalId,
			cellData,
			link,
			useLink,
		} = parameters

		const response = isProjects
			? link && (!entityId || useLink)
				? await projectManagementAPI.getLinkData(link, entityId)
				: await projectsAPI.getProjectsFormInfo(entityName, entityId)
			: isProjectLog
			? await projectlogAPI.getProjectLogFormInfo(entityName, entityId)
			: isReference
			? await referencesAPI.getReferenceFormInfo(entityName, entityId)
			: isDigitalProfile
			? await digitalProfilesAPI.getDigitalProfileFormInfo(entityName, entityId)
			: entityName === "Reports"
			? await mainAPI.getReportFormInfo(cellData)
			: await mainAPI.getObjectFormInfo(entityName, entityId, "form", link)

		if (afterGetFormInfoFunc) {
			return {modalId: modalId, data: {...afterGetFormInfoFunc(response.data)}}
		}

		return {modalId: modalId, data: response.data}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

export const getFormTabsInfo = createAsyncThunk("modalEditor/getFormTabsInfo", async (parameters, thunkAPI) => {
	try {
		const {modalId} = parameters
		const response = await mainAPI.getNavigationTree()

		return {response: response.data, modalId: modalId}
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// получет значения для выпадающих списков в форме
export const getOptions = createAsyncThunk("modalEditor/getOptions", async (selectFields, thunkAPI) => {
	try {
		const requests = selectFields.map((item) => {
			let link = item.link
			if (item.link === "/api/vnikti-references/v1/worktypes") {
				link = "/api/vnikti-references/v1/worktypes"
			}
			if (item.link === "/api/vnikti-journals/v1/user") {
				if (item.dataIndex === "executorFK") {
					link = "/api/vnikti-projects/v1/users/executors"
				} else if (item.dataIndex === "responsibleFK") {
					link = "/api/vnikti-projects/v1/users"
				}
			}
			if (item.link === "api/vnikti-project/v1/projects") {
				link = "/api/vnikti-projects/v1/projects"
			}

			return referencesAPI.getReferenceOptions(link).then((res) => {
				return {
					dataIndex: item.dataIndex,
					options: res,
				}
			})
		})
		const responses = await Promise.all(requests)

		return responses
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

export const getFileEntity = createAsyncThunk("objectTable/getFileEntity", async (parameters, thunkAPI) => {
	try {
		const {entityId} = parameters
		const response = await projectManagementAPI.getFileEntity(entityId)
		return response.data
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

const modalEditorSlice = createSlice({
	name: "modalEditorSlice",
	initialState: initialState,
	reducers: {
		openNewModal: (state, action) => {
			const newModalData = {...modalDataInitialState, modalId: action.payload}
			state.modalDataList.push(newModalData)
			state.currentModalId = action.payload
		},
		closeModal: (state) => {
			state.currentModalId = null
			state.modalDataList.pop()
			if (state.modalDataList.length > 0) {
				state.currentModalId = state.modalDataList[0].modalId
			}
		},
		setSelectedTab: (state, action) => {
			const currentModal = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModal) {
				currentModal.selectedTab = action.payload
			}
		},
		clearState: (state) => {
			const currentModal = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModal) {
				currentModal = {...modalDataInitialState}
			}
		},
		updateSelectedOption: (state, action) =>{
			const currentModal = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModal){
				const listToUpdate = currentModal.selectListOptions.find((x)=>x.dataIndex===action.payload.dataIndex)
				listToUpdate.options = action.payload.newOptions
				// console.log(currentModal.formInfo);
				// const fieldToMakeAble = currentModal.formInfo.find((x)=>x.dataIndex===action.payload.dataIndex)
				// fieldToMakeAble.disabled = false
			}
		}
	},
	extraReducers: {
		[getFormInfo.pending]: (state) => {
			const currentModalData = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModalData) {
				currentModalData.isLoading = true
			}
		},
		[getFormInfo.fulfilled]: (state, action) => {
			const currentModalData = state.modalDataList.find((x) => x.modalId === action.payload.modalId)
			if (currentModalData) {
				if (action.payload.data.tabs) {
					currentModalData.formInfo = action.payload.data.data
					currentModalData.tabs = action.payload.data.tabs
					currentModalData.selectedTab = action.payload.data.tabs[0]?.index
					currentModalData.prefix = action.payload.data.prefix
					currentModalData.isLocked = action.payload.isLocked
				} else {
					currentModalData.formInfo = action.payload.data
					currentModalData.tabs = null
					currentModalData.selectedTab = null
					currentModalData.prefix = action.payload.data.prefix
					currentModalData.isLocked = action.payload.isLocked
				}
				currentModalData.isLoading = false
			}
		},
		[getFormInfo.rejected]: (state) => {
			const currentModalData = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModalData) {
				currentModalData.isLoading = false
			}
		},

		[getFormTabsInfo.pending]: (state) => {
			state.isLoading = true
		},
		[getFormTabsInfo.fulfilled]: (state, action) => {
			const tree = makeNavigationTree(action.payload.response)[0]
			tree.formTabIndex = "perimeter"
			state.tree = [tree]
			state.isLoading = false
		},
		[getFormTabsInfo.rejected]: (state) => {
			state.isLoading = false
		},

		[getOptions.pending]: (state) => {
			const currentModalData = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModalData) {
				currentModalData.isLoading = true
			}
		},
		[getOptions.fulfilled]: (state, action) => {
			const currentModalData = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModalData) {
				if (action.payload.length === 1 && currentModalData.selectListOptions.length!==0) {
					const updatedListIndex = currentModalData.selectListOptions.findIndex((list) => list.dataIndex === action.payload[0].dataIndex)
					currentModalData.selectListOptions[updatedListIndex] = action.payload[0]
				} else {
					currentModalData.selectListOptions = action.payload
				}
				currentModalData.isLoading = false
			}
		},
		[getOptions.rejected]: (state) => {
			const currentModalData = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModalData) {
				currentModalData.isLoading = false
			}
		},

		[addItem.pending]: (state) => {
			state.isLoading = true
			state.isLoadingIdle = "pending"
		},
		[addItem.fulfilled]: (state) => {
			state.isLoading = false
			state.isLoadingIdle = "succeeded"

			state.formInfo = []
			state.tabs = []
			state.prefix = []
			state.isLocked = false
			state.selectedTab = ""
			state.options = []
			const currentModalData = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModalData) {
				currentModalData.isLoading = true
			}
		},
		[addItem.rejected]: (state) => {
			state.isLoadingIdle = "failed"
			const currentModalData = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModalData) {
				currentModalData.isLoading = false
			}
		},

		[editItem.pending]: (state) => {
			const currentModalData = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModalData) {
				currentModalData.isLoading = true
			}
			state.isLoading = true
		},
		[editItem.fulfilled]: (state) => {
			state.isLoading = false

			state.formInfo = []
			state.tabs = []
			state.prefix = []
			state.isLocked = false
			state.selectedTab = ""
			state.options = []
		},
		[editItem.rejected]: (state) => {
			const currentModalData = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModalData) {
				currentModalData.isLoading = false
			}
		},

		[deleteItem.pending]: (state) => {
			const currentModalData = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModalData) {
				currentModalData.isLoading = true
			}
			state.isLoading = true
		},
		[deleteItem.fulfilled]: (state) => {
			state.isLoading = false

			state.formInfo = []
			state.tabs = []
			state.prefix = []
			state.isLocked = false
			state.selectedTab = ""
			state.options = []
		},
		[deleteItem.rejected]: (state) => {
			const currentModalData = state.modalDataList.find((x) => x.modalId === state.currentModalId)
			if (currentModalData) {
				currentModalData.isLoading = false
			}
		},
	},
})

export const {setSelectedTab, openNewModal, closeModal, updateSelectedOption} = modalEditorSlice.actions
export const modalEditorReducer = modalEditorSlice.reducer

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"
import { projectManagementAPI } from "./api"

const initialState = {
	guid: null,
	name: null,
	activeTabId: "basicInformation",

	isLoading: LOADING_STATE_EMPTY,
}

export const getProjectInfo = createAsyncThunk("projectCard/getProjectInfo", async (projectGuid, thunkAPI) =>{
    try{
        const response = await projectManagementAPI.getProjectInfo(projectGuid)
		return response.data
    }
    catch (error) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
    }
})

const projectCardSlice = createSlice({
	name: "projectCard",
	initialState,
	reducers: {
		setProjectCardActiveTab: (state, action) => {
			state.activeTabId = action.payload
		},
		setProjectCardGuid: (state, action) => {
			state.guid = action.payload
		},
	},
	extraReducers:{
		[getProjectInfo.pending]: (state) =>{
			state.isLoading = LOADING_STATE_PENDING
		},
		[getProjectInfo.fulfilled]: (state, action) =>{
			state.name = action.payload.name
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getProjectInfo.rejected]: (state)=>{
			state.isLoading = LOADING_STATE_FAILED
		}
	}
})

export const {setProjectCardActiveTab, setProjectCardGuid} = projectCardSlice.actions
export const projectCardReducer = projectCardSlice.reducer

import customAxios from "../../../api/customAxios"


//кэш запросов элементов для выпадающих списков
const optionsCacheMap = new Map()

// методы страницы справочников
export const projectlogAPI = {

    getProjectLogBaseUrl() {
        return "/api/vnikti-journals/v1"
    },
    getProjectLogNavigationArray() {
        return customAxios.get("/api/vnikti-journals/v1/journalnavigation")
    },
    getProjectLogTableData(tableName) {
        return customAxios.get(`/api/vnikti-journals/v1/${tableName}/columns`)
    },
    getProjectLogFormInfo(tableName, guid) {
        const additionalParam = guid ? `?guid=${guid}` : ""
        return customAxios.get(`/api/vnikti-journals/v1/${tableName}/form${additionalParam}`)
    },
    getPerimeterAcces(userGuid) {
        return customAxios.get(`/api/vnikti-journals/v1/access-perimeter/${userGuid}`)
    },
    setPerimeterAcces(guidArr, userGuid) {
        return customAxios.post(`/api/vnikti-journals/v1/access-perimeter?userGuid=${userGuid}`, guidArr)
    },
    deletePerimeterAcces(guidArr, userGuid) {
        return customAxios.delete(`/api/vnikti-journals/v1/access-perimeter?userGuid=${userGuid}`, {data: [guidArr]})
    },
    async getProjectLogOptions(url) {
        if (!optionsCacheMap.has(url)) {
            try {
                const response = await customAxios.get(url)
                optionsCacheMap.set(url, response.data)
            } catch (error) {
                return []
            }
        }

        return optionsCacheMap.get(url)
    },

    //добавление строки таблицы
    postProjectLogTableRow(tableName, requestOptions) {
        return customAxios.post(`/api/vnikti-journals/v1/${tableName}`, requestOptions)
    },

    //редактирование строки таблицы
    editProjectLogTableRow(tableName, requestOptions) {
        return customAxios.put(`/api/vnikti-journals/v1/${tableName}`, requestOptions)
    },

    //удаление строки таблицы
    deleteProjectLogTableRow(tableName, guid) {
        return customAxios.delete(`/api/vnikti-journals/v1/${tableName}/${guid}`)
    },

    //список функциональных ролей
    getFunctionalRoles() {
        return customAxios.get(`/api/vnikti-journals/v1/functional-role`)
    },

    //список ролей, которые принадлежат пользователю
    getSelectedUserRoles(userGuid) {
        return customAxios.get(`/api/vnikti-journals/v1/functional-role/user/${userGuid}`)
    },

    // добавление пользователю роли
    postUserNewRole(userGuid, roleGuid) {
        return customAxios.post(`/api/vnikti-journals/v1/functional-role/add-to-user?userGuid=${userGuid}&roleGuid=${roleGuid}`)
    },

    //удаление роли пользователя
    deleteUserRole(userGuid, roleGuid) {
        return customAxios.delete(
            `/api/vnikti-journals/v1/functional-role/remove-from-user?userGuid=${userGuid}&roleGuid=${roleGuid}`
        )
    },
}
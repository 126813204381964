import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NavigationTree} from "../../../../components/NavigationTree/NavigationTree"
import {useNavigate, useParams} from "react-router-dom"
import {getResultsTreeData, setResultsBreadcrumbs, setSelectedResult} from "../../../../redux/projectManagementModule/resultsNavigationSlice"
import { getNavigationTreeNodeByGuid } from "../../../../utils/getNavigationTreeNodeByGuid"

// Боковая часть с навигацией на странице Проект.Результаты
export const ResultsNavigation = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const params = useParams()

	const isLoading = useSelector((state) => state.projectResultsNavigation.isLoading)
	const treeData = useSelector((state) => state.projectResultsNavigation.treeData)
	const selectedResultGuid = useSelector((state) => state.projectResultsNavigation.selectedResult.guid)

	// выбирает работу
	const onChange = (newValue, selectedResult) => {
		if (selectedResult.parentGuid === null) {
			dispatch(setSelectedResult({guid: selectedResult.guid, name: selectedResult.name}))
			dispatch(setResultsBreadcrumbs(newValue))
			navigate(`./`)
		} else {
			dispatch(setSelectedResult({guid: selectedResult.guid, name: selectedResult.name}))
			dispatch(setResultsBreadcrumbs(newValue))
			navigate(`${selectedResult.guid}`)
		}
	}

	useEffect(() => {
		dispatch(getResultsTreeData(params.guid))
	}, [])

	useEffect(() => {
		if (treeData.length !== 0) {
			const referenceTreeNode = getNavigationTreeNodeByGuid(treeData, params.workType)
			if (referenceTreeNode) onChange(referenceTreeNode.guid, referenceTreeNode)
		}
	}, [params.workType, treeData])

	return <NavigationTree treeData={treeData} value={selectedResultGuid} onChange={onChange} isLoading={isLoading} />
}

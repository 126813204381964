import {Col, Space} from "antd"
import React from "react"
import {DigitalProfilesBreadcrumbs} from "./DigitalProfilesBreadcrumbs"
import {DigitalProfilesTable} from "./DigitalProfilesTable"
import {useParams} from "react-router-dom"
import {useSelector} from "react-redux"
import {NavigationButton} from "../../components/NavigationButton/NavigationButton"

// Страница Цифрово профиль
export const DigitalProfiles = () => {
	const params = useParams()

	//заголовок таблицы
	const tableTitle = useSelector((state) => state.digitalProfilesNavigation.selectedDigitalProfile.name)

	return (
		<Col flex="auto" style={{height: "calc(100vh - 40px - 24px)", backgroundColor: "#fff", margin: "12px"}}>
			<Space size="middle" style={{marginBottom: "12px"}}>
				<NavigationButton />
			</Space>
			<DigitalProfilesTable tableName={params.tableName} tableTitle={tableTitle} />
		</Col>
	)
}

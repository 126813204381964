import {Button} from "antd"
import React from "react"
import PropTypes from "prop-types"
import {cardprojectAPI} from "../../api/cardprojectAPI"
import {useSelector} from "react-redux";

export const GetTechnicalReportButton = ({projectGuid}) => {
	const isReportable = useSelector((state) => state.projectFinalReportFilesTable.isReportable)
	function handleAddButtonClick() {
		cardprojectAPI.getTechnicalReport(projectGuid)
	}

	return (
		<>
			<Button
				type="primary"
				onClick={() => {
					handleAddButtonClick()
				}}
				disabled={!isReportable}
				className="mainColor addButton">
				{`Сформировать технический отчет`}
			</Button>
		</>
	)
}

GetTechnicalReportButton.propTypes = {
	projectGuid: PropTypes.string,
}
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {makeNavigationTree} from "../../utils/makeNavigationTree"
import {projectManagementAPI} from "./api"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"

const initialState = {
	initialData: [], // данные о структуре справочников полученные с сервера
	treeData: [], // данные для treeSelect (древовидная структура справочников)
	selectedConclusion: {guid: "", name: ""}, // выбранный справочник
	isLoading: LOADING_STATE_EMPTY, // идет ли запрос
}

// получет массив объектов для навигации
export const getConclusionTreeData = createAsyncThunk("conclusionNavigation/getConclusionTreeData", async (params, thunkAPI) => {
	try {
		const response = await projectManagementAPI.getConclusionNavigationArray()
		return response.data
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// tree select Навигация на странице заключения
const conclusionNavigationSlice = createSlice({
	name: "conclusionNavigation",
	initialState,
	reducers: {
		setSelectedConclusion: (state, action) => {
			state.selectedConclusion = action.payload
		},
	},
	extraReducers: {
		[getConclusionTreeData.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[getConclusionTreeData.fulfilled]: (state, action) => {
			state.initialData = action.payload
			let treeData = makeNavigationTree(action.payload)
			state.treeData = treeData
			state.selectedConclusion.guid = treeData[0].guid
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getConclusionTreeData.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
		},
	},
})
export const {setSelectedConclusion} = conclusionNavigationSlice.actions
export const conclusionNavigationReducer = conclusionNavigationSlice.reducer

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {projectlogAPI} from "../pages/ProjectLogs/api/projectlogAPI"
import {setSnackbarNotification} from "./snackbarSlice";
import {ProjectLogsNavigation} from "../pages/ProjectLogs/ProjectLogsNavigation";


const initialState = {
    tableData: [],
    objectTableName: "",
    tableColumns: [],
    functionalRoles: [], //список функциональных ролей

    checkedListRoles: [],

    isLoading: "idle", // идет ли запрос за данными для таблицы
    isLoadingFunctionalRoles: "idle",
    isLoadingPost: "idle",

    columnFilterValues: {},
}

// получет данные для отрисовки таблицы в справочнике
export const getProjectLogTable = createAsyncThunk("ProjectLogTables/getProjectLogTable", async (tableName, thunkAPI) => {
    try {
        const response = await projectlogAPI.getProjectLogTableData(tableName)
        return {response: response.data}
    } catch (err) {
        return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
    }
})

//список функциональных ролей
export const getFunctionalRoles = createAsyncThunk("ProjectLogTables/getFunctionalRoles", async (userGuid, thunkAPI) => {
    try {
        const response1 = await projectlogAPI.getFunctionalRoles()
        const response2 = await projectlogAPI.getSelectedUserRoles(userGuid)

        return {functionalRoles: response1.data, selectedUserRoles: response2.data}
    } catch (err) {
        return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
    }
})

// добавление пользователю роли
export const postUserNewRole = createAsyncThunk("ProjectLogTables/postUserNewRole", async (parameters, thunkAPI) => {
    try {
        const {userGuid, roleGuid} = parameters
        const response = await projectlogAPI.postUserNewRole(userGuid, roleGuid)

        if (response.status === 200) {
            thunkAPI.dispatch(setSnackbarNotification({message: "Роль успешно добавлена", type: "success"}))

            return thunkAPI.fulfillWithValue({
                roleGuid: roleGuid,
            })
        }
    } catch (err) {
        thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка добавления роли. Повторите попытку", type: "error"}))
        return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
    }
})

export const deleteUserRole = createAsyncThunk("ProjectLogTables/deleteUserRole", async (parameters, thunkAPI) => {
    try {
        const {userGuid, roleGuid} = parameters
        const response = await projectlogAPI.deleteUserRole(userGuid, roleGuid)
        if (response.status === 200) {
            thunkAPI.dispatch(setSnackbarNotification({message: "Роль пользователя удалена", type: "success"}))

            return thunkAPI.fulfillWithValue({
                roleGuid: roleGuid,
            })
        }
    } catch (err) {
        thunkAPI.dispatch(setSnackbarNotification({message: "Ошибка удаления роли. Повторите попытку", type: "error"}))
        return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
    }
})

// работа с таблицей на странице Справочники
const ProjectLogTablesSlice = createSlice({
    name: "ProjectLogTables",
    initialState,
    reducers: {
        setObjectTableName: (state, action) => {
            state.objectTableName = action.payload
        },
        updateColumnFilterValues: (state, action) => {
            state.columnFilterValues = action.payload
        },
        clearColumnFilterValues: (state) => {
            state.columnFilterValues = null
        },
        setCheckedListRoles: (state, action) => {
            state.checkedListRoles = action.payload
        },
    },
    extraReducers: {
        [getProjectLogTable.pending]: (state) => {
            state.isLoading = "pending"
        },
        [getProjectLogTable.fulfilled]: (state, action) => {
            const {response} = action.payload
            state.tableData = response.data
            state.tableColumns = response.columns.map((i) => ({...i, ellipsis: true, width: 150}))
            state.isLoading = "succeeded"
        },
        [getProjectLogTable.rejected]: (state) => {
            state.isLoading = "failed"
        },

        [getFunctionalRoles.pending]: (state) => {
            state.isLoadingFunctionalRoles = "pending"
        },
        [getFunctionalRoles.fulfilled]: (state, action) => {
            state.functionalRoles = action.payload?.functionalRoles ? action.payload?.functionalRoles : []
            state.checkedListRoles = action.payload?.selectedUserRoles ? action.payload.selectedUserRoles.map((item) => item.guid) : []

            state.isLoadingFunctionalRoles = "succeeded"
        },
        [getFunctionalRoles.rejected]: (state) => {
            state.isLoadingFunctionalRoles = "failed"
        },
        [postUserNewRole.pending]: (state) => {
            state.isLoadingPost = "pending"
        },
        [postUserNewRole.rejected]: (state) => {
            state.isLoadingPost = "failed"
        },
        [postUserNewRole.fulfilled]: (state, action) => {
            const {
                payload: {roleGuid},
            } = action

            state.checkedListRoles = [...state.checkedListRoles, roleGuid]

            state.isLoadingPost = "succeeded"
        },
        [deleteUserRole.pending]: (state) => {
            state.isLoadingPost = "pending"
        },
        [deleteUserRole.rejected]: (state) => {
            state.isLoadingPost = "failed"
        },
        [deleteUserRole.fulfilled]: (state, action) => {
            const {
                payload: {roleGuid},
            } = action

            state.checkedListRoles = state.checkedListRoles.filter((guid) => guid !== roleGuid)

            state.isLoadingPost = "succeeded"
        },
    },
})

export const ProjectLogTablesReducer = ProjectLogTablesSlice.reducer
export const {setObjectTableName, updateColumnFilterValues, clearColumnFilterValues} = ProjectLogTablesSlice.actions
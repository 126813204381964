import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"
import {NavigationTree} from "../../components/NavigationTree/NavigationTree"
import {useNavigate} from "react-router-dom"
import {
	getDigitalProfilesTreeData,
	setDigitalProfilesBreadcrumbs,
	setSelectedDigitalProfile
} from "../../redux/digitalProfilesNavigationSlice";

// Боковая часть с навигацией на странице Справочников
export const DigitalProfilesNavigation = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const isLoading = useSelector((state) => state.digitalProfilesNavigation.isLoading)
	const treeData = useSelector((state) => state.digitalProfilesNavigation.treeData)
	const selectedDigitalProfileGuid = useSelector((state) => state.digitalProfilesNavigation.selectedDigitalProfile.guid)

	// выбирает справочник, перерисовывает хлебные крошки
	const onChange = (selectedDigitalProfileGuid, selectedDigitalProfile) => {
		dispatch(setSelectedDigitalProfile({guid: selectedDigitalProfile.guid, name: selectedDigitalProfile.name}))
		navigate(`/digitalprofiles/${selectedDigitalProfileGuid}`)
	}

	useEffect(() => {
		dispatch(getDigitalProfilesTreeData())
	}, [])

	return <NavigationTree treeData={treeData} value={selectedDigitalProfileGuid} onChange={onChange} isLoading={isLoading} />
}

import React, {useEffect, useState, useMemo} from "react"
import PropTypes from "prop-types"
import {Typography, Button, Space, Checkbox} from "antd"
import {Form, Input} from "antd"
import {getReportTable, setTableValueType} from "../../../../redux/projectManagementModule/reportTableSlice"
import {useDispatch, useSelector} from "react-redux"
import {DownloadOutlined} from "@ant-design/icons"
import {ExportExcel} from "../../../../components/ExportExcel"
import {LOADING_STATE_PENDING} from "../../../../utils/loadingState"
import {BaseTable} from "../../../../components/BaseTable/baseTable"
import {Spinner} from "../../../../components/Spinner/spinner"
import {ModalEditor} from "../../../../components/ModalEditor/ModalEditor"
import {defaultColumnParams} from "../../../../components/BaseTable/defaultColumnParams"
import {StyledTabs} from "../../../../components/StyledTabs"
import {cardprojectAPI} from "../../api/cardprojectAPI"
import {useParams} from "react-router-dom"
import {getFileEntity} from "../../../../redux/modalEditorSlice"
import {projectManagementAPI} from "../../../../redux/projectManagementModule/api"

const {TextArea} = Input

// таблица
export const ReportTable = ({tableName, tableTitle, isWork}) => {
	const dispatch = useDispatch()
	const params = useParams()

	//колонки таблицы
	const tableColumns = useSelector((state) => state.projectReportTables.tableColumns)
	//данные таблицы
	const tableData = useSelector((state) => state.projectReportTables.tableData)
	// идет ли запрос на бэк за данными для таблицы
	const isLoading = useSelector((state) => state.projectReportTables.isLoading)

	const projectGuid = useSelector((state) => state.projectCard.guid)

	const columnFilterValues = useSelector((state) => state.projectReportTables.columnFilterValues)

	const valueType = useSelector((state) => state.projectReportTables.valueType)

	const breadcrumbs = useSelector((state) => state.projectReportNavigation.breadcrumbsArray)

	const [selectedId, setSelectedId] = useState("")
	const [showModalEditor, setShowModalEditor] = useState(false)
	const [interpretation, setInterpretation] = useState("")
	const [interpretationGuid, setInterpretationGuid] = useState("")
	const [isNewInterpretation, setIsNewInterpretation] = useState(false)

	const loadData = () => {
		dispatch(getReportTable({workCategory: tableName, projectGuid: projectGuid, valueType: valueType}))
	}

	const handleSuccess = () => {
		setSelectedId("")
		setShowModalEditor(false)
		loadData()
	}

	const handleCancel = () => {
		setSelectedId("")
		setShowModalEditor(false)
	}

	async function handleBoolChange(record) {
		try {
			let newState = 0
			if (!record.inReport) {
				newState = 1
			}
			const response = await cardprojectAPI.postNewResultsStatus(valueType, record.guid, newState)
			loadData()
		} catch (e) {
			console.warn(e)
		}
	}

	const imageCellRender = (text, record) => {
		return (
			<a href={record.filePath} target="_blank">
				{record.filePath}
			</a>
		)
	}

	const boolCellRenderer = (text, record) => {
		return <Checkbox defaultChecked={record.inReport} onClick={() => handleBoolChange(record)} />
	}

	const handleDownloadFile = async (recordGuid) => {
		const res = await dispatch(getFileEntity({entityId: recordGuid})).unwrap()
		projectManagementAPI.getFile(res.fileGuid)
	}

	const fileDownload = (text, record) => {
		return (
			<Button
				type="link"
				icon={<DownloadOutlined />}
				onClick={() => {
					handleDownloadFile(record.guid)
				}}>
				{text}
			</Button>
		)
	}

	const mappedColumns = useMemo(() => {
		//  сортировщик (для чисел и строк)
		const sorter = (a, b) => (isNaN(a) && isNaN(b) ? (a || "").localeCompare(b || "") : a - b)

		return tableColumns.map((x) => {
			const column = {
				...x,
				...defaultColumnParams,
				columnTitle: x.title,
				sorter: (a, b) => sorter(a[x.dataIndex], b[x.dataIndex]),
			}

			if (x.dataIndex === "filePath") {
				return {...column, width: 300, render: imageCellRender}
			}
			if (x.dataIndex === "inReport") {
				return {...x, render: boolCellRenderer}
			}
			if (x.dataIndex === "fileName") {
				return {...column, render: fileDownload}
			}
			return column
		})
	}, [tableColumns])

	const fourTabs = isWork
		? [
				{
					label: "Числовые результаты для включение в отчет",
					key: "numericalresults",
				},
				{
					label: "Графические результаты для включение в отчет",
					key: "graphicalresults",
				},
				{
					label: "Файлы",
					key: "files",
				},
		  ]
		: [
				{
					label: "Мнения и интерпретации",
					key: "interpretations",
				},
		  ]

	const handleTabChange = (newValue) => {
		dispatch(setTableValueType(newValue))
	}

	async function postInterpretation() {
		if (isNewInterpretation) {
			try {
				const response = await cardprojectAPI.postResultsInterpretation({
					projectFK: params.guid,
					workCategoryFK: tableName,
					interpretation: interpretation,
				})
				setInterpretationGuid(response.data.guid)
				setIsNewInterpretation(false)
			} catch (e) {}
		} else {
			try {
				const response = await cardprojectAPI.putResultsInterpretation({
					projectFK: params.guid,
					workCategoryFK: tableName,
					interpretation: interpretation,
					guid: interpretationGuid,
				})
			} catch (e) {}
		}
	}

	function onSubmit() {
		postInterpretation()
	}

	//запрос данных
	useEffect(() => {
		if (!isWork) {
			dispatch(setTableValueType("interpretations"))
		} else if (valueType === "interpretations") {
			dispatch(setTableValueType("numericalresults"))
		}
		if (
			tableName
			// && valueType !== "files"
		) {
			loadData()
		}
	}, [tableName, valueType, projectGuid, isWork])

	useEffect(() => {
		if (tableData.length === 0) {
			setInterpretation("")
			setIsNewInterpretation(true)
		} else {
			setInterpretation(tableData[0].interpretation)
			setInterpretationGuid(tableData[0].guid)
			setIsNewInterpretation(false)
		}
	}, [tableData, tableColumns])

	if (isLoading === LOADING_STATE_PENDING) {
		return <Spinner />
	}

	return (
		<>
			<Space direction="vertical" size="small" style={{width: "100%"}}>
				<Typography.Title level={3}>
					{tableTitle} {breadcrumbs.length > 1 && `(${breadcrumbs[0].name})`}
				</Typography.Title>
				<StyledTabs type="card" items={fourTabs} activeKey={valueType} onChange={handleTabChange} destroyInactiveTabPane />

				{valueType === "interpretations" ? (
					<Form>
						<Form.Item label="Мнение">
							<TextArea rows={4} value={interpretation} onChange={(e) => setInterpretation(e.target.value)} />
						</Form.Item>
						<Button type="primary" onClick={onSubmit} className="mainColor addButton">
							Сохранить изменения
						</Button>
					</Form>
				) : valueType === "files" ? (
					<>
						<Space>
							{/* <Button onClick={showAddModal} icon={<PlusOutlined />} type="primary">{`Добавить файл`}</Button> */}
							<ExportExcel dataToExport={tableData} tableHeader={tableColumns} fileName={tableName} />
						</Space>
						<BaseTable
							data={tableData}
							columns={mappedColumns}
							isLoading={isLoading === LOADING_STATE_PENDING}
							pageContentHeight={424}
							filteredValues={columnFilterValues}
						/>
						{showModalEditor && (
							<ModalEditor
								entityName={valueType}
								isProjects={true}
								entityId={selectedId}
								onSuccess={handleSuccess}
								onCancel={handleCancel}
								useLink={true}
								link={`${valueType}/getform?projectGuid=${projectGuid}&workCategoryGuid=${tableName}`}
							/>
						)}
					</>
				) : (
					<>
						<Space>
							<ExportExcel dataToExport={tableData} tableHeader={tableColumns} fileName={tableName} />
						</Space>
						<BaseTable
							data={tableData}
							columns={mappedColumns}
							isLoading={isLoading === LOADING_STATE_PENDING}
							pageContentHeight={424}
							filteredValues={columnFilterValues}
						/>
					</>
				)}
			</Space>
		</>
	)
}

ReportTable.propTypes = {
	tableName: PropTypes.string, // название Таблицы
	tableTitle: PropTypes.string, // подпись Таблицы
	isWork: PropTypes.bool, // является ли работой
}

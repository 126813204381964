import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {makeNavigationTree} from "../../utils/makeNavigationTree"
import {projectManagementAPI} from "./api"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"

const initialState = {
	initialData: [], // данные о структуре справочников полученные с сервера
	treeData: [], // данные для treeSelect (древовидная структура справочников)
	selectedWork: {guid: "", name: ""}, // выбранный справочник
	isLoading: LOADING_STATE_EMPTY, // идет ли запрос
}

// получет массив объектов для навигации
export const getWorksTreeData = createAsyncThunk("worksNavigation/getWorksTreeData", async (params, thunkAPI) => {
	try {
		const response = await projectManagementAPI.getWorksNavigationArray()
		return response.data
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// tree select Навигация на странице Справочников
const worksNavigationSlice = createSlice({
	name: "worksNavigation",
	initialState,
	reducers: {
		setSelectedWork: (state, action) => {
			state.selectedWork = action.payload
		},
	},
	extraReducers: {
		[getWorksTreeData.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[getWorksTreeData.fulfilled]: (state, action) => {
			state.initialData = action.payload
			let treeData = makeNavigationTree(action.payload)
			state.treeData = treeData
			state.selectedWork.guid = treeData[0].guid
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getWorksTreeData.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
		},
	},
})
export const {setSelectedWork} = worksNavigationSlice.actions
export const worksNavigationReducer = worksNavigationSlice.reducer

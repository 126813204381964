import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {makeNavigationTree} from "../../utils/makeNavigationTree"
import {projectManagementAPI} from "./api"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"

const initialState = {
	initialData: [], // данные о структуре справочников полученные с сервера
	treeData: [], // данные для treeSelect (древовидная структура справочников)
	selectedElement: {guid: "", name: ""}, // выбранный справочник
	isLoading: LOADING_STATE_EMPTY, // идет ли запрос
}

// получет массив объектов для навигации
export const getElementTreeData = createAsyncThunk("elementsNavigation/getElementTreeData", async (params, thunkAPI) => {
	try {
		const response = await projectManagementAPI.getElementsNavigationArray(params)
		return response.data
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// tree select Навигация на странице заключения
const elementsNavigationSlice = createSlice({
	name: "elementsNavigation",
	initialState,
	reducers: {
		setSelectedElement: (state, action) => {
			state.selectedElement = action.payload
		},
	},
	extraReducers: {
		[getElementTreeData.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[getElementTreeData.fulfilled]: (state, action) => {
			state.initialData = action.payload
			let treeData = makeNavigationTree(action.payload)
			state.treeData = treeData
			if (treeData.length > 0) {
				state.selectedElement.guid = treeData[0].guid
			} else {
				state.selectedElement = initialState.selectedElement
			}
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getElementTreeData.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
		},
	},
})
export const {setSelectedElement} = elementsNavigationSlice.actions
export const elementsNavigationReducer = elementsNavigationSlice.reducer

import React from "react"
import {Row, Space, Col} from "antd"

// текст, который показывается если еще не выбран Проект
export const EmptyCardProject = () => {
    return (
        <Row wrap={false} style={{margin: "12px", width: "1200px"}}>
            <Col span={16}>
                <Space size="middle">
                    <Row>
                        Выберите раздел проекта, чтобы отобразить его содержимое.
                    </Row>
                </Space>
            </Col>
        </Row>
    )
}
import React from "react"
import {Resizable} from "react-resizable"
import PropTypes from "prop-types"

export const ResizableTitle = (props) => {
	const {onResize, width, ...restProps} = props
	if (!width) {
		return <th {...restProps} />
	}
	return (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={(e) => {
						e.stopPropagation()
					}}
				/>
			}
			onResize={onResize}
			draggableOpts={{
				enableUserSelectHack: true,
			}}>
			<th {...restProps} />
		</Resizable>
	)
}

ResizableTitle.propTypes = {
	width: PropTypes.number,
	onResize: PropTypes.func,
}

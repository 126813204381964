import {Button, Form, Modal, Select} from "antd"
import {useEffect, useState} from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import {cardprojectAPI} from "../../api/cardprojectAPI"
import {EditableTable} from "../../../../components/EditableTable/editableTable"
import {MinusOutlined} from "@ant-design/icons"
import {Spinner} from "../../../../components/Spinner/spinner"
import DisableBodyScroll from "../../../../components/DisableBodyScroll"

const AddResultsModal = ({workGuid, onSuccess, onCancel}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [condition, setCondition] = useState("")
	const [conditions, setConditions] = useState([])
	const [results, setResults] = useState([])
	const [executors, setExecutors] = useState([])
	const [equipmentAttributes, setEquipmentAttributes] = useState([])
	const [unitsOfMeasurement, setUnitsOfMeasurement] = useState([])
	const [resultIndex, setResultIndex] = useState(0)
	const [errorMessage, setErrorMessage] = useState("")

	const getOptions = (reference) => {
		if (reference === "executorFK") return executors.map((executor) => ({value: executor.guid, label: executor.name}))
		if (reference === "equipmentAttributeFK")
			return equipmentAttributes.map((equipmentAttribute) => ({
				value: equipmentAttribute.guid,
				label: equipmentAttribute.name,
				unitFK: equipmentAttribute.unitOfMeasurementFK,
			}))
		if (reference === "unitsOfMeasurementFK")
			return unitsOfMeasurement.map((unitOfMeasurement) => ({value: unitOfMeasurement.guid, label: unitOfMeasurement.name}))

		return []
	}

	const handleDeleteLine = (record) => {
		const index = results.indexOf(record)
		if (index >= 0) {
			const newTableData = [...results]
			newTableData.splice(index, 1)
			handleDataChange(newTableData)
		}
	}

	const columns = [
		{
			title: "Исполнитель",
			dataIndex: "executor",
			key: "executorFK",
			editable: true,
			columnEditingProps: {
				type: "select",
				nameField: "executor",
				guidField: "executorFK",
				options: getOptions("executorFK"),
			},
			width: 300,
		},
		{
			title: "Параметр",
			dataIndex: "equipmentAttribute",
			key: "equipmentAttributeFK",
			editable: true,
			columnEditingProps: {
				type: "select",
				nameField: "equipmentAttribute",
				guidField: "equipmentAttributeFK",
				options: getOptions("equipmentAttributeFK"),
			},
			width: 250,
		},
		{
			title: "Единица измерения",
			dataIndex: "unitOfMeasurement",
			key: "unitOfMeasurementFK",
			editable: true,
			columnEditingProps: {
				type: "select",
				nameField: "unitOfMeasurement",
				guidField: "unitOfMeasurementFK",
				options: getOptions("unitsOfMeasurementFK"),
			},
			width: 180,
		},
		{
			title: "Значение",
			dataIndex: "value",
			key: "value",
			editable: true,
			columnEditingProps: {
				type: "strNum",
			},
			width: 150,
		},
		{
			title: "Погрешность",
			dataIndex: "measurementUncertainty",
			key: "measurementUncertainty",
			editable: true,
			columnEditingProps: {
				type: "strNum",
			},
			width: 150,
		},
		{
			title: "",
			dataIndex: "actions",
			key: "actions",
			render: (_, record) => {
				return <Button shape="circle" type="text" icon={<MinusOutlined />} onClick={() => handleDeleteLine(record)} />
			},
		},
	]

	const handleCancel = () => {
		onCancel()
	}

	const Header = () => {
		return <StyledHeader>Внесение результатов</StyledHeader>
	}

	async function HandleSubmit() {
		if (condition === "") {
			setErrorMessage("Выберите условия проведения испытаний")
			return 0
		}
		if (workGuid === "" || results.length === 0) {
			return 0
		}
		const finalResults = results.map((result) => {
			return {...result, conditionRegistrationJournalFK: condition, workFK: workGuid}
		})
		let errorMessage = ""
		finalResults.forEach((result, index) => {
			if (result.executorFK === "" || result.equipmentAttribute === "" || result.unitOfMeasurementFK === "") {
				errorMessage += `Не все поля заполнены у записи №${index + 1}. `
			}
		})
		if (errorMessage !== "") {
			setErrorMessage(errorMessage)
			return 0
		}
		try {
			const promises = []
			for (let i = 0; i < finalResults.length; i++) {
				const addingPromise = cardprojectAPI.postResult(finalResults[i]) // асинхронный вызов
				promises.push(addingPromise) // синхронный вызов
			}

			await Promise.all(promises)

			onSuccess()
		} catch (e) {
			console.error(e)
		}
	}

	const Footer = () => {
		return (
			<>
				<Button onClick={handleCancel}>Отмена</Button>

				<Button type="primary" loading={isLoading} onClick={() => HandleSubmit()}>
					Сохранить
				</Button>
			</>
		)
	}

	async function getConditions() {
		try {
			const response = await cardprojectAPI.getConditions()

			setConditions(response.data)
		} catch (e) {
			console.error(e)
		}
	}

	async function getEquipmentAttributes() {
		try {
			const response = await cardprojectAPI.getEquipmentAttributes()
			setEquipmentAttributes(response.data)
			return response.data
		} catch (e) {
			console.error(e)
			return []
		}
	}

	async function getUnitsOfMeasurement() {
		try {
			const response = await cardprojectAPI.getUnitsOfMeasurement()

			setUnitsOfMeasurement(response.data)
			return response.data
		} catch (e) {
			console.error(e)
			return []
		}
	}

	async function getExecutors() {
		try {
			const response = await cardprojectAPI.getExecutors()

			setExecutors(response.data)
			return response.data
		} catch (e) {
			console.error(e)
			return []
		}
	}

	async function loadData() {
		setIsLoading(true)
		try {
			await Promise.all([getConditions(), getEquipmentAttributes(), getUnitsOfMeasurement(), getExecutors()])
			setIsLoading(false)
		} catch (e) {
			console.error(e)
		}
	}

	const AddResult = () => {
		setResults([
			...results,
			{
				executorFK: "",
				conditionRegistrationJournalFK: "",
				equipmentAttributeFK: "",
				unitOfMeasurementFK: "",
				value: 0,
				measurementUncertainty: 0,
				key: resultIndex,
			},
		])
		setResultIndex(resultIndex + 1)
	}

	const handleDataChange = (data) => {
		setErrorMessage("")
		setResults(data)
	}

	useEffect(() => {
		loadData()
	}, [])

	return (
		<Modal title={<Header />} open={true} onCancel={handleCancel} width={"1100px"} centered getContainer={false} footer={<Footer />}>
			<DisableBodyScroll />
			{isLoading ? (
				<Spinner />
			) : (
				<Form onFinish={HandleSubmit} onCancel={handleCancel}>
					<Form.Item label="Условия проведения испытаний">
						<Select
							showSearch
							notFoundContent="Нет данных"
							name="condition"
							value={condition}
							filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
							options={
								Array.isArray(conditions) &&
								conditions.length > 0 &&
								conditions.map((option) => ({
									value: option?.guid,
									label: option?.name,
								}))
							}
							rules={[{required: true, message: "Channel ID is required"}]}
							style={{width: "50%"}}
							onChange={(e) => {
								setCondition(e)
								setErrorMessage("")
							}}
						/>
					</Form.Item>

					<section className="resultsList">
						<EditableTable columns={columns} data={results} onDataChange={handleDataChange} />

						<Button type="primary" onClick={AddResult}>
							Добавить результат
						</Button>
					</section>

					{errorMessage !== "" && <p style={{color: "red"}}>{errorMessage}</p>}
				</Form>
			)}
		</Modal>
	)
}

export default AddResultsModal

const StyledHeader = styled("div")`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: -8px;
`

AddResultsModal.propTypes = {
	workGuid: PropTypes.string,
	onSuccess: PropTypes.func,
	onCancel: PropTypes.func,
}

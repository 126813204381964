import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {LOADING_STATE_EMPTY, LOADING_STATE_FAILED, LOADING_STATE_PENDING, LOADING_STATE_SUCCEEDED} from "../../utils/loadingState"
import {projectManagementAPI} from "./api"

const initialState = {
	projectBasicInformationData: [],
	isLoading: LOADING_STATE_EMPTY,
}

export const getProjectBasedInfo = createAsyncThunk("basicInformation/getProjectInfo", async (projectGuid, thunkAPI) => {
	if (!projectGuid) return {data: []}
	try {
		const response = await projectManagementAPI.getProjectGeneralInfo(projectGuid)
		return response
	} catch (error) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

const basicInformationSlice = createSlice({
	name: "basicInformation",
	initialState,
	reducers: {},
	extraReducers: {
		[getProjectBasedInfo.pending]: (state) => {
			state.isLoading = LOADING_STATE_PENDING
		},
		[getProjectBasedInfo.fulfilled]: (state, action) => {
			state.projectBasicInformationData = action.payload.data
			state.isLoading = LOADING_STATE_SUCCEEDED
		},
		[getProjectBasedInfo.rejected]: (state) => {
			state.isLoading = LOADING_STATE_FAILED
		},
	},
})

// export const {} = basicInformationSlice.actions
export const basicInformationReducer = basicInformationSlice.reducer

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {makeNavigationTree} from "../utils/makeNavigationTree"
import {createBreadcrumbs} from "../utils/createBreadcrumbs"
import {digitalProfilesAPI} from "../pages/DigitalProfile/api/digitalProfilesAPI";

const initialState = {
	treeData: [], // данные для treeSelect (древовидная структура цифровых процилей)
	breadcrumbsArray: [], // массив отображаемых хлебных крошек
	selectedDigitalProfile: {guid: "", name: ""}, // выбранный цифровой профиль
	isLoading: "idle", // идет ли запрос
	selectedDigitalProfileID: null,
	navigationTreeRawData: [],
}

// получет массив объектов для навигации
export const getDigitalProfilesTreeData = createAsyncThunk("digitalProfilesNavigation/getdigitalProfilesTreeData", async (params, thunkAPI) => {
	try {
		const response = await digitalProfilesAPI.getDigitalProfilesNavigationArray()
		return response.data
	} catch (err) {
		return thunkAPI.rejectWithValue("Ошибка соединения. Повторите попытку")
	}
})

// tree select Навигация на странице Цифровых профилей
const digitalProfilesNavigationSlice = createSlice({
	name: "digitalProfilesNavigation",
	initialState,
	reducers: {
		setSelectedDigitalProfile: (state, action) => {
			state.selectedDigitalProfile = action.payload
			if (state.selectedDigitalProfile) {
				state.selectedDigitalProfileID = state.selectedDigitalProfile.guid
			} else {
				state.selectedDigitalProfileID = null
			}
		},
		setDigitalProfilesBreadcrumbs: (state, action) => {
			let newArray = []
			createBreadcrumbs(state.navigationTreeRawData, action.payload, newArray)
			state.breadcrumbsArray = newArray
		},
	},
	extraReducers: {
		[getDigitalProfilesTreeData.pending]: (state) => {
			state.isLoading = "pending"
		},
		[getDigitalProfilesTreeData.fulfilled]: (state, action) => {
			state.isLoading = "succeeded"
			state.navigationTreeRawData = action.payload
			let treeData = makeNavigationTree(action.payload)
			state.treeData = treeData
			state.selectedDigitalProfile.guid = treeData[0].guid
			state.breadcrumbsArray = [{name: treeData[0].name, guid: treeData[0].guid}]


		},
		[getDigitalProfilesTreeData.rejected]: (state) => {
			state.isLoading = "failed"
		},
	},
})
export const {setSelectedDigitalProfile, setDigitalProfilesBreadcrumbs} = digitalProfilesNavigationSlice.actions
export const digitalProfilesNavigationReducer = digitalProfilesNavigationSlice.reducer

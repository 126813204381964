import {Button, Col, Space} from "antd"
import React, {useState} from "react"
import {useParams} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {NavigationButton} from "../../../../components/NavigationButton/NavigationButton"
import {FragmentsTable} from "./FragmentsTable"
import {PlusOutlined} from "@ant-design/icons"
import {ModalEditor} from "../../../../components/ModalEditor/ModalEditor"
import {getFragmentTreeData} from "../../../../redux/projectManagementModule/fragmentsNavigationSlice"

// Страница Справочники
export const Fragments = () => {
	const params = useParams()
	const dispatch = useDispatch()

	//заголовок таблицы
	const tableTitle = useSelector((state) => state.projectFragmentsNavigation.selectedFragment.name)

	const [showFragmentModal, setFragmentModal] = useState(false)

	const showAddFragment = () => {
		setFragmentModal(true)
	}

	const handleSuccess = () => {
		dispatch(getFragmentTreeData(params.guid))
		setFragmentModal(false)
	}

	const handleCancel = () => {
		setFragmentModal(false)
	}

	return (
		<Col flex="auto" className="mainCol">
			<Space size="middle" style={{marginBottom: "12px"}}>
				<NavigationButton />
				<Button
					onClick={showAddFragment}
					icon={<PlusOutlined />}
					type="primary"
					className="mainColor addButton">{`Создание элемента`}</Button>
			</Space>
			{showFragmentModal && (
				<ModalEditor
					entityName={"fragments"}
					isProjects={true}
					onSuccess={handleSuccess}
					onCancel={handleCancel}
					useLink={true}
					link={`fragments/form/${params.guid}`}
				/>
			)}
			<FragmentsTable tableName={params.tableName} tableTitle={tableTitle} fragmentGuid={params.fragmentGuid} />
		</Col>
	)
}

import customAxios from "../../api/customAxios"
import {url} from "../../api/url"

//кэш запросов элементов для выпадающих списков
const optionsCacheMap = new Map()

const baseURL = `/api/vnikti-projects/v1`

// методы страницы проектов
export const projectManagementAPI = {
	getProjectBaseUrl() {
		return "/api/vnikti-projects/v1"
	},
	getProjectInfo(guid) {
		return customAxios.get(`${baseURL}/projects/${guid}`)
	},
	getProjectGeneralInfo(guid) {
		return customAxios.get(`${baseURL}/projects/generalInfo?projectGuid=${guid}`)
	},
	editProjectBasicInformation(values) {
		return customAxios.put(`${baseURL}/projects`, values)
	},
	// getProjectsTableData(tableName) {
	// 	return customAxios.get(`/api/vnikti-projects/v1/${tableName}/columns`)
	// },
	getProjectFormInfo(guid) {
		return customAxios.get(`${baseURL}/projects/form?guid=${guid}`)
	},
	// getProjects: () => {
	// 	return customAxios.get(`/api/vnikti-projects/v1/projects/columns/}`)
	// },
	// getBasicInformationId: (Id) => {
	// 	return customAxios.get(``)
	// },
	getFragmentsNavigationArray: (projectGuid) =>{
		return customAxios.get(`${baseURL}/fragments/navigation?projectGuid=${projectGuid}`)
	},
	getFragmentGeneralInfo: (fragmentGuid) =>{
		return customAxios.get(`${baseURL}/fragments/generalInfo?fragmentGuid=${fragmentGuid}`)
	},
	getFragmentsParametersTableData:(fragmentGuid, tableName) =>{
		return customAxios.get(`${baseURL}/${tableName}/${fragmentGuid}`)
	},
	getProjectFragments: (guid) => {
		return customAxios.get(`${baseURL}/fragments/columns/${guid}`)
	},
	getElementsByFragment: (guid) => {
		return customAxios.get(`${baseURL}/elements/columns/${guid}`)
	},
	getElementsNavigationArray: (projectGuid) =>{
		return customAxios.get(`${baseURL}/elements/navigation?projectGuid=${projectGuid}`)
	},
	getElementGeneralInfo: (elementGuid) =>{
		return customAxios.get(`${baseURL}/elements/generalInfo?elementGuid=${elementGuid}`)
	},
	getElementsParametersTableData: (elementGuid) =>{
		return customAxios.get(`${baseURL}/elementsParameters/${elementGuid}`)
	},
	getFragmentTechnical: (fragmentGuid) => {
		return customAxios.get(`${baseURL}/technical/${fragmentGuid}`)
	},
	getFragmentOperational: (fragmentGuid) => {
		return customAxios.get(`${baseURL}/operational/${fragmentGuid}`)
	},
	getWorksNavigationArray: () => {
		return customAxios.get(`${baseURL}/worknavigation`)
	},
	getWorksByProjectsAndWorkType: (projectGuid, workCategory) => {
		return customAxios.get(`${baseURL}/works/columns/${projectGuid}/${workCategory}`)
	},
	postNewWorkStatus: (workGuid, statusCode, unsuitability) => {
		if (statusCode===4 && unsuitability){
			return customAxios.post(`${baseURL}/works/changeStatus/${workGuid}/${statusCode}?unsuitability=${unsuitability}`)
		}
		return customAxios.post(`${baseURL}/works/changeStatus/${workGuid}/${statusCode}`)
	},
	getWorkResultsNavigationTree: (projectGuid) => {
		return customAxios.get(`${baseURL}/worknavigation/results?projectGuid=${projectGuid}`)
	},
	getNumericalResults: (projectGuid, workCategory) => {
		return customAxios.get(`${baseURL}/numericalresults/columns/${projectGuid}/${workCategory}`)
	},
	getLinkData: (link, guid) => {
		const additionalParam = guid ? `&guid=${guid}` : ""
		return customAxios.get(`${baseURL}/${link}${additionalParam}`)
	},

	getGraphicalResults: (projectGuid, workCategory) => {
		return customAxios.get(`${baseURL}/graphicalresults/columns/${projectGuid}/${workCategory}`)
	},
	// getResultsId: (Id) => {
	// 	return customAxios.get(``)
	// },
	// getReportId: (Id) => {
	// 	return customAxios.get(``)
	// },
	getConclusionNavigationArray: () => {
		return {
			data: [
				{
					guid: "75640c21-8fa2-4dc1-bcee-80fe889dcb63",
					name: "Дефекты",
					conclusionName: "defectsToFragments",
				},
				{
					guid: "23bbe3cb",
					name: "Причины разрушения",
					conclusionName: "causesofdamagetoprojects",
				},
				{
					guid: "23bbe3cb-b15a-4a5f",
					name: "Рекомендации",
					conclusionName: "recommendationsToProjects",
				},
				{
					guid: "23bbe3cb-b15a-4a5f-a076",
					name: "Выводы",
					conclusionName: "conclusion",
				},
			],
		}
		// return customAxios.get(`${baseURL}/`)
	},
	getReportResultsNavigationArray: (projectGuid) => {
		return customAxios.get(`${baseURL}/worknavigation/reportResults?projectGuid=${projectGuid}`)
	},
	getResultTableData: (tableName, projectGuid) => {
		return customAxios.get(`${baseURL}/${tableName}/columns/${projectGuid}`)
	},
	getNumericalReport: (projectGuid, workCategory) => {
		return customAxios.get(`${baseURL}/numericalresults/results/${projectGuid}/${workCategory}`)
	},
	getGraphicalReport: (projectGuid, workCategory) => {
		return customAxios.get(`${baseURL}/graphicalresults/results/${projectGuid}/${workCategory}`)
	},
	//скачивание файла
	getFileEntity(guid) {
		return customAxios.get(`${baseURL}/files/${guid}`)
	},

	getFile(fileGuid) {
		window.open(`${url}${baseURL}/files/download/${fileGuid}`, "_blank")
	},
	getProjectFiles(projectGuid) {
		return customAxios.get(`${baseURL}/files/filestable?projectGuid=${projectGuid}`)
	},
	getIsReportable(projectGuid) {
		return customAxios.get(`${baseURL}/projects/${projectGuid}/isReportable`)
	},
	// getProjectByID: (projectID) => {
	// 	return customAxios.get(`/api/vnikti-projects/v1/projects/columns/${projectID}`)
	// },
	// getProjectFormInfo(tableName, guid) {
	// 	const additionalParam = guid ? `?guid=${guid}` : ""
	// 	return customAxios.get(`/api/vnikti-projects/v1/projects/form${additionalParam}`)
	// },
	async getProjectsOptions(url) {
		if (!optionsCacheMap.has(url)) {
			try {
				const response = await customAxios.get(url)
				optionsCacheMap.set(url, response.data)
			} catch (error) {
				return []
			}
		}

		return optionsCacheMap.get(url)
	},

	// //добавление строки таблицы
	// postProjectsTableRow(tableName, requestOptions) {
	// /*	return customAxios.post(`/api/vnikti-projects/v1/${tableName}`, requestOptions) */
	// 	return customAxios.post(`/api/vnikti-projects/v1/projects`, requestOptions)
	// },

	// //редактирование строки таблицы
	// editProjectsTableRow(tableName, requestOptions) {
	// 	return customAxios.put(`/api/vnikti-projects/v1/projects`, requestOptions)
	// },

	// //удаление строки таблицы
	// deleteProjectsTableRow(tableName, guid) {
	// 	return customAxios.delete(`/api/vnikti-projects/v1/projects/${guid}`)
	// },
}
